import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { WriteOffDashboardType } from "types/dashboard/write-off-dashboard-types";
import { useTranslation } from "react-i18next";

export default function WriteOffUserChart({
  writeOffDashboardData,
}: {
  writeOffDashboardData: WriteOffDashboardType | null;
}) {
  const { t } = useTranslation();
  
  const writeOffUserData = useMemo(() => {
    return writeOffDashboardData?.writeoff_by_user.map((item) => item.quantity) || [];
  }, [writeOffDashboardData]);

  const options: ApexOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: t("dashboard.writeoff_by_users"),
    },
    labels: writeOffDashboardData?.writeoff_by_user.map((item) => item.name_surname) || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return <ReactApexChart options={options} series={writeOffUserData} type="pie" />;
}
