import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { MainDashboardType } from "types/dashboard/main-dashboard-types";
import { useTranslation } from "react-i18next";

export default function Top10TreemapChart({ mainDashboardData }: { mainDashboardData: MainDashboardType | null }) {
  const { t } = useTranslation();

  // TODO: make long words break
  const slicedAndSplittedTitle = (title: string) => (title + "...").split(" ").slice(0, 2);

  const sortedData = useMemo(() => {
    return (
      mainDashboardData?.top_10_items.map((item) => ({ x: slicedAndSplittedTitle(item.item_name), y: item.value })) ||
      []
    );
  }, [mainDashboardData]);

  const options: ApexOptions = {
    chart: {
      type: "treemap",
      toolbar: {
        show: false,
      },
    },
    title: {
      text: t("dashboard.top_10"),
    },
    legend: {
      show: false,
    },
  };
  return <ReactApexChart options={options} series={[{ data: sortedData }]} type="treemap" />;
}
