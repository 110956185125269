import { Api } from "api";

export const getRoles = async () => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/roles/?hash_pw=${hash}`);
  return res.data;
};

export const addRole = async (payload: { role: string; who_changed: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/add_role/?hash_pw=${hash}`, payload);
  return res.data;
};

export const updateRole = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/update_role/?hash_pw=${hash}`, payload);
  return res.data;
};
