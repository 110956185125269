import { Button, Empty, Image, Modal, Spin } from "antd";
import StageLayout from "components/layout/page-layout";
import { useEffect, useState } from "react";
import { StageType } from "types/stage-types";
import { checkScan, saveImg, scan } from "api/registration";
import { useMainContext } from "state/main-context";
import { emptyScanImage, ScanImage } from "types/registration-types";
import { IconInfoCircle, IconRotate2, IconRotateClockwise2, IconZoomIn, IconZoomOut } from "@tabler/icons-react";
import RotatedImage from "helpers/rotate-img";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";
import ScanExample from "./scan-example";

export default function ScanningPage({ setStep }: { setStep: (step: number) => void }) {
  const { user, invoice, setInvoice } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSavingImage, setSavingImage] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [images, setImages] = useState<ScanImage[]>([]);
  const [currentImage, setCurrentImage] = useState<ScanImage>(emptyScanImage);
  const [degreesToRotate, setDegreesToRotate] = useState<number>(0);

  useEffect(() => {
    const savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
    const isDocReady = JSON.parse(localStorage.getItem("document_is_ready") || "{}");

    if (isDocReady === true) {
      setStep(3);
    } else if (invoice.length) {
      setImages(invoice);
    } else if (savedRegister?.invoice?.length) {
      setImages(savedRegister.invoice);
    } else if (!invoice.length && !images.length && !savedRegister?.invoice) {
      startScan(1, true);
    }
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      startScan(currentPage);
    }
  }, [currentPage]);

  const addImageToArray = (newImage: ScanImage, newArray?: boolean) => {
    if (newArray) {
      setImages([newImage]);
      setInvoice([newImage]);
      return;
    } else {
      let newArray = [...images, newImage];
      setImages(newArray);
      setInvoice(newArray);

      let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");

      let register_step_2 = { ...savedRegister, invoice: newArray };

      localStorage.setItem("register", JSON.stringify(register_step_2));
    }
  };

  const deleteImageFromArray = (imageId: number) => {
    let newImgArray = images.filter((img) => img.id !== imageId);
    setImages(newImgArray);
  };

  const deleteImageConfirm = (imageId: number) => {
    Modal.confirm({
      width: 500,
      centered: true,
      title: t("register.delete_scan_title"),
      content: t("register.delete_scan_content"),
      okText: t("register.delete_scan_confirm"),
      cancelText: t("register.delete_scan_cancel"),
      onOk: () => deleteImageFromArray(imageId),
    });
  };

  const onImageTransform = (info: any) => {
    if (info.action === "rotateLeft" || info.action === "rotateRight") {
      setDegreesToRotate(info.transform.rotate);
    } else if (info.action === "close") {
      RotatedImage({ image: currentImage.img, degree: degreesToRotate }).then((imgData) => {
        let rotatedImage: ScanImage = { ...currentImage, img: imgData };
        let otherImages = images.filter((item) => item.id !== currentImage.id);
        let sortedImages = [...otherImages, rotatedImage].sort((a, b) => a.id - b.id);

        setInvoice(sortedImages);
        setImages(sortedImages);
      });
    }
  };

  const startScan = async (page?: number, again?: boolean) => {
    setLoading(true);

    try {
      const [_, res] = await Promise.all([
        checkScan(),
        scan({
          id_5: user.id_5.toString(),
          scan_num: page || 1,
          start_again: again,
        }),
      ]);

      addImageToArray(
        {
          id: currentPage,
          img: `data:image/png;base64,${res.base64_image}`,
        },
        again
      );
    } catch (error: any) {
      if (error.code === "ERR_NETWORK") {
        showNotification("error", t("notifications.scan_error"), t("notifications.scan_error_scanner"));
      } else {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.scan_error"), err);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetImagesData = () => {
    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
    let register_without_data = { category: savedRegister?.category };
    localStorage.setItem("register", JSON.stringify(register_without_data));

    setCurrentPage(1);
    setInvoice([]);
    setImages([]);
    startScan(1, true);
  };

  const scanNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const navigateToThirdStep = async (new_form: boolean = false) => {
    setSavingImage(true);

    saveImg({ id_5: user.id_5.toString(), images: images.map((img) => img.img.replace("data:image/png;base64,", "")) })
      .then(() => {
        if (new_form) {
          let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
          localStorage.setItem("register", JSON.stringify({ ...savedRegister, data: { iin_bin: "000000000000" } }));
        }

        setStep(3);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.save_invoice_error"), err);
      })
      .finally(() => setSavingImage(false));
  };

  const secondStageScanningNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">{t("register.scan_in_progress")}</h2>
      <Spin size="large"></Spin>
    </div>
  );

  const infoModal = () => {
    Modal.info({
      title: <span className="font-medium">{t("register.alert_title")}</span>,
      content: (
        <ol>
          <li>{t("register.alert_text_1")}</li>
          <li>{t("register.alert_text_2")}</li>
          <li>{t("register.alert_text_3")}</li>
          <li>{t("register.alert_text_4")}</li>
        </ol>
      ),
    });
  };

  const secondStageNode = (
    <>
      <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <h2 className="text-lg lg:text-xl font-semibold">{t("register.scan_example_title")}</h2>
            <ScanExample />
          </div>
          <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between gap-3 border-b border-gray-200 pb-3">
            <div className="flex items-center justify-center gap-1">
              <h2 className="text-lg lg:text-xl font-semibold text-center">{t("register.scanned_pages_title")}</h2>
              <IconInfoCircle className="text-primary cursor-pointer" onClick={infoModal} />
            </div>
            <div className="flex flex-wrap items-center justify-center lg:justify-end gap-3">
              <Button className="shadow grow lg:grow-0" loading={isSavingImage} onClick={resetImagesData}>
                {t("register.scan_again")}
              </Button>

              <Button className="shadow grow lg:grow-0" loading={isSavingImage} onClick={scanNextPage}>
                {t("register.scan_more")}
              </Button>

              <Button
                className="shadow grow lg:grow-0"
                loading={isSavingImage}
                onClick={() => navigateToThirdStep(true)}
                disabled={!images.length}
              >
                {t("register.enter_data_manually")}
              </Button>

              <Button
                className="shadow grow lg:grow-0"
                type="primary"
                loading={isSavingImage}
                onClick={() => navigateToThirdStep()}
                disabled={!images.length}
              >
                {JSON.parse(localStorage.getItem("register") || "{}")?.data?.iin_bin
                  ? t("register.continue")
                  : t("register.parse")}
              </Button>
            </div>
          </div>
          {images.length ? (
            <div className="flex items-center gap-3">
              {images.map((item, index) => (
                <div className="flex flex-col gap-3" key={index}>
                  <Image
                    width={200}
                    src={item.img}
                    key={item.id}
                    preview={{
                      toolbarRender: (_, { actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }) => (
                        <div className="flex items-center gap-12 px-12 py-6 rounded-[100px] bg-black-10">
                          <IconRotate2 className="cursor-pointer" size={48} onClick={onRotateLeft} />
                          <IconRotateClockwise2 className="cursor-pointer" size={48} onClick={onRotateRight} />
                          <IconZoomOut className="cursor-pointer" size={48} onClick={onZoomOut} />
                          <IconZoomIn className="cursor-pointer" size={48} onClick={onZoomIn} />
                        </div>
                      ),
                      onTransform: onImageTransform,
                    }}
                    onClick={() => setCurrentImage(item)}
                  />
                  <Button variant={"solid"} color={"danger"} onClick={() => deleteImageConfirm(item.id)}>
                    {t("register.delete_scan_button")}
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </div>
      <Spin spinning={isSavingImage} fullscreen></Spin>
    </>
  );

  const secondStageData: StageType = {
    id: 1,
    title: t("register.header"),
    node: isLoading ? secondStageScanningNode : secondStageNode,
  };

  return <StageLayout stage={secondStageData} />;
}
