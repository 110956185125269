import { useState } from "react";
import { Tabs } from "antd";
import DashboardMain from "./main";
import DashboardWriteOff from "./write-off";
import { MainDashboardType } from "types/dashboard/main-dashboard-types";
import { WriteOffDashboardType } from "types/dashboard/write-off-dashboard-types";
import { InventoryDashboardType } from "types/dashboard/inventory-dashboard-types";
import DashboardInventory from "./inventory";
import DashboardItems from "./items-dashboard";
import { useTranslation } from "react-i18next";
import { RegistryItemResponse } from "types/registry/item-types";
import { ActionsDashboardType } from "types/dashboard/actions-dashboard-types";
import DashboardActions from "./actions";

interface TabsData {
  mainDashboardData: MainDashboardType | null;
  writeOffDashboardData: WriteOffDashboardType | null;
  inventoryDashboardData: InventoryDashboardType | null;
  itemsDashboardData: RegistryItemResponse | null;
  actionsDashboardData: ActionsDashboardType | null;
  switchTab: (tab: string) => void;
  openFilters: () => void;
}

type TabType = "main" | "write-off" | "inventory" | "items";

export default function DashboardTabs({
  mainDashboardData,
  writeOffDashboardData,
  inventoryDashboardData,
  itemsDashboardData,
  actionsDashboardData,
  switchTab,
  openFilters,
}: TabsData) {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState<string | TabType>("main");

  const TabItems = [
    {
      label: t("dashboard.main"),
      key: "main",
      children: <DashboardMain mainDashboardData={mainDashboardData} openFilters={openFilters} />,
    },
    {
      label: t("dashboard.write_off"),
      key: "write-off",
      children: <DashboardWriteOff writeOffDashboardData={writeOffDashboardData} openFilters={openFilters} />,
    },
    {
      label: t("dashboard.inventory"),
      key: "inventory",
      children: <DashboardInventory inventoryDashboardData={inventoryDashboardData} openFilters={openFilters} />,
    },
    {
      label: t("dashboard.items"),
      key: "items",
      children: <DashboardItems itemsDashboardData={itemsDashboardData} openFilters={openFilters} />,
    },
    {
      label: t("dashboard.actions"),
      key: "actions",
      children: <DashboardActions actionsDashboardData={actionsDashboardData} openFilters={openFilters} />,
    },
  ];

  const onTabChange = (key: string) => {
    setCurrentTab(key);
    switchTab(key);
  };

  return (
    <div className="h-full pl-0 md:pl-4 lg:pl-6">
      <Tabs
        className="h-full"
        type="card"
        items={TabItems}
        activeKey={currentTab}
        onChange={onTabChange}
        destroyInactiveTabPane={true}
      />
    </div>
  );
}
