export const photo = {
  title: "Photo",
  change_photo: "Change photo",
  add_photo: "Add photo",

  // capture photo
  capture_photo_title: "Photo capture",
  save: "Save",
  cancel: "Cancel",
  retake_photo: "Retake photo",
  take_photo: "Take a photo",

  // photo preview
  open_pdf: "Open PDF",
  open_photo: "Open photo",
};
