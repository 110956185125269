import { Api } from "api";

export const getEmployees = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  const query = !!params ? `&${params}` : "";
  const res = await Api.get(`/employees_by_users/?hash_pw=${hash}${query}`);
  return res.data;
};

export const createEmployee = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/update_or_create_employee1/?hash_pw=${hash}`, payload);
  return res.data;
};
