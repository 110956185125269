import { useEffect, useState } from "react";
import { Button, Input, Modal, Select, Table, TableProps } from "antd";
import { IconFilter, IconInfoCircle, IconQrcode } from "@tabler/icons-react";
import QrScannerComponent from "components/qr-scanner";
import { useMainContext } from "state/main-context";
import { emptyMovingItems, MovingData, MovingItems } from "types/moving-types";
import { addToBatch, deleteFromBatch } from "api/batches";
import { BatchType } from "types/batch-types";
import { useNotification } from "state/notification-context";
import { getItems } from "api/registry";
import { FilterOptionsTypes, getOptions, NumberOption } from "helpers/options-list";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";

const emptyOption: NumberOption = { label: "", value: 0 };

export default function BatchTransfer({
  batch_list,
  isBatchClearing,
}: {
  batch_list: BatchType[];
  isBatchClearing: boolean;
}) {
  const { user, itemsToMove, setItemsToMove } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [batchList, setBatchList] = useState<NumberOption[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const [options, setOptions] = useState<FilterOptionsTypes>();

  const [batch, setBatch] = useState<NumberOption>(emptyOption);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isFiltersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
  const [itemsList, setItemsList] = useState<MovingItems>(emptyMovingItems);
  const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setItemsToMove([]);

    getItemsList();
    getQueryValues();

    return () => {
      setItemsToMove([]);
    };
  }, []);

  useEffect(() => {
    isBatchClearing && clearBatch();
  }, [isBatchClearing]);

  useEffect(() => {
    let batchOptions: NumberOption[] = batch_list
      .filter((item) => item.status === true)
      .map((item) => ({
        label: item.batch,
        value: item.id_batch,
      }));
    setBatchList(batchOptions);
  }, [batch_list]);

  const openQrScannerModal = () => {
    setIsQrModalOpen(true);
  };

  const closeQrScannerModal = () => {
    setIsQrModalOpen(false);
  };

  const setScannedQr = (id: string) => {
    appendFilter("id_item", id);
    getItemsList();
    closeQrScannerModal();
  };

  const getItemsList = async (limit?: number, offset?: number, params = searchParams) => {
    setTableLoading(true);

    if (limit !== undefined) {
      params.set("limit", limit.toString());
    }
    if (offset !== undefined) {
      params.set("offset", offset.toString());
    }

    await getItems(new URLSearchParams(params))
      .then((res: MovingItems) => {
        setItemsList(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getQueryValues = async () => {
    setTableLoading(true);

    await getOptions()
      .then((res) => {
        setOptions(res);
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const appendFilter = (key: string, value: string) => {
    if (key !== "limit" && key !== "offset") {
      searchParams.set("limit", "10");
      searchParams.delete("offset");
    }

    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const handleSearchChange = (filterName: string | string[], value: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name, index) => {
        value[index] !== undefined && appendFilter(name, value[index]);
      });
    } else {
      value !== undefined && !Array.isArray(value) && appendFilter(filterName, value);
    }

    timeoutId && clearTimeout(timeoutId);

    if (filterName !== "name_surname" && filterName !== "location_name" && filterName !== "batch") {
      const newTimeoutId = window.setTimeout(() => {
        getItemsList();
      }, 1000);

      setTimeoutId(newTimeoutId);
    } else {
      getItemsList();
    }

    setCurrentPage(1);
  };

  const handleClearSearch = (filterName: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name) => {
        searchParams.delete(name);
      });
    } else {
      searchParams.delete(filterName);
    }

    getItemsList();
  };

  const clearAllFilters = () => {
    searchParams.delete("offset");
    searchParams.delete("id_item");
    searchParams.delete("item_name");
    searchParams.delete("name_surname");
    searchParams.delete("location_name");
    searchParams.delete("batch");

    getItemsList();
  };

  const clearBatch = () => {
    setBatch(emptyOption);
  };

  const addItemsToBatch = async () => {
    setTableLoading(true);

    await addToBatch({
      id_items: itemsToMove.map((item) => item.id_item),
      id_batch: +batch.value,
      id_user: user.user_id,
    })
      .then(() => {
        setItemsToMove([]);
        clearBatch();
        showNotification("success", t("notifications.add_to_batch_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.add_to_batch_error"), err);
      })
      .finally(() => {
        getItemsList();
      });
  };

  const deleteItemsFromBatch = async () => {
    setTableLoading(true);

    await deleteFromBatch({
      id_items: itemsToMove.map((item) => item.id_item),
      id_batch: +batch.value,
      id_user: user.user_id,
    })
      .then(() => {
        setItemsToMove([]);
        clearBatch();
        showNotification("success", t("notifications.delete_from_batch_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.delete_from_batch_error"), err);
      })
      .finally(() => {
        getItemsList();
      });
  };

  const onTableChange: TableProps<MovingData>["onChange"] = (pagination, filters, sorter, extra) => {
    const updatedParams = new URLSearchParams(searchParams);

    // Handle pagination
    if (pagination) {
      setCurrentPage(pagination.current || 1);

      const newOffset = ((pagination.current || 1) - 1) * (pagination.pageSize || 10);
      updatedParams.set("limit", (pagination.pageSize || 10).toString());
      updatedParams.set("offset", newOffset.toString());
    }

    // Handle sorting
    if (!Array.isArray(sorter)) {
      if (sorter.order) {
        updatedParams.set("sort_by", `${sorter.field}:${sorter.order === "ascend" ? "asc" : "desc"}`);
      } else {
        updatedParams.delete("sort_by");
        searchParams.delete("sort_by");
      }
    } else {
      updatedParams.delete("sort_by");
      searchParams.delete("sort_by");

      let reversedSorter = sorter.reverse();
      reversedSorter.forEach((sort) => {
        updatedParams.append("sort_by", `${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`);
      });
    }

    // Update searchParams with the new params using Array.from()
    Array.from(updatedParams.entries()).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    getItemsList(undefined, undefined, updatedParams);
  };

  const columns: TableProps<MovingData>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
  ];

  const rowSelection: TableProps<MovingData>["rowSelection"] = {
    type: "checkbox",
    selectedRowKeys: itemsToMove.map((item) => item.id_stock),
    onChange: (selectedRowKeys: React.Key[], selectedRows: MovingData[]) => {
      setItemsToMove(selectedRows);
    },
    getCheckboxProps: (record: MovingData) => ({
      disabled: false, // Column configuration not to be checked
      name: record.item_name,
    }),
  };

  const infoModal = () => {
    Modal.info({
      content: (
        <ol>
          <li>{t("batches.transfer_alert_1")}</li>
          <li>{t("batches.transfer_alert_2")}</li>
          <li>{t("batches.transfer_alert_3")}</li>
        </ol>
      ),
    });
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between flex-wrap gap-3">
          <div className="flex items-center gap-1">
            <h2 className="text-lg md:text-xl font-semibold text-center">{t("batches.transfer_title")}</h2>
            <IconInfoCircle className="text-primary cursor-pointer" onClick={infoModal} />
          </div>

          <div className="flex items-start gap-3 flex-wrap w-full md:w-fit">
            <Button className="shadow grow" icon={<IconFilter />} onClick={() => setFiltersModalOpen(true)}>
              {t("inputs.search_title")}
            </Button>

            <Button
              className="shadow grow"
              color="danger"
              variant="solid"
              onClick={() =>
                Modal.confirm({
                  width: 500,
                  centered: true,
                  title: t("batches.delete_title"),
                  content: t("batches.delete_content"),
                  cancelText: t("batches.delete_cancel"),
                  okText: t("batches.delete_confirm"),
                  onOk: deleteItemsFromBatch,
                })
              }
              disabled={!!!itemsToMove.length || !!!itemsToMove.filter((item) => item.batch).length}
            >
              {t("batches.delete_from_batch")}
            </Button>

            <Select
              className="w-full md:w-auto"
              placeholder={<span className="text-black">{t("batches.choose_batch")}</span>}
              value={batch.value ? batch : undefined}
              onChange={(value, option) => {
                // @ts-ignore
                !Array.isArray(option) && setBatch(option);
              }}
              options={batchList}
              disabled={isTableLoading}
            />

            <Button
              className="shadow grow"
              type="primary"
              onClick={addItemsToBatch}
              disabled={!!!itemsToMove.length || !!!batch.value || isTableLoading}
            >
              {t("batches.transfer_add")}
            </Button>
          </div>
        </div>
      </div>
      <Table<MovingData>
        size="small"
        className="grow"
        dataSource={itemsList.items}
        columns={columns}
        rowSelection={rowSelection}
        rowKey={"id_stock"}
        loading={isTableLoading}
        bordered
        pagination={{
          disabled: isTableLoading,
          total: itemsList.total_items,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
          },
          showSizeChanger: false,
        }}
        onChange={onTableChange}
      />
      <QrScannerComponent isModalOpen={isQrModalOpen} onScan={setScannedQr} closeModal={closeQrScannerModal} />
      <Modal
        width={400}
        title={t("inputs.search_title")}
        open={isFiltersModalOpen}
        onCancel={() => setFiltersModalOpen(false)}
        onOk={() => setFiltersModalOpen(false)}
        footer={(_, { OkBtn }) => (
          <>
            <Button onClick={clearAllFilters}>{t("inputs.search_reset")}</Button>
            <OkBtn />
          </>
        )}
        keyboard={false}
        centered
      >
        <div className="flex flex-col gap-3">
          <Input
            placeholder={t("inputs.search_esep_id")}
            value={searchParams.get("id_item") || ""}
            onChange={(e) => handleSearchChange("id_item", e.target.value)}
            onClear={() => handleClearSearch("id_item")}
            allowClear
          />

          <Input
            placeholder={t("inputs.search_name")}
            value={searchParams.get("item_name") || ""}
            onChange={(e) => handleSearchChange("item_name", e.target.value)}
            onClear={() => handleClearSearch("item_name")}
            allowClear
          />

          <Select
            placeholder={t("inputs.search_mrp")}
            value={searchParams.get("name_surname")}
            options={options?.all_employees}
            onChange={(value) => value && handleSearchChange("name_surname", value)}
            onClear={() => handleClearSearch("name_surname")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_location")}
            value={searchParams.get("location_name")}
            options={options?.locations}
            onChange={(value) => value && handleSearchChange("location_name", value)}
            onClear={() => handleClearSearch("location_name")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_batch")}
            value={searchParams.get("batch")}
            options={options?.batches}
            onChange={(value) => value && handleSearchChange("batch", value)}
            onClear={() => handleClearSearch("batch")}
            allowClear
            showSearch
          />

          <Button icon={<IconQrcode />} onClick={openQrScannerModal}>
            {t("inputs.search_qr")}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
