import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Modal } from "antd";
import { IconBuildingWarehouse, IconCameraPlus, IconPackage } from "@tabler/icons-react";
import { CategoryType, emptyCategory } from "types/ui/category-types";
import { useMainContext } from "state/main-context";
import { useTranslation } from "react-i18next";

export default function ChooseCategory({ setStep }: { setStep: (step: number) => void }) {
  const { OsType, setInvoice, setReactData } = useMainContext();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [category, setCategory] = useState<CategoryType>(emptyCategory);

  const registerTypes: CategoryType[] = [
    {
      id: "ОС",
      title: t("register.os_type"),
      icon: <IconBuildingWarehouse size={60} />,
      url: "step-2",
      disabled: OsType !== "Desktop",
    },
    {
      id: "ТМЗ",
      title: t("register.tmz_type"),
      icon: <IconPackage size={60} />,
      url: "step-2",
      disabled: OsType !== "Desktop",
    },
    {
      id: "add_photo",
      title: t("register.add_photo"),
      icon: <IconCameraPlus size={60} />,
      url: "/registration/invoice-list",
      disabled: false,
    },
  ];

  const startNewRegister = () => {
    localStorage.removeItem("register");
    localStorage.removeItem("in_process");
    localStorage.removeItem("document_is_ready");
    setInvoice([]);
    setReactData([]);
  };

  const navigateToSecondStep = () => {
    if (category.id === "add_photo") {
      navigate(category.url);
    } else {
      let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
      if (!savedRegister.category) {
        localStorage.setItem("register", JSON.stringify({ category: `${category.id}` }));
      }
      setStep(2);
    }
  };

  useEffect(() => {
    let savedCategory = localStorage.getItem("register");
    savedCategory &&
      Modal.confirm({
        width: 500,
        centered: true,
        title: t("register.old_register_title"),
        content: t("register.old_register_content"),
        cancelText: t("register.old_register_cancel"),
        okText: t("register.old_register_confirm"),
        onCancel: startNewRegister,
        onOk: () => setStep(2),
      });
  }, []);

  useEffect(() => {
    category.id && navigateToSecondStep();
  }, [category]);

  const firstStageNode = (
    <div className="flex flex-col justify-center grow gap-4 md:gap-8 lg:gap-12 md:mx-12 lg:mx-16">
      <h2 className="text-lg md:text-xl font-bold text-center">{t("register.choose_type_title")}</h2>
      <div className="grow sm:grow-0 grid grid-cols-1 sm:grid-cols-3 justify-items-center gap-4 md:gap-8 lg:gap-12 h-full sm:h-fit">
        {registerTypes.map((register) => (
          <div
            className={`w-2/3 sm:w-full p-4 shadow rounded-xl md:rounded-2xl lg:rounded-3xl bg-white cursor-pointer ${
              register.disabled === false
                ? "cursor-pointer"
                : "text-[#bfbfbf] !bg-[rgba(0,0,0,0.04)] !cursor-not-allowed"
            }`}
            onClick={() => register.disabled === false && setCategory(register)}
            key={register.id}
          >
            <div className="flex flex-col items-center justify-center gap-2 h-full md:h-48 lg:h-60">
              {register.icon}
              <span className="text-lg md:text-xl font-bold text-center">{register.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const firstStageData: StageType = {
    id: 1,
    title: t("register.header"),
    node: firstStageNode,
  };

  return <StageLayout stage={firstStageData} />;
}
