import { IconArrowNarrowRight } from "@tabler/icons-react";
import { Alert, Button, Image, Input, Modal, Space, Spin, Table, TableProps } from "antd";
import CameraCapture from "components/camera-capture";
import StageLayout from "components/layout/page-layout";
import QrScannerComponent from "components/qr-scanner";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StageType } from "types/stage-types";
import { useMainContext } from "state/main-context";
import { BasketItem, emptyBasketItem } from "types/inventory-types";
import { addImage, addInventory, getItemsByQr, removeFromBasket, updateItem } from "api/inventory";
import { useTranslation } from "react-i18next";
import { useNotification } from "state/notification-context";

export default function TableToItem() {
  const { user, itemInventory, setItemInventory } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [inventoryCheckData, setInventoryCheckData] = useState<BasketItem[]>([]);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isItemSaving, setItemSaving] = useState<boolean>(false);

  const [checkStage, setCheckStage] = useState<number>(1);
  const [checkTitle, setCheckTitle] = useState<string>("");
  const [checkBtnText, setCheckBtnText] = useState<string>("");

  const [isItemFound, setIsItemFound] = useState<boolean>(true);
  const [isQrMatches, setIsQrMatches] = useState<boolean | null>(null);
  const [realQuantity, setRealQuantity] = useState<string>("0");

  const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
  const [isCameraActive, setCameraActive] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string | null>(null);

  useEffect(() => {
    if (!itemInventory.id_item) {
      navigate(-1);
    } else {
      initCheck();

      // Attach event listeners
      window.addEventListener("popstate", () => setItemInventory(emptyBasketItem));

      // Cleanup listeners when component is unmounted
      return () => {
        window.removeEventListener("popstate", () => setItemInventory(emptyBasketItem));
      };
    }
  }, []);

  const totalQuantity = useMemo(() => {
    return inventoryCheckData.reduce((sum, item) => {
      return sum + item.quantity;
    }, 0);
  }, [inventoryCheckData]);

  const status: string = useMemo(() => {
    if (!isItemFound) return "Товара нет";
    if (isQrMatches === false) return "QR код не соответствует";
    if (+realQuantity !== totalQuantity) return "Количество не соответствует";
    return "Соответствует";
  }, [isItemFound, isQrMatches, realQuantity, totalQuantity]);

  const initCheck = async () => {
    setTableLoading(true);
    await getItemsByQr(itemInventory.id_item)
      .then((res: any) => {
        setInventoryCheckData(res.items);

        let sum = inventoryCheckData.reduce((sum, item) => {
          return sum + item.quantity;
        }, 0);
        setRealQuantity(sum.toString());
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const completeInventory = () => {
    setItemSaving(true);

    let data = {
      id_item: inventoryCheckData[0].id_item,
      quantity_checked: +realQuantity,
      id_check_user: user.user_id,
      status: status,
      method: "От учета к товару",
      action_name: `Инвентаризация: Статус - ${status}, Факт - ${realQuantity}`,
    };

    let photo_data = {
      id_item: inventoryCheckData[0].id_item,
      photo: (photo || "").replace("data:image/png;base64,", ""),
      action: `Инвентаризация_${user.name}_${status}`,
    };

    let promises = [
      updateItem(inventoryCheckData[0].id_item, data)
        .then(() => addInventory(data))
        .then(() => removeFromBasket(data)),
    ];

    if (photo) {
      promises.push(addImage(photo_data));
    }

    Promise.all(promises)
      .then(() => {
        showNotification("success", t("notifications.stock_count_success"));
        navigate(-1);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.stock_count_error"), err);
      })
      .finally(() => setItemSaving(false));
  };

  useEffect(() => {
    switch (checkStage) {
      case 1:
        if (isItemFound) {
          setCheckTitle(t("inventory.check_found_title"));
          setCheckBtnText(t("inventory.check_found_button"));
        } else {
          setCheckTitle(t("inventory.check_not_found_title"));
          setCheckBtnText(t("inventory.check_not_found_button"));
        }
        break;
      case 2:
        setCheckTitle(t("inventory.check_scan_title"));
        if (isQrMatches) {
          setCheckBtnText(t("inventory.check_next_button"));
        } else if (isQrMatches === false) {
          setCheckBtnText(t("inventory.check_scan_again"));
        } else {
          setCheckBtnText(t("inventory.check_scan_button"));
        }
        break;
      case 3:
        setCheckTitle(t("inventory.check_photo"));
        setCheckBtnText(t("inventory.check_save_button"));
        break;
      default:
        break;
    }
  }, [checkStage, isItemFound, isQrMatches, photo]);

  const checkBtnFunc = () => {
    switch (checkStage) {
      case 1:
        if (isItemFound) {
          Modal.confirm({
            centered: true,
            title: t("inventory.modal_found_title"),
            cancelText: t("inventory.modal_not_found"),
            okText: t("inventory.modal_found"),
            onCancel: () => {
              setIsItemFound(false);
              setRealQuantity("0");
            },
            onOk: () => setCheckStage(2),
          });
        } else {
          Modal.confirm({
            centered: true,
            title: t("inventory.modal_header"),
            content: t("inventory.modal_content"),
            cancelText: t("inventory.cancel"),
            okText: t("inventory.confirm"),
            onOk: completeInventory,
          });
        }
        break;
      case 2:
        if (isQrMatches) {
          setInventoryCheckData(inventoryCheckData.map((inv) => ({ ...inv, status: t("inventory.checked_title") })));
          setCheckStage(3);
        } else openQrModal();
        break;
      case 3:
        photo &&
          Modal.confirm({
            centered: true,
            title: t("inventory.end_inventory"),
            cancelText: t("inventory.end_cancel"),
            okText: t("inventory.end_confirm"),
            onOk: completeInventory,
          });
        break;
      default:
        break;
    }
  };

  const openQrModal = () => {
    setIsQrModalOpen(true);
  };

  const closeQrScannerModal = () => {
    setIsQrModalOpen(false);
  };

  const checkScannedQr = (id: string) => {
    closeQrScannerModal();
    setIsQrMatches(inventoryCheckData[0].id_item === +id ? true : false);
  };

  const columns: TableProps<BasketItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity_released",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value ? new Date(value).toLocaleDateString() : "-"),
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => (value !== null ? value : "-"),
    },
  ];

  const CheckNode = (
    <>
      <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 w-full">
          <h2 className="text-lg md:text-xl font-semibold">{checkTitle}</h2>
          <Table<BasketItem>
            size="small"
            dataSource={inventoryCheckData}
            columns={columns}
            rowKey={(_, index) => (index || 0).toString()}
            loading={isTableLoading}
            pagination={{ defaultPageSize: 40 }}
          />
          {checkStage === 2 && isQrMatches !== null && (
            <Alert
              className={`${isQrMatches ? "text-green-600" : "text-red-600"}`}
              message={isQrMatches ? t("inventory.qr_match") : t("inventory.qr_not_match")}
              type={isQrMatches ? "success" : "error"}
              showIcon
            />
          )}
          {checkStage === 3 && (
            <>
              <div className="flex items-center gap-4 text-lg font-semibold">
                <span className="text-lg font-semibold">{t("inventory.quantity")}</span>
                <Input
                  className="max-w-32"
                  type="number"
                  min={0}
                  step={"any"}
                  value={realQuantity}
                  onChange={(e) => setRealQuantity(e.target.value)}
                />
              </div>
              <Space direction="vertical">
                <span className="text-lg font-semibold">{t("photo.title")}</span>
                {photo && <Image width={100} src={photo || undefined} />}
                <Button onClick={() => setCameraActive(true)}>
                  {photo ? t("photo.change_photo") : t("photo.add_photo")}
                </Button>
              </Space>
            </>
          )}
          <div className="flex items-center justify-end gap-3">
            {isQrMatches === false && (
              <Button
                className="shadow text-white bg-green-600 active:!text-white active:!border-green-500 active:!bg-green-500 hover:!text-white hover:!border-green-500 hover:!bg-green-500"
                variant="solid"
                onClick={() =>
                  Modal.confirm({
                    centered: true,
                    title: t("inventory.qr_mismatch_title"),
                    content: t("inventory.qr_mismatch_content"),
                    cancelText: t("inventory.cancel"),
                    okText: t("inventory.confirm"),
                    onOk: completeInventory,
                  })
                }
              >
                {t("inventory.save")}
              </Button>
            )}
            <Button
              className="shadow"
              type="primary"
              disabled={
                checkStage === 3 && (!photo || +realQuantity < 0 || isNaN(+realQuantity) || realQuantity === "")
              }
              onClick={checkBtnFunc}
            >
              {checkBtnText}
            </Button>
          </div>
        </div>
      </div>
      <QrScannerComponent isModalOpen={isQrModalOpen} onScan={checkScannedQr} closeModal={closeQrScannerModal} />
    </>
  );

  const savingNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">{t("inventory.inventory_in_progress")}</h2>
      <Spin size="large"></Spin>
    </div>
  );

  const CheckStageData: StageType = {
    id: 4,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("inventory.header")}
        <IconArrowNarrowRight />
        {t("inventory.table_to_item")}
      </div>
    ),
    node: isItemSaving ? savingNode : CheckNode,
  };

  return (
    <>
      <StageLayout stage={CheckStageData} />
      <CameraCapture isCameraActive={isCameraActive} setCameraActive={setCameraActive} sendPhoto={setPhoto} />
    </>
  );
}
