import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Table, TableProps, Tag } from "antd";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { addLocation, getFilialLocations, updateLocationStatus } from "api/locations";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { LocationType } from "types/location-types";
import NewLocation from "./new-location";
import NewFilial from "./new-filial";
import { updateFilterCache } from "api/filter_cache";
import { useTranslation } from "react-i18next";

export default function Locations() {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [locations, setLocations] = useState<LocationType[]>([]);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isLocationModalOpen, setLocationModalOpen] = useState(false);
  const [isFilialModalOpen, setFilialModalOpen] = useState(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 241;
    } else if (width < 768) {
      return height - 257;
    } else if (width < 1024) {
      return height - 213;
    } else return height - 269;
  }, [window.innerWidth]);

  useEffect(() => {
    getLocations();
  }, []);

  const openLocationModal = () => {
    setLocationModalOpen(true);
  };

  const openFilialModal = () => {
    setFilialModalOpen(true);
  };

  const closeModal = () => {
    setLocationModalOpen(false);
    setFilialModalOpen(false);
  };

  const getLocations = async () => {
    setTableLoading(true);

    await getFilialLocations()
      .then((res: LocationType[]) => {
        setLocations(res.filter((filial) => filial.location_name !== "Списанные"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const createLocation = async (data: LocationType) => {
    setTableLoading(true);

    await addLocation({ filial: data.filial, location_name: data.location_name, who_changed: user.user_id })
      .then(() => {
        getLocations();
        showNotification("success", t("notifications.create_location_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.create_location_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
        closeModal();
      });
  };

  const createFilial = async (data: LocationType) => {
    setTableLoading(true);

    await addLocation({ filial: data.filial, location_name: "Списанные", who_changed: user.user_id })
      .then(() => {
        updateFilterCache();
        getLocations();
        showNotification("success", t("notifications.create_filial_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.create_filial_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
        closeModal();
      });
  };

  const updateLocation = async (data: LocationType) => {
    setTableLoading(true);

    await updateLocationStatus({
      id_location: data.id_location,
      location_status: !data.location_status,
      who_changed: user.user_id,
    })
      .then(() => {
        getLocations();
        showNotification("success", t("notifications.edit_location_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.edit_location_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
        closeModal();
      });
  };

  const columns: TableProps<LocationType>["columns"] = [
    {
      title: t("locations.name_column"),
      dataIndex: "location_name",
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
    },
    {
      title: t("locations.filial_column"),
      dataIndex: "filial",
      sorter: (a, b) => a.filial.localeCompare(b.filial),
    },
    {
      title: t("locations.status_column"),
      dataIndex: "location_status",
      render: (value) =>
        value ? <Tag color="success">{t("locations.active")}</Tag> : <Tag color="error">{t("locations.inactive")}</Tag>,
      sorter: (a, b) => `${b.location_status}`.localeCompare(`${a.location_status}`),
    },
    {
      render: (value, record) => (
        <Button
          className={`${record.location_status ? "text-red-600" : "text-green-600"}`}
          type="dashed"
          onClick={() => updateLocation(record)}
          disabled={isTableLoading}
        >
          {record.location_status ? t("locations.deactivate") : t("locations.activate")}
        </Button>
      ),
    },
  ];

  const WarehouseNode = (
    <>
      <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 grow">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-3">
            <h2 className="text-lg md:text-xl font-semibold text-center">{t("locations.title")}</h2>
            <div className="flex items-center w-full md:w-fit gap-3">
              <Button className="shadow grow" type="primary" onClick={openLocationModal}>
                {t("locations.add_location")}
              </Button>
              {user.role_permissions.access_filials && (
                <Button className="shadow grow" type="primary" onClick={openFilialModal}>
                  {t("locations.add_filial")}
                </Button>
              )}
            </div>
          </div>
          <Table<LocationType>
            size="small"
            className="grow"
            columns={columns}
            dataSource={locations}
            rowKey="id_location"
            pagination={false}
            scroll={{ y: table_height }}
            loading={isTableLoading}
            bordered
          />
        </div>
      </div>
      <NewLocation isModalOpen={isLocationModalOpen} closeModal={closeModal} createLocation={createLocation} />
      <NewFilial isModalOpen={isFilialModalOpen} closeModal={closeModal} createFilial={createFilial} />
    </>
  );

  const WarehouseTableData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("directories.header")}
        <IconArrowNarrowRight />
        {t("locations.header")}
      </div>
    ),
    node: WarehouseNode,
  };

  return <StageLayout stage={WarehouseTableData} />;
}
