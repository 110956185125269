export default function RotatedImage({ image, degree }: { image: string; degree: number }) {
  // Function to rotate and return base64 image
  const getBase64 = (base64Data: string, deg: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const Img = new Image();
      Img.src = base64Data;

      // Handle image loading error
      Img.onerror = () => reject('Failed to load image');

      Img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const width = Img.width;
        const height = Img.height;
        const w = deg % 180 === 0 ? width : height;
        const h = deg % 180 === 0 ? height : width;
        const obj = {
          x: w / 2,
          y: h / 2,
          w: width,
          h: height,
        };

        // Set canvas size
        canvas.width = w;
        canvas.height = h;

        // Perform rotation and drawing
        ctx?.translate(obj.x, obj.y);
        ctx?.rotate((deg * Math.PI) / 180);
        ctx?.translate(-obj.x, -obj.y);

        // Draw the image onto the canvas
        ctx?.drawImage(Img, obj.x - obj.w / 2, obj.y - obj.h / 2, obj.w, obj.h);

        // Convert canvas to base64 data URL
        const dataURL = canvas.toDataURL();
        resolve(dataURL);
      };
    });
  };

  // Render the rotated image or a placeholder if not loaded
  return getBase64(image, degree);
}
