import { Api } from "api";
import { HostMappings } from "types/hostname-types";

export const getClientUrls = async () => {
  const hostname = window.location.hostname;
  const res = await Api.get(`/get_host_mappings/`);
  const clientUrls: HostMappings = res.data;

  Api.defaults.baseURL = clientUrls[hostname]?.api_url || process.env.REACT_APP_API_URL;
  
  return res.data;
};

export const authenticateUser = async (payload: { username: string; password: string }) => {
  const res = await Api.post(`/authenticate/`, payload);
  localStorage.setItem("hash", res.data.hashed_password);
  return res.data;
};

export const getUser = async () => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/check_user_auth/?hash_pw=${hash}`);
  return res.data;
};
