import { Button, Statistic } from "antd";
import { InventoryDashboardType } from "types/dashboard/inventory-dashboard-types";
import InventorizatorBarChart from "./inventorizator-bar";
import StatusBarChart from "./status-bar";
import InventoryDateChart from "./inventory-date-chart";
import InventoryMethodChart from "./inventory-method-pie";
import { useTranslation } from "react-i18next";
import compactNumber from "helpers/compact-number";
import { IconFilter } from "@tabler/icons-react";

export default function DashboardInventory({
  inventoryDashboardData,
  openFilters,
}: {
  inventoryDashboardData: InventoryDashboardType | null;
  openFilters: () => void;
}) {
  const { t } = useTranslation();
  const statsArray = [
    {
      title: t("dashboard.inventory_count"),
      value: inventoryDashboardData?.items.total_inventoried_items || 0,
      suffix: t("dashboard.pieces"),
    },
    {
      title: t("dashboard.inventory_items_count"),
      value: inventoryDashboardData?.items.total_items || 0,
      suffix: t("dashboard.pieces"),
    },
  ];

  return (
    <div className="flex flex-col gap-3 md:gap-4 lg:gap-6">
      <Button className="shadow md:hidden" icon={<IconFilter />} onClick={openFilters}>
        {t("inputs.search_title")}
      </Button>
      <div className="grid grid-cols-2 gap-3 md:gap-4 lg:gap-6 justify-center flex-wrap">
        {statsArray.map((stat, index) => (
          <div className="p-2 sm:p-3 md:p-4 lg:p-6 border rounded-md bg-white" key={index}>
            <Statistic
              className="h-full flex flex-col justify-between font-bold text-center"
              title={<div className="font-semibold text-black">{stat.title}</div>}
              value={compactNumber(stat.value)}
              suffix={stat.suffix}
              key={index}
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-4 lg:gap-6 justify-center flex-wrap">
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <InventorizatorBarChart inventoryDashboardData={inventoryDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <StatusBarChart inventoryDashboardData={inventoryDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <InventoryDateChart inventoryDashboardData={inventoryDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <InventoryMethodChart inventoryDashboardData={inventoryDashboardData} />
        </div>
      </div>
    </div>
  );
}
