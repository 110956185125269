import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "index.css";
import "assets/styles/styles.css";
import reportWebVitals from "reportWebVitals";
import { MainContextProvider } from "state/main-context";
import { NotificationProvider } from "state/notification-context";
import ProtectedRoute from "components/protected-route";
import AccountDeletionPage from "components/delete-account";
import NotFound from "components/error-pages/404";
import MainPage from "components/main-page";
import ScanFromMain from "components/scan-from-main";
import Employees from "components/employees";
import Dictionaries from "components/dictionaries";
import Locations from "components/dictionaries/locations";
import WriteOffReasons from "components/dictionaries/write-offs";
import RolesAndAccess from "components/dictionaries/roles-and-access";
import Batches from "components/dictionaries/batches";
import Registry from "components/registry";
import InvoicesList from "components/registry/invoices";
import InvoiceDetails from "components/registry/invoices/invoice-details";
import ItemsList from "components/registry/items";
import WriteOffsList from "components/registry/write-off";
import DashboardLayout from "components/dashboard/dashboard-layout";
import Register from "components/register";
import RegisterInvoiceList from "components/register/register-invoice-list";
import AddPhoto from "components/register/add-photo";
import MovingList from "components/moving/moving-list";
import MovingLocations from "components/moving/moving-locations";
import Inventory from "components/inventory";
import Basket from "components/inventory/table-to-item/basket";
import TableToItem from "components/inventory/table-to-item";
import ItemToTable from "components/inventory/item-to-table";
import InventoryList from "components/inventory/table-to-item/inventory-list";
import ChooseJournalType from "components/inventory/journal/choose-journal-type";
import JournalList from "components/inventory/journal/journal-list";
import JournalDetails from "components/inventory/journal/journal-details";
import PrintAgain from "components/print-again";
import ItemDetails from "components/registry/items/item-details";
import "i18n";

const App = () => {
  return (
    <Router>
      <MainContextProvider>
        <Routes>
          <Route
            path="/delete_account"
            element={
              <ProtectedRoute accessLevel={"0"}>
                <AccountDeletionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute accessLevel={"0"}>
                <NotFound />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute accessLevel={"0"}>
                <MainPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/scan-from-main"
            element={
              <ProtectedRoute accessLevel={"scan"}>
                <ScanFromMain />
              </ProtectedRoute>
            }
          />
          <Route
            path="/print-again"
            element={
              <ProtectedRoute accessLevel={"reprint"}>
                <PrintAgain />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employees"
            element={
              <ProtectedRoute accessLevel={"employees"}>
                <Employees />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dictionaries"
            element={
              <ProtectedRoute accessLevel={"0"}>
                <Dictionaries />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dictionaries/locations"
            element={
              <ProtectedRoute accessLevel={"locations"}>
                <Locations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dictionaries/write-off-reasons"
            element={
              <ProtectedRoute accessLevel={"reasons"}>
                <WriteOffReasons />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dictionaries/roles-and-access"
            element={
              <ProtectedRoute accessLevel={"roles"}>
                <RolesAndAccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dictionaries/batches"
            element={
              // TODO: change access level
              <ProtectedRoute accessLevel={"locations"}>
                <Batches />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registry"
            element={
              <ProtectedRoute accessLevel={"reestr"}>
                <Registry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registry/invoices"
            element={
              <ProtectedRoute accessLevel={"reestr"}>
                <InvoicesList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registry/invoices/:id_invoice"
            element={
              <ProtectedRoute accessLevel={"reestr"}>
                <InvoiceDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registry/items"
            element={
              <ProtectedRoute accessLevel={"reestr"}>
                <ItemsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registry/items/:id_item"
            element={
              <ProtectedRoute accessLevel={"access_item_logs"}>
                <ItemDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registry/write-off"
            element={
              <ProtectedRoute accessLevel={"reestr"}>
                <WriteOffsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/report"
            element={
              <ProtectedRoute accessLevel={"report"}>
                <DashboardLayout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <Inventory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/table-to-item"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <InventoryList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/table-to-item/basket"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <Basket />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/table-to-item/basket/check"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <TableToItem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/item-to-table"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <ItemToTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/journal-type"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <ChooseJournalType />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/journal-type/table-to-item"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <JournalList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/journal-type/item-to-table"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <JournalList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/journal-type/table-to-item/:filial/:id_user/:check_date"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <JournalDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/journal-type/item-to-table/:filial/:id_user/:check_date"
            element={
              <ProtectedRoute accessLevel={"inventory"}>
                <JournalDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registration"
            element={
              <ProtectedRoute accessLevel={"register"}>
                <Register />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registration/invoice-list"
            element={
              <ProtectedRoute accessLevel={"register"}>
                <RegisterInvoiceList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registration/:id_invoice"
            element={
              <ProtectedRoute accessLevel={"register"}>
                <AddPhoto />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product-moving"
            element={
              <ProtectedRoute accessLevel={"move"}>
                <MovingList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product-moving/confirm-page"
            element={
              <ProtectedRoute accessLevel={"move"}>
                <MovingLocations />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product-write-off"
            element={
              <ProtectedRoute accessLevel={"writeoff"}>
                <MovingList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product-write-off/confirm-page"
            element={
              <ProtectedRoute accessLevel={"writeoff"}>
                <MovingLocations />
              </ProtectedRoute>
            }
          />
        </Routes>
      </MainContextProvider>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <NotificationProvider>
    <App />
  </NotificationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
