import axios from "axios";
import i18n from "i18n";

const ApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

ApiInstance.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language === "ru" ? "ru" : "en";
  return config;
});

ApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error: any) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default ApiInstance;
export { ApiInstance as Api };
