import { Api } from "api";

export const getFilialLocations = async () => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/locations/?hash_pw=${hash}`);
  return res.data;
};

export const addLocation = async (payload: { filial: string; location_name: string; who_changed: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/add_location/?hash_pw=${hash}`, payload);
  return res.data;
};

export const updateLocationStatus = async (payload: {
  id_location: number;
  location_status: boolean;
  who_changed: number;
}) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.put(`/update_location_status/?hash_pw=${hash}`, payload);
  return res.data;
};
