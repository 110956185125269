import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { useEffect, useMemo, useState } from "react";
import { getEmployees, createEmployee } from "api/employee";
import { EmployeeUserData, emptyEmployeeUserData } from "types/employee-types";
import { Button, Select, Table, TableProps, Tag } from "antd";
import NewEmployee from "components/employees/new-employee";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { FilterOptionsTypes, getOptions } from "helpers/options-list";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";

export default function Employees() {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [employees, setEmployees] = useState<EmployeeUserData[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeUserData[]>([]);
  const [employeeToEdit, setEmployeeToEdit] = useState<EmployeeUserData>(emptyEmployeeUserData);
  const [isFiltered, setFiltered] = useState<boolean>(false);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const [options, setOptions] = useState<FilterOptionsTypes>({
    years: [],
    locationsList: [],
    locations: [],
    destinations: [],
    reasons: [],
    filials: [],
    all_employees: [],
    responsibles: [],
    batches: [],
    categories: [],
    months: [],
    sources: [],
    methods: [],
    statuses: [],
    actions: [],
  });

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 307;
    } else if (width < 768) {
      return height - 323;
    } else if (width < 1024) {
      return height - 235;
    } else return height - 290;
  }, [window.innerWidth]);

  useEffect(() => {
    getEmployeesData();
    getQueryValues();
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setEmployeeToEdit(emptyEmployeeUserData);
    setModalOpen(false);
  };

  const editEmployee = (employee: EmployeeUserData) => {
    setModalOpen(true);
    setEmployeeToEdit(employee);
  };

  const getEmployeesData = async () => {
    setTableLoading(true);
    await getEmployees(searchParams)
      .then((res: EmployeeUserData[]) => {
        setEmployees(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const saveEmployeeData = async (data: EmployeeUserData) => {
    setTableLoading(true);
    await createEmployee({ ...data, who_changed: user.user_id })
      .then(() => {
        getEmployeesData();
        showNotification("success", t("notifications.save_employee_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.save_employee_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
        closeModal();
      });
  };

  const getQueryValues = async () => {
    setTableLoading(true);

    await getOptions()
      .then((res) => {
        setOptions(res);
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      });
  };

  const filterLocations = (value: string) => {
    if (value) {
      const filtered = employees.filter((employee) => employee.location_name === value);
      setFilteredEmployees(filtered);
      setFiltered(true);
    } else {
      setFilteredEmployees([]);
      setFiltered(false);
    }
  };

  const employeeColumns: TableProps<EmployeeUserData>["columns"] = [
    {
      title: t("employees.name"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.name_surname.localeCompare(b.name_surname),
      width: 140,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("employees.action"),
      render: (value, record) => (
        <Button className="text-primary border-primary" type="dashed" onClick={() => editEmployee(record)}>
          {t("employees.edit_button")}
        </Button>
      ),
      width: 160,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("employees.position"),
      dataIndex: "position",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.gender"),
      dataIndex: "gender",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.phone"),
      dataIndex: "phone",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.email"),
      dataIndex: "email",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.department"),
      dataIndex: "department",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.employee_status"),
      dataIndex: "employee_status",
      render: (value) =>
        value ? <Tag color="success">{t("employees.active")}</Tag> : <Tag color="error">{t("employees.inactive")}</Tag>,
    },
    {
      title: t("employees.role"),
      dataIndex: "role_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.username"),
      dataIndex: "login",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("employees.password"),
      dataIndex: "password",
      render: (value) => (value !== null ? value.replace(/./g, "*") : "-"),
    },
    {
      title: t("employees.user_status"),
      dataIndex: "user_status",
      render: (value) =>
        value === true ? (
          <Tag color="success">{t("employees.active")}</Tag>
        ) : value === false ? (
          <Tag color="error">{t("employees.inactive")}</Tag>
        ) : (
          "-"
        ),
    },
  ];

  const UsersNode = (
    <>
      <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 grow">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-3">
            <h2 className="text-lg md:text-xl font-semibold text-center">{t("employees.title")}</h2>

            <div className="flex flex-wrap md:flex-nowrap items-center w-full md:w-fit gap-3">
              <Select
                className="w-full md:w-auto"
                placeholder={t("inputs.search_location")}
                options={options?.locations}
                onChange={filterLocations}
                allowClear
                showSearch
              />
              <Button className="shadow grow" type="primary" onClick={openModal}>
                {t("employees.add_button")}
              </Button>
            </div>
          </div>
          <Table<EmployeeUserData>
            size="small"
            className="grow"
            rowKey="id_employee"
            columns={employeeColumns}
            dataSource={isFiltered ? filteredEmployees : employees}
            pagination={false}
            loading={isTableLoading}
            scroll={{ x: 1600, y: table_height }}
            bordered
          />
        </div>
      </div>
      <NewEmployee
        isModalOpen={isModalOpen}
        data={employeeToEdit}
        closeModal={closeModal}
        saveEmployeeData={saveEmployeeData}
      />
    </>
  );

  const UserTableData: StageType = {
    id: 1,
    title: t("employees.header"),
    node: UsersNode,
  };

  return <StageLayout stage={UserTableData} />;
}
