import { Api } from "api";

export const getFilteredAmount = async (payload: { data: any[]; name_surname: string; location_name: string }) => {
  const res = await Api.post(`/filtered_data/`, payload);
  return res.data;
};

export const getImages = async (params: { folder: string; id: number }) => {
  const res = await Api.get(`/images/`, { params });
  return res.data;
};

export const moveItem = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/move_item/?hash_pw=${hash}`, payload);
  return res.data;
};

export const moveItems = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/move_items/?hash_pw=${hash}`, payload);
  return res.data;
};
