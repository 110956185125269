import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { CategoryType } from "types/ui/category-types";
import { IconFileText, IconPackage, IconPackageOff } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default function Registry() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const registryTypes: CategoryType[] = [
    { id: "invoices", title: t("registry.invoices"), icon: <IconFileText size={60} />, url: "/registry/invoices" },
    { id: "items", title: t("registry.items"), icon: <IconPackage size={60} />, url: "/registry/items" },
    {
      id: "write-off",
      title: t("registry.writeoff_items"),
      icon: <IconPackageOff size={60} />,
      url: "/registry/write-off",
    },
  ];

  const firstStageNode = (
    <div className="flex flex-col justify-center grow gap-4 md:gap-8 lg:gap-12 md:mx-12 lg:mx-16">
      <h2 className="text-lg md:text-xl font-bold text-center">{t("registry.choose_type")}</h2>
      <div className="grow sm:grow-0 grid grid-cols-1 sm:grid-cols-3 justify-items-center gap-4 md:gap-8 lg:gap-12 h-full sm:h-fit">
        {registryTypes.map((registry) => (
          <div
            className={`w-2/3 sm:w-full p-4 shadow rounded-xl md:rounded-2xl lg:rounded-3xl bg-white cursor-pointer ${
              registry.disabled === false ? "cursor-pointer" : "bg-[rgba(0,0,0,0.04)] cursor-not-allowed"
            }`}
            onClick={() => navigate(`${registry.url}`)}
            key={registry.id}
          >
            <div className="flex flex-col items-center justify-center gap-2 h-full md:h-48 lg:h-60">
              {registry.icon}
              <span className="text-lg md:text-xl font-bold text-center">{registry.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const firstStageData: StageType = {
    id: 1,
    title: t("registry.header"),
    node: firstStageNode,
  };

  return <StageLayout stage={firstStageData} />;
}
