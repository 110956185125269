import { useEffect, useState } from "react";
import { Button, Form, FormProps, Input, Modal, Select } from "antd";
import { LocationType } from "types/location-types";
import { getOptions, StringOption } from "helpers/options-list";
import { useNotification } from "state/notification-context";
import { useMainContext } from "state/main-context";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";

export default function NewLocation({
  isModalOpen,
  closeModal,
  createLocation,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  createLocation: (data: LocationType) => Promise<void>;
}) {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [filials, setFilials] = useState<StringOption[]>([]);

  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    isModalOpen && getFilterOptions();
  }, [isModalOpen]);

  useEffect(() => {
    form.resetFields();
  }, [closeModal]);

  const getFilterOptions = () => {
    getOptions()
      .then((res) => {
        if (user.role_permissions.access_filials) {
          setFilials(res.filials);
        } else {
          setFilials(res.filials.filter((f) => f.value === user.filial));
        }
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      });
  };

  const onFinish: FormProps<LocationType>["onFinish"] = async (payloadData) => {
    setLoading(true);

    createLocation(payloadData).finally(() => {
      setLoading(false);
      form.resetFields();
    });
  };

  return (
    <Modal
      title={t("locations.new_location_title")}
      open={isModalOpen}
      footer={false}
      closable={false}
      width={400}
      centered
    >
      <Form
        form={form}
        onFinish={onFinish}
        disabled={isLoading}
        clearOnDestroy
        validateMessages={{
          required: t("validations.required"),
        }}
        autoComplete="off"
      >
        <Form.Item<LocationType> name="id_location" hidden>
          <Input />
        </Form.Item>
        <Form.Item<LocationType> name="location_name" label={t("locations.name_column")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item<LocationType> name="filial" label={t("locations.filial_column")} rules={[{ required: true }]}>
          <Select options={filials} />
        </Form.Item>

        <div className="flex items-center justify-end gap-3">
          <Button type="primary" onClick={closeModal} loading={isLoading}>
            {t("locations.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("locations.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
