export interface BasketItem {
  id_item: number;
  item_name: string;
  quantity: number;
  id_location: number;
  location_name: string;
  unit_measurement: string;
  id_invoice: number;
  status: string;
  last_check_date: string;
  category: string;
  source: string;
  filial: string;
  name_surname: string;
  id_stock: number;
  sum_with_vat: number;
}

export interface BasketItems {
  items: BasketItem[];
  total_items: number;
}

export interface BasketPayloadItem {
  id_check_user: number;
  id_item: number;
  status: null;
}

export interface InventoryJournalItem {
  check_date: string;
  id_user: number;
  name_surname: string;
  unique_items: number;
  non_matching_items: number;
  method: string;
  filial: string;
}

export interface InventoryJournalList {
  items: InventoryJournalItem[];
  total_items: number;
}

export interface InventoryDetailItem {
  id_item: number;
  item_name: string;
  status: string;
  last_check_date: string;
  id_invoice: number;
  quantity_released: number;
  unit_price: number;
  sum_with_vat: number;
  name_surname: string;
  quantity_checked: number;
  method: string;
}

export const emptyBasketItem: BasketItem = {
  id_item: 0,
  item_name: "",
  quantity: 0,
  id_location: 0,
  location_name: "",
  unit_measurement: "",
  id_invoice: 0,
  status: "",
  last_check_date: "",
  category: "",
  source: "",
  filial: "",
  name_surname: "",
  id_stock: 0,
  sum_with_vat: 0,
};

export const emptyBasketItems: BasketItems = {
  items: [],
  total_items: 0,
};

export const emptyInventoryJournalItem: InventoryJournalItem = {
  check_date: "",
  id_user: 0,
  name_surname: "",
  unique_items: 0,
  non_matching_items: 0,
  method: "",
  filial: "",
};

export const emptyInventoryJournalList: InventoryJournalList = {
  items: [],
  total_items: 0,
};

export const emptyInventoryDetailItem: InventoryDetailItem = {
  id_item: 0,
  item_name: "",
  status: "",
  last_check_date: "",
  id_invoice: 0,
  quantity_released: 0,
  unit_price: 0,
  sum_with_vat: 0,
  name_surname: "",
  quantity_checked: 0,
  method: "",
};
