import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";

export default function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const notFoundPageTitle = <span>{t("error_page.not_found_title")}</span>;
  const notFoundPageSubtitle = <span className="text-lg">{t("error_page.not_found_subtitle")}</span>;

  return (
    <section className="flex flex-col justify-center h-[100dvh]">
      <Result
        status="404"
        title={notFoundPageTitle}
        subTitle={notFoundPageSubtitle}
        extra={
          <Button type="primary" onClick={() => navigate("/", { replace: true })}>
            {t("error_page.go_to_main")}
          </Button>
        }
      />
    </section>
  );
}
