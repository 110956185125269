import { IconFileTypePdf, IconPhoto } from "@tabler/icons-react";
import { Alert, Button, Image, Table, TableProps } from "antd";
import { getImages } from "api/moving";
import { getItems } from "api/registry";
import StageLayout from "components/layout/page-layout";
import QrScannerComponent from "components/qr-scanner";
import { useState } from "react";
import { RegistryItem, RegistryItemResponse } from "types/registry/item-types";
import { StageType } from "types/stage-types";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";
import { useMainContext } from "state/main-context";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function ScanFromMain() {
  const { setLoadingScreen } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [isTableCollapsed, setTableCollapsed] = useState<boolean>(true);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [inventoryCheckData, setInventoryCheckData] = useState<RegistryItem[]>([]);

  const [isItemFound, setItemFound] = useState<boolean | null>(null);
  const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const appendFilter = (key: string, value: string) => {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const openQrModal = () => {
    setIsQrModalOpen(true);
  };

  const closeQrScannerModal = () => {
    setIsQrModalOpen(false);
  };

  const getItemData = async (id_item: string) => {
    closeQrScannerModal();
    setTableLoading(true);

    if (id_item !== undefined) appendFilter("id_item", id_item.toString());

    await getItems(searchParams)
      .then((res: RegistryItemResponse) => {
        setInventoryCheckData(res.items);
        setItemFound(res.items.length ? true : false);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const toggleTableCollapsing = () => {
    setTableCollapsed(!isTableCollapsed);
  };

  const columns: TableProps<RegistryItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      width: 155,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      width: 185,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
    },

    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity_released",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.quantity_checked"),
      dataIndex: "quantity_checked",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
    },

    {
      title: t("table.stock_date"),
      dataIndex: "stock_date",
      render: (value) => (value ? new Date(value).toLocaleDateString() : "-"),
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.amortisation_value"),
      dataIndex: "amortisation_value",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },

    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value ? new Date(value).toLocaleDateString() : "-"),
      hidden: isTableCollapsed,
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => (value !== null ? value : "-"),
      hidden: isTableCollapsed,
    },
    {
      title: t("table.stock_count_auditor"),
      dataIndex: "inventory_responsible",
      render: (value) => (value !== null ? value : "-"),
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_number"),
      dataIndex: "invoice_number",
      render: (value) => (value !== null ? value : "-"),
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_date"),
      dataIndex: "invoice_date",
      render: (value) => (value ? new Date(value).toLocaleDateString() : "-"),
      hidden: isTableCollapsed,
    },

    {
      title: t("table.iin_bin"),
      dataIndex: "iin_bin",
      render: (value) => (value !== null ? value : "-"),
      hidden: isTableCollapsed,
    },
    {
      title: t("table.sender"),
      dataIndex: "sender",
      render: (value) => (value !== null ? value : "-"),
      hidden: isTableCollapsed,
    },
    {
      title: t("table.recipient"),
      dataIndex: "recipient",
      render: (value) => (value !== null ? value : "-"),
      hidden: isTableCollapsed,
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      hidden: isTableCollapsed,
    },
  ];

  const CheckNode = (
    <>
      <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 w-full">
          <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3">
            <h2 className="text-lg md:text-xl font-semibold">{t("scan_from_main.title")}</h2>
            <div className="flex flex-wrap md:flex-nowrap items-center gap-3">
              <Button
                className="shadow"
                icon={<IconFileTypePdf size={20} />}
                onClick={() => getItemImages("invoices", inventoryCheckData[0].id_invoice)}
                disabled={!inventoryCheckData.length}
              ></Button>

              <Button
                className="shadow"
                icon={<IconPhoto size={20} />}
                onClick={() => getItemImages("items", inventoryCheckData[0].id_item)}
                disabled={!inventoryCheckData.length}
              ></Button>

              <Button className="shadow grow" onClick={toggleTableCollapsing} disabled={!inventoryCheckData.length}>
                {isTableCollapsed ? t("table.expand_table") : t("table.collapse_table")}
              </Button>

              <Button className="shadow grow text-white bg-green-600" variant="solid" onClick={openQrModal}>
                {t("inventory.check_scan_button")}
              </Button>
            </div>
          </div>
          {isItemFound === false && (
            <Alert className="text-red-600" message={t("inventory.alert_not_found")} type="error" showIcon />
          )}
          <Table<RegistryItem>
            size="small"
            dataSource={inventoryCheckData}
            columns={columns}
            rowKey={(_, index) => (index || 0).toString()}
            loading={isTableLoading}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
      <QrScannerComponent isModalOpen={isQrModalOpen} onScan={getItemData} closeModal={closeQrScannerModal} />
      <div className="hidden">
        <Image.PreviewGroup
          items={itemImages}
          preview={{
            visible: previewVisible,
            destroyOnClose: true,
            imageRender: (original, info) => (
              <div className="relative flex max-h-[70%]">
                {original}
                <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                  {translatePhotoName(itemImages[info.current]?.name)}
                </div>
              </div>
            ),
            onVisibleChange: (value) => {
              setPreviewVisible(value);
              !value && setItemImages([]);
            },
          }}
        >
          <Image />
        </Image.PreviewGroup>
      </div>
    </>
  );

  const CheckStageData: StageType = {
    id: 1,
    title: <div className="flex items-center gap-2">{t("scan_from_main.header")}</div>,
    node: CheckNode,
  };

  return <StageLayout stage={CheckStageData} />;
}
