import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Table, TableProps } from "antd";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { getInventoryJournal } from "api/inventory";
import { useMainContext } from "state/main-context";
import {
  emptyInventoryJournalItem,
  emptyInventoryJournalList,
  InventoryJournalItem,
  InventoryJournalList,
} from "types/inventory-types";
import { useTranslation } from "react-i18next";
import { useNotification } from "state/notification-context";

export default function JournalList() {
  const { setInventoryJournalItem } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [inventoryData, setInventoryData] = useState<InventoryJournalList>(emptyInventoryJournalList);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(10);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 274;
    } else if (width < 768) {
      return height - 290;
    } else if (width < 1024) {
      return height - 286;
    } else return height - 342;
  }, [window.innerWidth]);

  const isTableToItem = useMemo(() => {
    return location.pathname.includes("table-to-item") ? true : false;
  }, [location]);

  const inventoryMethod = useMemo(() => {
    return isTableToItem ? t("inventory.table_to_item") : t("inventory.item_to_table");
  }, [isTableToItem]);

  useEffect(() => {
    setInventoryJournalItem(emptyInventoryJournalItem);
    getInventoryJournalData(currentLimit, 0);
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      const newOffset = (currentPage - 1) * currentLimit;
      getInventoryJournalData(currentLimit, newOffset);
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [currentLimit]);

  const appendFilter = (key: string, value: string) => {
    if (key !== "limit" && key !== "offset") {
      searchParams.set("limit", "10");
      searchParams.delete("offset");
    }

    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const getInventoryJournalData = async (limit?: number, offset?: number) => {
    setTableLoading(true);

    if (limit !== undefined) appendFilter("limit", limit.toString());
    if (offset !== undefined) appendFilter("offset", offset.toString());

    await getInventoryJournal(isTableToItem ? "От учета к товару" : "От товара к учету", searchParams)
      .then((res: InventoryJournalList) => {
        setInventoryData(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const onOpenDetails = (item: InventoryJournalItem) => {
    setInventoryJournalItem(item);
    navigate(`${location.pathname}/${item.filial}/${item.id_user}/${item.check_date}`);
  };

  const onTableChange: TableProps<InventoryJournalItem>["onChange"] = (pagination, filters, sorter, extra) => {
    if (!Array.isArray(sorter)) {
      if (sorter.order) {
        sorter && searchParams.set("sort_by", `${sorter.field}:${sorter.order === "ascend" ? "asc" : "desc"}`);
      } else {
        searchParams.delete("sort_by");
      }
    } else {
      searchParams.delete("sort_by");

      let reversedSorter = sorter.reverse();
      reversedSorter.forEach((sort) => {
        searchParams.append("sort_by", `${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`);
      });
    }

    getInventoryJournalData();
  };

  const columns: TableProps<InventoryJournalItem>["columns"] = [
    {
      title: t("table.stock_count_date"),
      dataIndex: "check_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.stock_count_auditor"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("inventory.unique_items"),
      dataIndex: "unique_items",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("inventory.non_matching_items"),
      dataIndex: "non_matching_items",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("inventory.method"),
      dataIndex: "method",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      render: (_, item) => <Button onClick={() => onOpenDetails(item)}>{t("inventory.details_button")}</Button>,
    },
  ];

  const thirdStageNode = (
    <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col gap-2 lg:gap-3 w-full grow">
        <h2 className="text-lg md:text-xl font-semibold">{t("inventory.details_title")}</h2>
        <Table<InventoryJournalItem>
          size="small"
          dataSource={inventoryData.items}
          columns={columns}
          rowKey={(_, index) => (index || 0).toString()}
          loading={isTableLoading}
          scroll={{ x: 1400, y: table_height }}
          bordered
          pagination={{
            disabled: isTableLoading,
            total: inventoryData.total_items,
            current: currentPage,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setCurrentLimit(pageSize);
            },
          }}
          onChange={onTableChange}
        />
      </div>
    </div>
  );

  const thirdStageData: StageType = {
    id: 3,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("inventory.header")}
        <IconArrowNarrowRight />
        {t("inventory.journal")}
        <IconArrowNarrowRight />
        {inventoryMethod}
      </div>
    ),
    node: thirdStageNode,
  };

  return <StageLayout stage={thirdStageData} />;
}
