import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { CategoryType } from "types/ui/category-types";
import { IconBuildingWarehouse, IconFileExport, IconLockAccess, IconPackages } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default function Dictionaries() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dictionaryTypes: CategoryType[] = [
    {
      id: "locations",
      title: t("directories.locations"),
      icon: <IconBuildingWarehouse size={60} />,
      url: "/dictionaries/locations",
    },
    {
      id: "write-off-reasons",
      title: t("directories.reasons"),
      icon: <IconFileExport size={60} />,
      url: "/dictionaries/write-off-reasons",
    },
    {
      id: "roles-and-access",
      title: t("directories.roles"),
      icon: <IconLockAccess size={60} />,
      url: "/dictionaries/roles-and-access",
    },
    {
      id: "batches",
      title: t("directories.batches"),
      icon: <IconPackages size={60} />,
      url: "/dictionaries/batches",
    },
  ];

  const DictionariesNode = (
    <div className="flex flex-col justify-center grow gap-4 md:gap-8 lg:gap-12 sm:m-8 md:mx-12 lg:mx-16">
      <h2 className="text-lg md:text-xl font-bold text-center">{t("inventory.choose_type_title")}</h2>
      <div className="grow sm:grow-0 grid grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-12 h-full md:h-fit">
        {dictionaryTypes.map((dictionary) => (
          <div
            className="p-4 shadow rounded-xl md:rounded-2xl lg:rounded-3xl bg-white cursor-pointer"
            onClick={() => navigate(`${dictionary.url}`)}
            key={dictionary.id}
          >
            <div className="flex flex-col items-center justify-center gap-2 h-full md:h-48 lg:h-60">
              {dictionary.icon}
              <span className="text-lg md:text-xl font-bold text-center">{dictionary.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const DictionariesData: StageType = {
    id: 1,
    title: t("directories.header"),
    node: DictionariesNode,
  };

  return <StageLayout stage={DictionariesData} />;
}
