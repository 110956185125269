import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { InventoryDashboardType } from "types/dashboard/inventory-dashboard-types";
import { useTranslation } from "react-i18next";

export default function StatusBarChart({
  inventoryDashboardData,
}: {
  inventoryDashboardData: InventoryDashboardType | null;
}) {
  const { t } = useTranslation();

  const sortedData = useMemo(() => {
    return inventoryDashboardData?.quantity_by_status || [];
  }, [inventoryDashboardData]);

  const handleStatusSplit = (status: string, first_half_length: number) => {
    let splittedStatus = status.split(" ");

    let firstHalf = splittedStatus.slice(0, first_half_length);
    let secondHalf = splittedStatus.slice(first_half_length);

    return [firstHalf.join(" ")].concat(secondHalf.join(" "));
  };

  const splittedStatus = useMemo(() => {
    return sortedData.map((item, index) => {
      switch (index) {
        case 0:
          return handleStatusSplit(item.status, 2);
        case 1:
          return handleStatusSplit(item.status, 1);
        default:
          return item.status;
      }
    });
  }, [sortedData]);

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    title: {
      text: t("dashboard.inventory_by_status"),
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: splittedStatus || [],
    },
  };
  return <ReactApexChart options={options} series={[{ data: sortedData.map((item) => item.quantity) }]} type="bar" />;
}
