import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { WriteOffDashboardType } from "types/dashboard/write-off-dashboard-types";
import { useTranslation } from "react-i18next";
import compactNumber from "helpers/compact-number";

export default function WriteOffDateChart({
  writeOffDashboardData,
}: {
  writeOffDashboardData: WriteOffDashboardType | null;
}) {
  const { t } = useTranslation();

  const writeOffChartData = useMemo(() => {
    return [
      {
        name: t("dashboard.quantity"),
        data: writeOffDashboardData?.writeoff_by_date.map((item) => item.quantity) || [],
      },
    ];
  }, [writeOffDashboardData]);

  const options: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: t("dashboard.writeoff_by_days"),
    },
    dataLabels: {
      enabled: false,
    },
    labels: writeOffDashboardData?.writeoff_by_date.map((item) => item.date) || [],
    yaxis: [
      {
        title: {
          text: t("dashboard.quantity"),
        },
        labels: {
          formatter: (value: any) => {
            return isNaN(+value) ? value : compactNumber(value);
          },
        },
      },
    ],
  };

  return <ReactApexChart options={options} series={writeOffChartData} type="line" />;
}
