export default function AccountDeletionPage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-2xl shadow-lg text-center max-w-md">
        <h1 className="text-2xl font-bold text-gray-800">Request Account Deletion</h1>
        <p className="text-gray-600 mt-4">
          If you want to delete your account and all associated data, please send us an email using the button below.
        </p>
        <p className="text-gray-600 mt-2">
          Make sure to include your registered email address and username for verification.
        </p>
        <a
          href="mailto:info@ai-tidaar.com?subject=Account%20Deletion%20Request"
          className="inline-block mt-6 bg-blue-500 text-white px-5 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Request Deletion
        </a>
      </div>
    </div>
  );
}
