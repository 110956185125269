export interface RegistryInvoiceItem {
  id_invoice: number;
  invoice_number: string;
  invoice_date: string;
  registration_date: string;
  name_surname: string;
  sender: string;
  iin_bin: string;
  category: string;
  source: string;
  filial: string;
  recipient: string;
  delivery_responsible: string;
  transport_organization: string;
  item_transport_invoice: string;
  sum_with_vat: number;
}

export interface RegistryInvoiceResponse {
  invoices: RegistryInvoiceItem[];
  total_invoices: number;
}

export interface RegistryInvoiceItemDetail {
  item_name: string;
  id_invoice: number;
  invoice_number: string;
  quantity: number;
  id_item: number;
  unit_measurement: string;
  status: string;
  last_check_date: string;
  location_name: string;
  quantity_released: number;
  quantity_checked: number;
  name_surname: string;
  sum_with_vat: number;
  unit_price: number;
}

export const emptyRegistryInvoiceItem: RegistryInvoiceItem = {
  id_invoice: 0,
  invoice_number: "",
  invoice_date: "",
  registration_date: "",
  name_surname: "",
  sender: "",
  iin_bin: "",
  category: "",
  source: "",
  filial: "",
  recipient: "",
  delivery_responsible: "",
  transport_organization: "",
  item_transport_invoice: "",
  sum_with_vat: 0,
};

export const emptyRegistryInvoiceResponse: RegistryInvoiceResponse = {
  invoices: [],
  total_invoices: 0,
};

export const emptyRegistryInvoiceItemDetail: RegistryInvoiceItemDetail = {
  item_name: "",
  id_invoice: 0,
  invoice_number: "",
  quantity: 0,
  id_item: 0,
  unit_measurement: "",
  status: "",
  last_check_date: "",
  location_name: "",
  quantity_released: 0,
  quantity_checked: 0,
  name_surname: "",
  sum_with_vat: 0,
  unit_price: 0,
};
