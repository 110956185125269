export interface Role {
  id_role: number | null;
  role: string;
  register: boolean;
  inventory: boolean;
  locations: boolean;
  reasons: boolean;
  employees: boolean;
  move: boolean;
  roles: boolean;
  report: boolean;
  writeoff: boolean;
  reestr: boolean;
  access_item_logs: boolean;
  scan: boolean;
  reprint: boolean;
  batch: boolean;
  access_filials: boolean;
}

export const emptyRole: Role = {
  id_role: null,
  role: "",
  register: false,
  inventory: false,
  locations: false,
  reasons: false,
  employees: false,
  move: false,
  roles: false,
  report: false,
  writeoff: false,
  reestr: false,
  access_item_logs: false,
  scan: false,
  reprint: false,
  batch: false,
  access_filials: false,
};
