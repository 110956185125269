import { Api } from "api";

export const getBasket = async (id_user: number, params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  let query = !!params ? `&${params}` : "";
  const res = await Api.get(`/items_with_locations_for_user/${id_user}/?hash_pw=${hash}${query}`);
  return res.data;
};

export const addToBasket = async (payload: any[]) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/add_to_temp_basket/?hash_pw=${hash}`, { items: payload });
  return res.data;
};

export const checkInventoryPrintQr = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/process_and_print_qr_codes_inventory/?hash_pw=${hash}`, payload);
  return res.data;
};

export const checkItemPrintQr = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/process_and_print_qr_codes_for_items/?hash_pw=${hash}`, payload);
  return res.data;
};

export const deleteFromBasket = async (payload: { id_items: number[]; id_check_user: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.delete(`/delete_temp_basket_items/?hash_pw=${hash}`, { data: payload });
  return res.data;
};

export const getItemsByQr = async (qr_id: number) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`get_item_with_invoice_by_id/?hash_pw=${hash}&item_id=${qr_id}`);
  return res.data;
};

export const updateItem = async (id_item: number, payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.put(`update_item/${id_item}?hash_pw=${hash}`, payload);
  return res.data;
};

export const addInventory = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/add_inventory/?hash_pw=${hash}`, payload);
  return res.data;
};

export const addImage = async (payload: { id_item: number; photo: string; action: string }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/upload_image/?hash_pw=${hash}`, payload);
  return res.data;
};

export const removeFromBasket = async (payload: any) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.delete(`/delete_temp_basket/?hash_pw=${hash}`, { data: payload });
  return res.data;
};

export const getInventoryJournal = async (method: string, params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  let query = !!params ? `&${params}` : "";
  const res = await Api.get(`inventory_status/${method}/?hash_pw=${hash}${query}`);
  return res.data;
};

export const getInventoryByDate = async (payload: {
  need_date: string;
  id_user: number;
  filial: string;
  method: string;
}) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/items_details_by_date_user/?hash_pw=${hash}`, payload);
  return res.data;
};
