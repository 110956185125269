export const header = {
  back_button_text: "Вернуться назад",
  main_button_text: "Главная",
  modal_title: "Подтвердите действие",
  modal_moving_text:
    "Если вы перейдёте с текущей страницы прогресс перемещения будет утерян и потребуется начать заново",
  modal_writeoff_text:
    "Если вы перейдёте с текущей страницы прогресс списания будет утерян и потребуется начать заново",
  modal_checking_text:
    "Если вы перейдёте с текущей страницы прогресс инвентаризации будет утерян и потребуется начать заново",
  modal_cancel: "Продолжить без сохранения",
  modal_confirm: "Остаться на странице",
};
