import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { MainDashboardType } from "types/dashboard/main-dashboard-types";
import { useTranslation } from "react-i18next";
import compactNumber from "helpers/compact-number";

export default function InvoiceChart({ mainDashboardData }: { mainDashboardData: MainDashboardType | null }) {
  const { t } = useTranslation();

  const invoiceSumByYear = useMemo(() => {
    return mainDashboardData?.invoices_by_date_quantity_value.reduce<Record<string, number>>((acc, item) => {
      const year = new Date(item.date).getFullYear();
      acc[year] = (acc[year] || 0) + item.value;
      return acc;
    }, {});
  }, [mainDashboardData]);

  const invoiceCountByYear = useMemo(() => {
    return mainDashboardData?.invoices_by_date_quantity_value.reduce<Record<string, number>>((acc, item) => {
      const year = new Date(item.date).getFullYear();
      acc[year] = (acc[year] || 0) + item.quantity;
      return acc;
    }, {});
  }, [mainDashboardData]);

  const invoiceChartData: ApexAxisChartSeries = useMemo(() => {
    return [
      {
        name: t("dashboard.amount"),
        type: "column",
        data: Object.entries(invoiceSumByYear ?? {}).map(([year, value]) => value),
      },
      {
        name: t("dashboard.amount"),
        type: "line",
        data: Object.entries(invoiceCountByYear ?? {}).map(([year, quantity]) => quantity),
      },
    ];
  }, [mainDashboardData]);

  const options: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: [0, 4],
    },
    title: {
      text: t("dashboard.invoice_by_date"),
    },
    dataLabels: {
      enabled: false,
    },
    labels: Object.entries(invoiceSumByYear ?? {}).map(([year, value]) => year),
    yaxis: [
      {
        opposite: true,
        title: {
          text: t("dashboard.amount"),
        },
        labels: {
          formatter: (value: any) => {
            return isNaN(+value) ? value : compactNumber(value);
          },
        },
      },
      {
        title: {
          text: t("dashboard.quantity"),
        },
        labels: {
          formatter: (value: any) => {
            return isNaN(+value) ? value : compactNumber(value);
          },
        },
      },
    ],
  };

  return <ReactApexChart options={options} series={invoiceChartData} type="line" />;
}
