export const inputs = {
  search_title: "Фильтры",
  search_esep_id: "Поиск по ESEP-ID",
  search_name: "Поиск по наименованию",
  search_user_writeoff: "Списал товар",
  search_mrp: "Поиск по МОЛ",
  search_sender: "Отправитель",
  search_iin: "Поиск по ИИН/БИН",
  search_location: "Поиск по локациям",
  search_batch: "Партия",
  search_category: "Категория",
  search_source: "Источник",
  search_filial: "Филиал",
  search_qr: "Поиск по QR",
  search_reset: "Сбросить фильтры",
};
