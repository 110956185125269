// src/components/ProtectedRoute.tsx
import React, { ReactNode, useMemo } from "react";
import { useLocation } from "react-router-dom";
import ForbiddenPage from "components/error-pages/403";
import { useMainContext } from "state/main-context";

interface ProtectedRouteProps {
  children: ReactNode;
  accessLevel: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, accessLevel }) => {
  const { user } = useMainContext();

  const location = useLocation();

  const hasAccess: boolean = useMemo(() => {
    // @ts-ignore
    return accessLevel === "0" ? true : user.role_permissions[accessLevel] ? true : false;
  }, [location]);

  if (!hasAccess) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
