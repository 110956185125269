import { Button, Statistic } from "antd";
import { WriteOffDashboardType } from "types/dashboard/write-off-dashboard-types";
import WriteOffDateChart from "./write-off-date-chart";
import WriteOffUserChart from "./write-off-user-pie";
import WriteOffReasonChart from "./write-off-reason-pie";
import WriteOffResponsibleChart from "./write-off-responsible-pie";
import { useTranslation } from "react-i18next";
import compactNumber from "helpers/compact-number";
import { IconFilter } from "@tabler/icons-react";

export default function DashboardWriteOff({
  writeOffDashboardData,
  openFilters,
}: {
  writeOffDashboardData: WriteOffDashboardType | null;
  openFilters: () => void;
}) {
  const { t } = useTranslation();

  const statsArray = [
    {
      title: t("dashboard.initial_value_written_off"),
      value: writeOffDashboardData?.items.items_value_writeoff || 0,
      suffix: "KZT",
    },
    {
      title: t("dashboard.written_off_goods"),
      value: writeOffDashboardData?.items.items_quantity_writeoff || 0,
      suffix: t("dashboard.pieces"),
    },
    {
      title: t("dashboard.written_off_items"),
      value: writeOffDashboardData?.items.items_count_writeoff || 0,
      suffix: t("dashboard.pieces"),
    },
  ];

  return (
    <div className="flex flex-col gap-3 md:gap-4 lg:gap-6">
      <Button className="shadow md:hidden" icon={<IconFilter />} onClick={openFilters}>
        {t("inputs.search_title")}
      </Button>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-4 lg:gap-6 justify-center flex-wrap">
        {statsArray.map((stat, index) => (
          <div className="p-2 sm:p-3 md:p-4 lg:p-6 border rounded-md bg-white" key={index}>
            <Statistic
              className="h-full flex flex-col justify-between font-bold text-center"
              title={<div className="font-semibold text-black">{stat.title}</div>}
              value={compactNumber(stat.value)}
              suffix={stat.suffix}
              key={index}
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-4 lg:gap-6 justify-center flex-wrap">
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <WriteOffDateChart writeOffDashboardData={writeOffDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <WriteOffUserChart writeOffDashboardData={writeOffDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <WriteOffReasonChart writeOffDashboardData={writeOffDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <WriteOffResponsibleChart writeOffDashboardData={writeOffDashboardData} />
        </div>
      </div>
    </div>
  );
}
