import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Image, Table, TableProps, Tag } from "antd";
import { IconArrowNarrowRight, IconFileTypePdf, IconPhoto } from "@tabler/icons-react";
import { getInventoryByDate } from "api/inventory";
import { useMainContext } from "state/main-context";
import { InventoryDetailItem, InventoryJournalItem } from "types/inventory-types";
import { getImages } from "api/moving";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function JournalDetails() {
  const { setLoadingScreen, inventoryJournalItem } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const location = useLocation();

  const { filial, id_user, check_date } = useParams();

  const [inventoryDetailData, setInventoryDetailData] = useState<InventoryDetailItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<InventoryDetailItem[]>([]);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 444;
    } else if (width < 768) {
      return height - 460;
    } else if (width < 1024) {
      return height - 456;
    } else return height - 472;
  }, [window.innerWidth]);

  const isTableToItem = useMemo(() => {
    return location.pathname.includes("table-to-item") ? true : false;
  }, [location]);

  const inventoryMethod = useMemo(() => {
    return isTableToItem ? t("inventory.table_to_item") : t("inventory.item_to_table");
  }, [isTableToItem]);

  useEffect(() => {
    getInventoryJournalItem();
  }, []);

  const getInventoryJournalItem = async () => {
    setTableLoading(true);

    await getInventoryByDate({
      need_date: new Date(check_date || "").toLocaleDateString("ru-RU"),
      id_user: +(id_user || 0),
      filial: filial || "",
      method: isTableToItem ? "От учета к товару" : "От товара к учету",
    })
      .then((res: InventoryDetailItem[]) => {
        setInventoryDetailData(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const columns: TableProps<InventoryJournalItem>["columns"] = [
    {
      title: t("table.stock_count_date"),
      dataIndex: "check_date",
      render: (value) => new Date(value).toLocaleDateString(),
    },
    {
      title: t("table.stock_count_auditor"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("inventory.unique_items"),
      dataIndex: "unique_items",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("inventory.non_matching_items"),
      dataIndex: "non_matching_items",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("inventory.method"),
      dataIndex: "method",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
    },
  ];

  const detaildColumns: TableProps<InventoryDetailItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.id_item - b.id_item,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
    },
    {
      title: t("table.quantity_checked"),
      dataIndex: "quantity_checked",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.quantity_checked - b.quantity_checked,
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity_released",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.quantity_released - b.quantity_released,
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.unit_price - b.unit_price,
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.sum_with_vat - b.sum_with_vat,
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => <Tag color={value === t("inventory.is_match_button") ? "success" : "error"}>{value}</Tag>,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
  ];

  const rowSelection: TableProps<InventoryDetailItem>["rowSelection"] = {
    type: "radio",
    onChange: (selectedRowKeys: React.Key[], selectedRows: InventoryDetailItem[]) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record: InventoryDetailItem) => ({
      disabled: false, // Column configuration not to be checked
      name: record.item_name,
    }),
  };

  const fourthStageNode = (
    <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col gap-3 w-full grow">
        <div className="flex flex-col lg:flex-row lg:items-end flex-wrap lg:justify-between gap-3">
          <h2 className="text-lg md:text-xl font-semibold">{t("inventory.journal_details_title")}</h2>
          <div className="flex items-center flex-wrap gap-3">
            <Button
              className="shadow grow"
              icon={<IconFileTypePdf size={20} />}
              onClick={() => getItemImages("invoices", selectedItems[0].id_invoice)}
              disabled={!selectedItems.length}
            >
              {t("photo.open_pdf")}
            </Button>
            <Button
              className="shadow grow"
              icon={<IconPhoto size={20} />}
              onClick={() => getItemImages("items", selectedItems[0].id_item)}
              disabled={!selectedItems.length}
            >
              {t("photo.open_photo")}
            </Button>
            <div className="hidden">
              <Image.PreviewGroup
                items={itemImages}
                preview={{
                  visible: previewVisible,
                  destroyOnClose: true,
                  imageRender: (original, info) => (
                    <div className="relative flex max-h-[70%]">
                      {original}
                      <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                        {translatePhotoName(itemImages[info.current]?.name)}
                      </div>
                    </div>
                  ),
                  onVisibleChange: (value) => {
                    setPreviewVisible(value);
                    !value && setItemImages([]);
                  },
                }}
              >
                <Image />
              </Image.PreviewGroup>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 grow">
          <Table<InventoryJournalItem>
            size="small"
            dataSource={[inventoryJournalItem]}
            columns={columns}
            rowKey={(_, index) => (index || 0).toString()}
            loading={isTableLoading}
            pagination={false}
            scroll={{ x: 1200 }}
          />
          <h6>{t("inventory.checked_items_title")}</h6>
          <Table<InventoryDetailItem>
            size="small"
            className="grow"
            dataSource={inventoryDetailData}
            columns={detaildColumns}
            rowSelection={rowSelection}
            rowKey={(_, index) => (index || 0).toString()}
            loading={isTableLoading}
            pagination={{ defaultPageSize: 40, hideOnSinglePage: true }}
            scroll={{ x: 1400, y: table_height }}
          />
        </div>
      </div>
    </div>
  );

  const fourthStageData: StageType = {
    id: 4,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("inventory.header")}
        <IconArrowNarrowRight />
        {t("inventory.journal")}
        <IconArrowNarrowRight />
        {inventoryMethod}
      </div>
    ),
    node: fourthStageNode,
  };

  return <StageLayout stage={fourthStageData} />;
}
