export const notifications = {
  // auth
  auth_success: "Authorization successful",
  auth_error: "Authorization error",
  logout_success: "Logout successful",
  user_data_error: "Error while retrieving user data",
  // get data
  data_error: "Error while getting data",
  item_count_error: "Error while retrieving quantity of goods",
  // filters
  filters_error: "Failed to get filters",
  // export
  export_success: "Data successfully exported",
  // photo
  photo_error: "No photos",
  // scan
  scan_error: "Error while scanning",
  scan_error_scanner: "Failed to connect to scanner",
  // qr
  qr_access_denied: "Camera access denied",
  qr_print_success: "QR code(s) printed",
  qr_error: "Error printing QR codes",
  qr_error_printer: "Unable to connect to printer",
  // register
  save_invoice_error: "Error saving invoice",
  save_invoice_success: "Invoice saved successfully",
  parse_invoice_error: "Error processing invoice",
  parse_invoice_in_progress: "Document is being processed. Please wait",
  parse_queue_count: "Active processing sessions:",
  parse_invoice_success: "Document successfully processed",
  invoice_register_error: "Error saving items",
  invoice_register_success: "Items successfully saved",
  item_register_success: "Item successfully saved",
  // transfer / write-off
  transfer_success: "Item successfully moved",
  writeoff_success: "Item successfully written off",
  transfer_error: "Error moving items",
  writeoff_error: "Error writing off items",
  // stock count
  stock_count_success: "Stock count successfully saved",
  stock_count_error: "Error saving stock count",
  basket_error: "Error retrieving cart",
  add_to_basket_success: "Item(s) added to cart",
  add_to_basket_error: "Error adding to cart",
  delete_from_basket_success: "Item(s) removed from cart",
  delete_from_basket_error: "Error removing from cart",
  // employees
  save_employee_success: "Employee data successfully saved",
  save_employee_error: "Error saving employee data",
  // locations
  create_location_success: "Location successfully created",
  create_location_error: "Error creating location",
  create_filial_success: "Filial successfully created",
  create_filial_error: "Error creating filial",
  edit_location_success: "Location successfully changed",
  edit_location_error: "Error changing location",
  create_writeoff_reason_success: "Write-off reason successfully created",
  create_writeoff_reason_error: "Error creating write-off reason",
  // roles
  edit_role_success: "Role successfully changed",
  edit_role_error: "Error changing role",
  create_role_success: "Role successfully created",
  create_role_error: "Error creating role",
  // batches
  create_batch_success: "Batch successfully created",
  create_batch_error: "Error creating batch",
  edit_batch_success: "Batch successfully modified",
  edit_batch_error: "Error modifying batch",
  add_to_batch_success: "Products successfully added to batch",
  add_to_batch_error: "Error adding to batch",
  delete_from_batch_success: "Products successfully removed from batch",
  delete_from_batch_error: "Error removing from batch",
};
