export const inputs = {
  search_title: "Filters",
  search_esep_id: "Search by ESEP-ID",
  search_name: "Search by name",
  search_user_writeoff: "Written off by",
  search_mrp: "Search by MRP",
  search_sender: "Sender",
  search_iin: "Search by TIN/BIN",
  search_location: "Search by location",
  search_batch: "Batch",
  search_category: "Category",
  search_source: "Source",
  search_filial: "Filial",
  search_qr: "Search by QR code",
  search_reset: "Reset filters",
};
