import { Button, Statistic, Table, TableProps } from "antd";
import { useTranslation } from "react-i18next";
import compactNumber from "helpers/compact-number";
import { IconFilter } from "@tabler/icons-react";
import { ActionLogType, ActionsDashboardType } from "types/dashboard/actions-dashboard-types";
import ExportTable from "components/registry/export-to-xlsx";
import { formatExcel } from "helpers/format-excel";

export default function DashboardActions({
  actionsDashboardData,
  openFilters,
}: {
  actionsDashboardData: ActionsDashboardType | null;
  openFilters: () => void;
}) {
  const { t } = useTranslation();

  const getDataForExport = async () => {
    return new Promise<any[]>(async (resolve) => {
      // eslint-disable-next-line
      formatExcel(actionsDashboardData?.detailed_logs || [], location.pathname).then((formattedData) => {
        resolve(formattedData);
      });
    });
  };

  const actionStatsArray = Object.entries(actionsDashboardData?.action_counts || {}).map(([title, value]) => ({
    title,
    value,
    suffix: t("dashboard.pieces"),
  }));

  const columns: TableProps<ActionLogType>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.id_item - b.id_item,
      width: 200,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      width: 300,
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.name_surname.localeCompare(b.name_surname),
      width: 200,
    },
    {
      title: t("table.action_name"),
      dataIndex: "action_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.action_name.localeCompare(b.action_name),
      width: 250,
    },
    {
      title: t("table.action_type"),
      dataIndex: "action_type",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.action_type.localeCompare(b.action_type),
      width: 200,
    },
    {
      title: t("table.action_date"),
      dataIndex: "action_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: (a, b) => a.action_date.localeCompare(b.action_date),
      width: 150,
    },
  ];

  return (
    <div className="h-[inherit] flex flex-col gap-3 md:gap-4 lg:gap-6">
      <div className="flex items-center gap-3 md:hidden">
        <Button className="shadow w-full" icon={<IconFilter />} onClick={openFilters}>
          {t("inputs.search_title")}
        </Button>
        <ExportTable getAllData={getDataForExport} buttonWithText />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-3 md:gap-4 lg:gap-6 justify-center flex-wrap">
        {actionStatsArray.map((stat, index) => (
          <div className="p-2 sm:p-3 md:p-4 lg:p-6 border rounded-md bg-white" key={index}>
            <Statistic
              className="h-full flex flex-col justify-between font-bold text-center"
              title={<div className="font-semibold text-black">{stat.title}</div>}
              value={compactNumber(stat.value)}
              suffix={stat.suffix}
              key={index}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-col items-end gap-3 shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <Table<ActionLogType>
          size="small"
          className="grow max-h-dvh"
          dataSource={actionsDashboardData?.detailed_logs}
          columns={columns}
          rowKey={(_, index) => (index || 0).toString()}
          bordered
          scroll={{ y: window.innerHeight - 100 }}
        />
      </div>
    </div>
  );
}
