import { useEffect, useMemo, useState } from "react";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Radio, Space, Spin, Table, Tag } from "antd";
import type { RadioChangeEvent, TableProps } from "antd";
import { checkQrCode, getQrCode } from "api/registration";
import { useMainContext } from "state/main-context";
import { TableWithPhoto } from "types/registration-types";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";

export default function ChooseQRCount({ setStep }: { setStep: (step: number) => void }) {
  const { user, reactData, setReactData } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [isOS, setOS] = useState<boolean>(false);

  const [isMultipleQrCount, setMultipleQrCount] = useState<boolean | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
    savedRegister.category === "ОС" && setOS(true);

    let inProcess = JSON.parse(localStorage.getItem("in_process") || "{}");
    inProcess[0]?.esep_id ? setStep(6) : setReactData(inProcess);
  }, []);

  const isItemCountInteger = useMemo(() => {
    return reactData?.every((item) => Number.isInteger(+item.quantity)) ? true : false;
  }, [reactData]);

  const onChange = (e: RadioChangeEvent) => {
    setMultipleQrCount(e.target.value);
  };

  const getItemQrCode = async () => {
    setLoading(true);

    const dataForQr = {
      selected: {
        "Номер по порядку": reactData[0].row_number,
        "Наименование, характеристика": reactData[0].name,
        "Номенклатурный номер": reactData[0].nomenclature_number,
        "Единица измерения": reactData[0].unit_measurement,
        "Количество отпущено": +reactData[0].quantity,
        "Цена за единицу, в KZT": reactData[0].unit_price,
        "Сумма с НДС, в KZT": reactData[0].total_with_vat,
        "Сумма НДС, в KZT": reactData[0].vat,
        "Срок полезной службы": reactData[0].lifecycle,
      },
      id_5: user.id_5.toString(),
      activator_toggle: isMultipleQrCount || false,
    };

    await checkQrCode(dataForQr)
      .then(async (res: any) => {
        await getQrCode({ qr_images: res.qr_images });
        showNotification("success", t("notifications.qr_print_success"));

        let dataWithQr = Array.from(
          res.data,
          (item: any) =>
            (item = {
              ...reactData[0],
              esep_id: item.id,
              quantity: reactData[0].quantity / res.data.length,
              total_with_vat: reactData[0].total_with_vat / res.data.length,
            })
        );
        localStorage.setItem("in_process", JSON.stringify(dataWithQr));

        setStep(6);
      })
      .catch((error: any) => {
        if (error.code === "ERR_NETWORK") {
          showNotification("error", t("notifications.qr_error"), t("notifications.qr_error_printer"));
        } else {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.qr_error"), err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const itemColumns: TableProps<TableWithPhoto>["columns"] = [
    {
      title: t("table.row_number"),
      dataIndex: "row_number",
    },
    {
      title: t("table.item_name"),
      dataIndex: "name",
    },
    {
      title: t("table.nomenclature_number"),
      dataIndex: "nomenclature_number",
    },
    {
      title: t("table.unit_measurement"),
      dataIndex: "unit_measurement",
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "total_with_vat",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.vat"),
      dataIndex: "vat",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      hidden: !isOS,
    },
    {
      title: "E-SEP ID",
      dataIndex: "esep_id",
      render: (esep_id: string) => <Tag color={esep_id ? "success" : "error"}>{esep_id || t("register.absence")}</Tag>,
    },
  ];

  const qrPrintingNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">{t("register.qr_print_in_progress")}</h2>
      <Spin size="large"></Spin>
    </div>
  );

  const fifthStageNode = (
    <div className="flex flex-col gap-3 grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3">
        <div className="flex flex-col gap-2">
          <div className="text-base font-medium">{t("register.qr_print_count")}</div>

          <Radio.Group onChange={onChange} value={isMultipleQrCount}>
            <Space className="pl-4" direction="vertical">
              <Radio value={true} disabled={!isItemCountInteger}>
                {t("register.qr_print_multiple")}
              </Radio>
              <Radio value={false}>{t("register.qr_print_single")}</Radio>
            </Space>
          </Radio.Group>
        </div>

        <Button className="shadow" type="primary" disabled={isMultipleQrCount === null} onClick={getItemQrCode}>
          {t("register.qr_print_continue")}
        </Button>
      </div>

      <Table<TableWithPhoto>
        size="small"
        columns={itemColumns}
        dataSource={reactData.length ? reactData : []}
        rowKey={(_, index) => (index || 0).toString()}
        bordered
        pagination={false}
      />
    </div>
  );

  const fifthStageData: StageType = {
    id: 1,
    title: t("register.header"),
    node: isLoading ? qrPrintingNode : fifthStageNode,
  };

  return <StageLayout stage={fifthStageData} />;
}
