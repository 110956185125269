import { Api } from "api";

export const getInvoices = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  let query = !!params ? `&${params}` : "";
  const res = await Api.get(`/read_invoices/?hash_pw=${hash}${query}`);
  return res.data;
};

export const getInvoiceItems = async (id_invoice: number) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/get_items_with_locations_invoice/${id_invoice}?hash_pw=${hash}`);
  return res.data;
};

export const getItems = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  let query = !!params ? `&${params}` : "";
  const res = await Api.get(`/Item_Details/?hash_pw=${hash}${query}`);
  return res.data;
};

export const getItemLogs = async (id_item: number) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/get_logs_for_item/${id_item}/?hash_pw=${hash}`);
  return res.data;
};

export const getWriteOffs = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  let query = !!params ? `&${params}` : "";
  const res = await Api.get(`/writeoffs_with_invoice_details/?hash_pw=${hash}${query}`);
  return res.data;
};
