import { Api } from "api";

export const getBatches = async () => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/batches/?hash_pw=${hash}`);
  return res.data;
};

export const addBatch = async (payload: { batch: string; filial: string; who_changed: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/add_batch/?hash_pw=${hash}`, payload);
  return res.data;
};

export const updateBatchStatus = async (payload: { id_batch: number; status: boolean; who_changed: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.put(`/update_batch/?hash_pw=${hash}`, payload);
  return res.data;
};

export const addToBatch = async (payload: { id_items: number[]; id_batch: number; id_user: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/move_item_batch/?hash_pw=${hash}`, payload);
  return res.data;
};

export const deleteFromBatch = async (payload: { id_items: number[]; id_batch: number; id_user: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.delete(`/delete_item_batch/?hash_pw=${hash}`, { data: payload });
  return res.data;
};
