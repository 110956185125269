import { useEffect, useState } from "react";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Spin, Table, Tag } from "antd";
import type { TableProps } from "antd";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { useMainContext } from "state/main-context";
import { TableWithPhoto } from "types/registration-types";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";

export default function ConfirmSave({ setStep }: { setStep: (step: number) => void }) {
  const { reactData, setReactData } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [isOS, setOS] = useState<boolean>(false);

  const [isSaving, setSaving] = useState<boolean>(false);
  const [isProductMapSaved, setProductMapSaved] = useState<boolean>(false);

  useEffect(() => {
    let inProcess = JSON.parse(localStorage.getItem("in_process") || "{}");
    setReactData(inProcess);

    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
    savedRegister.category === "ОС" && setOS(true);
  }, []);

  const saveItemMap = async () => {
    setSaving(true);

    reactData.forEach((item) => {
      let tableWithPhoto = [{ ...item, photo: "uploaded" }];

      localStorage.setItem("in_process", JSON.stringify(tableWithPhoto));
    });

    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");

    let incompletedItems = savedRegister.data.table_data.filter(
      (item: TableWithPhoto) => item.row_number !== reactData[0].row_number
    );

    let newTableData: TableWithPhoto[] = incompletedItems.concat(reactData);
    savedRegister.data.table_data = newTableData.sort((a, b) => a.row_number - b.row_number);

    localStorage.setItem("register", JSON.stringify(savedRegister));

    setProductMapSaved(true);

    showNotification("success", t("notifications.item_register_success"));

    setTimeout(() => {
      setStep(4);
    }, 2000);
  };

  const itemColumns: TableProps<TableWithPhoto>["columns"] = [
    // {
    //   title: t("table.row_number"),
    //   dataIndex: "row_number",
    // },
    {
      title: t("table.item_name"),
      dataIndex: "name",
    },
    {
      title: t("table.nomenclature_number"),
      dataIndex: "nomenclature_number",
    },
    {
      title: t("table.unit_measurement"),
      dataIndex: "unit_measurement",
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "total_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.vat"),
      dataIndex: "vat",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      hidden: !isOS,
    },
    {
      title: "E-SEP ID",
      dataIndex: "esep_id",
      render: (esep_id: string) => <Tag color={esep_id ? "success" : "error"}>{esep_id || t("register.absence")}</Tag>,
    },
  ];

  const sixthStageNode = (
    <div className="flex flex-col gap-3 grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3">
        <h2 className="text-lg md:text-xl font-bold text-green-600">{t("register.qr_ready_title")}</h2>

        <Button className="shadow" type="primary" onClick={saveItemMap}>
          {t("register.qr_ready_continue")}
        </Button>
      </div>

      <Table<TableWithPhoto>
        size="small"
        columns={itemColumns}
        dataSource={reactData.length ? reactData : []}
        rowKey={(_, index) => (index || 0).toString()}
        bordered
        pagination={false}
      />
    </div>
  );

  const sixthStageSavingNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">
        {isProductMapSaved ? t("register.product_saved") : t("register.product_saving")}
      </h2>
      {isProductMapSaved ? <IconCircleCheckFilled className="fill-primary" size={200} /> : <Spin size="large"></Spin>}
    </div>
  );

  const sixthStageData: StageType = {
    id: 1,
    title: t("register.header"),
    node: isSaving ? sixthStageSavingNode : sixthStageNode,
  };

  return (
    <>
      <StageLayout stage={sixthStageData} />
    </>
  );
}
