import { Api } from "api";

export const getWriteOffReasons = async () => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/write_offs/?hash_pw=${hash}`);
  return res.data;
};

export const addWriteOffReason = async (payload: { reason: string, who_changed: number }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/add_writeoff/?hash_pw=${hash}`, payload);
  return res.data;
};
