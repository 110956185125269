import axios from "axios";

const LocalInstance = axios.create({
  baseURL: "http://localhost:5000",
  headers: {
    "Accept-Language": "ru",
  },
});

LocalInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error: any) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("hash");
      window.location.href = "/";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default LocalInstance;
