import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

interface QrScannerProps {
  isModalOpen: boolean;
  onScan: (esep_id: string) => void;
  closeModal: () => void;
}

const QrScannerComponent: React.FC<QrScannerProps> = ({ isModalOpen, onScan, closeModal }) => {
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [qrScannerInstance, setQrScannerInstance] = useState<QrScanner | null>(null);

  const initQrScanner = () => {
    // @ts-ignore
    const qrScanner = new QrScanner(videoRef.current, (result: string) => {
      onScan(result);
      qrScanner.stop();
    });

    setQrScannerInstance(qrScanner);
  };

  const destroyQrScanner = () => {
    qrScannerInstance?.stop();
    closeModal();
  };

  const handleStartScanning = async () => {
    if (qrScannerInstance && videoRef.current) {
      try {
        await qrScannerInstance.start();
      } catch (err) {
        setError(t("notifications.qr_access_denied"));
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (isModalOpen) initQrScanner();
  }, [isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <div>
      <Modal
        title="QR Scanner"
        open={isModalOpen}
        afterOpenChange={handleStartScanning}
        onCancel={destroyQrScanner}
        footer={false}
        keyboard={false}
        centered
      >
        {error && <p className="text-red-500">{error}</p>}
        <video ref={videoRef} style={{ width: "100%", height: "auto" }} />
      </Modal>
    </div>
  );
};

export default QrScannerComponent;
