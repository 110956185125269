export const main = {
  scan: "Сканирование QR",
  print: "Повторная печать QR",
  employees: "Сотрудники",
  dictionaries: "Справочники",
  change_language: "Сменить язык",
  logout: "Выйти",

  registry: "Реестр",
  report: "Отчёты",
  inventory: "Инвентаризация",
  registration: "Регистрация",
  product_moving: "Перемещение",
  product_write_off: "Списание",
};
