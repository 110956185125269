export const batches = {
  header: "Batches",
  name_column: "Name",
  filial_column: "Filial",
  status_column: "Status",
  active: "Active",
  inactive: "Inactive",
  deactivate: "Deactivate",
  activate: "Activate",
  title: "Batches list",
  add_batch: "Add batch",
  // transfer
  transfer_alert_1: "1. Select products in the table to add to the batch.",
  transfer_alert_2: "2. Select the desired part from the drop-down menu on the right.",
  transfer_alert_3: "3. Click 'Add to batch'.",
  transfer_title: "Select a product to add to the batch",
  transfer_add: "Add to batch",
  delete_title: "Confirm action",
  delete_content: "Delete selected items from batches?",
  delete_cancel: "Cancel",
  delete_confirm: "Delete",
  delete_from_batch: "Delete from batch",
  choose_batch: "Select batch",
  // new batch
  new_batch_title: "New batch",
  cancel: "Cancel",
  save: "Save",
};
