import { useState } from "react";
import ChooseCategory from "./1-choose-category";
import ScanningPage from "./2-scanning-page";
import EditScannedDocument from "./3-edit-scanned-document";
import InvoiceAfterScan from "./4-invoice-after-scan";
import ChooseQRCount from "./5-choose-qr-count";
import ConfirmSave from "./6-confirm-save";
import { FloatButton } from "antd";

const Register = () => {
  const [activeComponent, setActiveComponent] = useState<number>(1);

  const renderComponent = () => {
    switch (activeComponent) {
      case 1:
        return <ChooseCategory setStep={setActiveComponent} />;
      case 2:
        return <ScanningPage setStep={setActiveComponent} />;
      case 3:
        return <EditScannedDocument setStep={setActiveComponent} />;
      case 4:
        return <InvoiceAfterScan setStep={setActiveComponent} />;
      case 5:
        return <ChooseQRCount setStep={setActiveComponent} />;
      case 6:
        return <ConfirmSave setStep={setActiveComponent} />;
      default:
        return <ChooseCategory setStep={setActiveComponent} />;
    }
  };

  return (
    <>
      {renderComponent()}
      {/* <FloatButton.Group shape="circle" style={{ insetInlineEnd: 24 }}>
        <FloatButton icon={1} onClick={() => setActiveComponent(1)} />
        <FloatButton icon={2} onClick={() => setActiveComponent(2)} />
        <FloatButton icon={3} onClick={() => setActiveComponent(3)} />
        <FloatButton icon={4} onClick={() => setActiveComponent(4)} />
        <FloatButton icon={5} onClick={() => setActiveComponent(5)} />
        <FloatButton icon={6} onClick={() => setActiveComponent(6)} />
      </FloatButton.Group> */}
    </>
  );
};

export default Register;
