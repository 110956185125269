import { useEffect, useState } from "react";
import { Button, Form, FormProps, Input, Modal } from "antd";
import { BatchType } from "types/batch-types";
import { useTranslation } from "react-i18next";

export default function NewBatch({
  isModalOpen,
  closeModal,
  createBatch,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  createBatch: (data: BatchType) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [closeModal]);

  const onFinish: FormProps<BatchType>["onFinish"] = async (payloadData) => {
    setLoading(true);

    createBatch(payloadData).finally(() => {
      setLoading(false);
      form.resetFields();
    });
  };

  return (
    <Modal title={t("batches.new_batch_title")} open={isModalOpen} footer={false} closable={false} width={400} centered>
      <Form form={form} onFinish={onFinish} disabled={isLoading} clearOnDestroy autoComplete="off">
        <Form.Item<BatchType> name="id_batch" required hidden>
          <Input />
        </Form.Item>
        <Form.Item<BatchType> name="batch" label={t("batches.name_column")} required>
          <Input />
        </Form.Item>

        <div className="flex items-center justify-end gap-3">
          <Button type="primary" onClick={closeModal} loading={isLoading}>
            {t("batches.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("batches.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
