import i18n from "i18n";

export const translatePhotoName = (name: string): string => {
  switch (i18n.language) {
    case "en":
      // Registration
      if (name?.startsWith("Регистрация товара (фото)")) {
        let splittedName = name.split("_");
        splittedName.shift();

        return `Registration, date: ${splittedName.join("_")}`;
      }

      // Stock count
      if (name?.startsWith("Инвентаризация")) {
        let splittedName = name.split("_");
        let status = splittedName[2];

        switch (status) {
          case "Товара нет":
            status = "No such item";
            break;

          case "QR код не соответствует":
            status = "QR code does not match";
            break;

          case "Количество не соответствует":
            status = "Quantity does not match";
            break;

          case "Соответствует":
            status = "Matches";
            break;

          default:
            break;
        }

        return `Stock count by ${splittedName[1]} with status "${status}", date: ${splittedName[3]}`;
      }

      // Transfer
      if (name?.startsWith("От")) {
        let splittedName = name.split("_");

        return `From ${splittedName[1]} to "${splittedName[3]}", date: ${splittedName[5]}`;
      }

      // Write-off
      if (name?.startsWith("Списание")) {
        let splittedName = name.split("_");
        splittedName.shift();

        return `Write-off, date: ${splittedName.join("_")}`;
      }

      return name;

    default:
      return name;
  }
};
