export const inventory = {
  header: "Stock count",
  basket_header: "Basket",

  // type choice
  choose_type_title: "Select stock count method",
  table_to_item: "Book to floor",
  item_to_table: "Floor to book",
  journal: "Goods ledger",
  next: "Next",

  // list
  title: "Add items to stock count basket",
  add_to_basket: "Add to basket",
  go_to_basket: "Go to basket",

  // basket
  choose_item: "Choose an item to start stock count",
  delete_from_basket: "Delete from basket",
  print_1c: "Print QR code for 1C",
  start_inventory: "Start stock count",
  total_1: "Total:",
  total_2: "pcs., sum:",
  alert: "Only one item can be counted at a time",
  basket_empty: "Basket is empty",
  back_to_list: "Back to list",

  // process
  check_found_title: "Find the product listed in the table below, then click 'Next'",
  check_found_button: "Next",
  check_not_found_title: "Product was not found, save your details to continue",
  check_not_found_button: "Save details",
  check_scan_title: "Scan QR code of the item below in the table",
  check_next_button: "Next",
  check_scan_again: "Re-scan QR code",
  check_scan_button: "Scan QR code",
  check_photo: "Take a photo of the product from the table and indicate its quantity",
  check_save_button: "Save stock count",
  modal_found_title: "Was the item found?",
  modal_not_found: "No",
  modal_found: "Yes",
  modal_header: "Item was not found",
  modal_content: "The stock count will be completed with the status 'item was not found'. Continue?",
  cancel: "Cancel",
  confirm: "Confirm",
  checked_title: "Checked",
  end_inventory: "Complete stock count?",
  end_cancel: "Cancel",
  end_confirm: "Complete",
  qr_match: "The QR code matches the product data",
  qr_not_match: "The QR code doesn't matches the product data",
  quantity: "Specify the actual product quantity:",
  qr_mismatch_title: "QR code mismatch",
  qr_mismatch_content: "The stock count will be completed with the status 'QR code does not match'. Continue?",
  save: "Save",
  inventory_in_progress: "Saving items data...",

  // item to table
  is_match: "Does the product on which the QR code was scanned match the product found?",
  is_match_button: "Matches",
  scan_qr_title: "Scan the QR code to display product information",
  alert_not_found: "Item not found",
  item_qr_mismatch: "Item doesn't match",

  // journal choice
  table_to_item_journal: "Book to floor goods ledger",
  item_to_table_journal: "Floor to book goods ledger",
  choose_journal_type: "Choose goods ledger type",

  // journal table
  unique_items: "Checked units",
  non_matching_items: "Incorrect quantity",
  method: "Stock count method",
  details_button: "Details",
  details_title: "Click the 'Details' button to view stock count information.",

  // journal details
  journal_details_title: "Stock count details",
  checked_items_title: "Checked items:",
};
