export const locations = {
  header: "Локации",
  name_column: "Название локации",
  filial_column: "Филиал",
  status_column: "Статус",
  active: "Активный",
  inactive: "Неактивный",
  deactivate: "Деактивировать",
  activate: "Активировать",
  title: "Список локаций",
  add_location: "Добавить локацию",
  add_filial: "Добавить филиал",
  // new location
  new_location_title: "Новая локация",
  cancel: "Отмена",
  save: "Сохранить",
  // new filial
  new_filial_title: "Новый филиал",
};
