import { Api } from "api";

export const getFilterCache = async (params?: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  const query = params?.size ? `&${params}` : "";
  const res = await Api.get(`/filter_cache/?hash_pw=${hash}${query}`);
  return res.data;
};

export const updateFilterCache = async () => {
  const res = await Api.post(`/update_filter_cache/`);
  return res.data;
};
