export const dashboard = {
  header: "Dashboard",

  // layout
  last_7_days: "Last 7 days",
  last_30_days: "Last 30 days",
  year: "Year",
  month: "Month",
  category: "Category",
  batch: "Batch",
  source: "Source",
  location: "Location",
  employee: "Employee",
  filial: "Filial",
  method: "Method",
  status: "Status",

  // tabs
  main: "Main",
  write_off: "Write-offs",
  inventory: "Stock counts",
  items: "Items",
  actions: "Activity",

  // units
  pieces: "pcs",
  amount: "Amount, KZT",
  quantity: "Quantity, pcs",

  // main
  initial_value: "Book value",
  quantity_of_goods: "Quantity of goods",
  quantity_of_items: "Quantity of items",
  residual_value: "Residual value",
  initial_value_of_goods: "Book value of goods for write-off",
  goods_for_writeoff: "Quantity of goods for write-off",
  items_for_writeoff: "Quantity of items for write-off",
  invoices: "Quantity of invoices",
  // 1
  invoice_by_date: "Number of invoices by date",
  // 2
  writeoff_pie_title: "Number of regular items and items to write-off",
  writeoff_pie_1: "Items to write-off",
  writeoff_pie_2: "Regular items",
  // 3
  quantity_by_location: "Quantity by location",
  // 4
  top_10: "Top 10 items for write-off by amount",

  // write-off
  initial_value_written_off: "Book value written-off goods",
  written_off_goods: "Quantity of written-off goods",
  written_off_items: "Quantity of written-off items",
  // 1
  writeoff_by_days: "Number of write-offs by days",
  // 2
  writeoff_by_users: "Number of write-offs by users",
  // 3
  writeoff_by_reasons: "Number of write-offs by reason",
  // 4
  writeoff_by_mrp: "Number of write-offs by MRP",

  // inventory
  inventory_count: "Number of inventory counts",
  inventory_items_count: "Number of inventoried items",
  // 1
  inventory_by_inventarizator: "Number of inventory counts by users",
  // 2
  inventory_by_status: "Number of inventory counts by status",
  // 3
  inventory_by_days: "Number of inventory counts by date",
  // 4
  inventory_by_method: "Number of inventory counts by method",
};
