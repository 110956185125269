import { useEffect, useState } from "react";
import { Button, Form, FormProps, Input, Modal } from "antd";
import { LocationType } from "types/location-types";
import { useTranslation } from "react-i18next";

export default function NewFilial({
  isModalOpen,
  closeModal,
  createFilial,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  createFilial: (data: LocationType) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [closeModal]);

  const onFinish: FormProps<LocationType>["onFinish"] = async (payloadData) => {
    setLoading(true);

    createFilial(payloadData).finally(() => {
      setLoading(false);
      form.resetFields();
    });
  };

  return (
    <Modal
      title={t("locations.new_filial_title")}
      open={isModalOpen}
      footer={false}
      closable={false}
      width={400}
      centered
    >
      <Form form={form} onFinish={onFinish} disabled={isLoading} clearOnDestroy autoComplete="off">
        <Form.Item<LocationType> name="id_location" required hidden>
          <Input />
        </Form.Item>
        <Form.Item<LocationType> name="location_name" label={t("locations.name_column")} required hidden>
          <Input />
        </Form.Item>
        <Form.Item<LocationType> name="filial" label={t("locations.filial_column")} required>
          <Input />
        </Form.Item>

        <div className="flex items-center justify-end gap-3">
          <Button type="primary" onClick={closeModal} loading={isLoading}>
            {t("locations.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("locations.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
