import { useEffect, useState } from "react";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Spin, TableProps } from "antd";
import { useMainContext } from "state/main-context";
import { checkItemPrintQr } from "api/inventory";
import { emptyRegistryItemResponse, RegistryItem, RegistryItemResponse } from "types/registry/item-types";
import { getItems } from "api/registry";
import { useNotification } from "state/notification-context";
import { getQrCode } from "api/registration";
import { ActiveFilters } from "types/ui/table-filter-types";
import TableLayout from "components/table/table-layout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function PrintAgain() {
  const { OsType, user } = useMainContext();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const activeFilters: ActiveFilters = {
    date_filter: false,
    id_filter: true,
    name_filter: true,
    sender_filter: false,
    iin_filter: false,
    qr_filter: false,
    category_filter: true,
    batch_filter: true,
    source_filter: true,
    filial_filter: true,
    location_filter: true,
    reason_filter: false,
    user_writeoff_filter: false,
    responsible_filter: true,
  };

  const [isTableCollapsed, setTableCollapsed] = useState<boolean>(true);

  const [registryItems, setRegistryItems] = useState<RegistryItemResponse>(emptyRegistryItemResponse);

  const [isQrCodePrinting, setQrCodePrinting] = useState<boolean>(false);

  useEffect(() => {
    if (OsType !== "Desktop") {
      navigate(-1);
    }
  }, []);

  const getItemsData = async (params: URLSearchParams) => {
    try {
      const res: RegistryItemResponse = await getItems(params);
      setRegistryItems(res);
      return res.items;
    } catch (error: any) {
      let err = error?.response?.data?.detail;
      showNotification("error", t("notifications.data_error"), err);
      return [];
    }
  };

  const printQrCode = async (item: RegistryItem) => {
    setQrCodePrinting(true);

    let payload = [
      {
        id_user: user.user_id,
        "E-SEP ID": item.id_item,
        Наименование_характеристика: item.item_name,
        Источник: item.source,
        "Статус инвентаризации": item.status,
      },
    ];

    await checkItemPrintQr(payload)
      .then(async (res: any) => {
        await getQrCode({ qr_images: res });
        showNotification("success", t("notifications.qr_print_success"));
      })
      .catch((error: any) => {
        if (error.code === "ERR_NETWORK") {
          showNotification("error", t("notifications.qr_error"), t("notifications.qr_error_printer"));
        } else {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.qr_error"), err);
        }
      })
      .finally(() => setQrCodePrinting(false));
  };

  const toggleTableCollapsing = () => {
    setTableCollapsed(!isTableCollapsed);
  };

  const itemColumns: TableProps<RegistryItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      width: 155,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      width: 185,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity_released",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity_checked"),
      dataIndex: "quantity_checked",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },

    {
      title: t("table.stock_date"),
      dataIndex: "stock_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.amortisation_value"),
      dataIndex: "amortisation_value",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },

    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.stock_count_auditor"),
      dataIndex: "inventory_responsible",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_number"),
      dataIndex: "invoice_number",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_date"),
      dataIndex: "invoice_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },

    {
      title: t("table.iin_bin"),
      dataIndex: "iin_bin",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.sender"),
      dataIndex: "sender",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.recipient"),
      dataIndex: "recipient",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
  ];

  const PrintAgainNode = (
    <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <TableLayout
        title={<h2 className="md:text-lg lg:text-xl font-semibold">{t("print_again.title")}</h2>}
        dataSource={registryItems.items}
        dataLength={registryItems.total_items}
        columns={itemColumns}
        selection={true}
        selectionType="radio"
        rowKey="id_stock"
        scroll={{ x: isTableCollapsed ? 2500 : 4500, y: 500 }}
        activeFilters={activeFilters}
        showInvoiceBtn
        showItemBtn
        showActionBtn
        showCollapseBtn
        isTableCollapsed={isTableCollapsed}
        updateData={getItemsData}
        toggleTableCollapsing={toggleTableCollapsing}
        action={printQrCode}
        actionText={t("print_again.print_button")}
      />
    </div>
  );

  const prinItProgressNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">{t("print_again.qr_printing")}</h2>
      <Spin size="large"></Spin>
    </div>
  );

  const PrintAgainData: StageType = {
    id: 1,
    title: t("main.print"),
    node: isQrCodePrinting ? prinItProgressNode : PrintAgainNode,
  };

  return <StageLayout stage={PrintAgainData} />;
}
