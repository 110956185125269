export interface RegistryWriteOff {
  id_item: number;
  item_name: string;
  category: string;
  batch: string;
  source: string;

  quantity_released: number;
  quantity: number;
  stock_date: string;
  reason: string;
  user_name_surname: string;
  name_surname: string;
  user_writeoff: string;

  sum_with_vat: number;
  invoice_number: string;
  invoice_date: string;
  iin_bin: string;

  sender: string;
  recipient: string;
  filial: string;

  // unused
  id_invoice: number;
  delivery_responsible: string;
  transport_organization: string;
}

export interface RegistryWriteOffResponse {
  items: RegistryWriteOff[];
  total_items: number;
}

export const emptyRegistryWriteOffResponse: RegistryWriteOffResponse = {
  items: [],
  total_items: 0,
};
