import { ReactNode, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Modal } from "antd";
import { IconCategory, IconChevronLeft } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default function Header({
  pageTitle,
  backNavigatePageCount,
}: {
  pageTitle: ReactNode;
  backNavigatePageCount: number;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isInMoving = useMemo(() => {
    return location.pathname.includes("moving/confirm-page") ? true : false;
  }, [location]);

  const isInWriteOff = useMemo(() => {
    return location.pathname.includes("write-off/confirm-page") ? true : false;
  }, [location]);

  const isInChecking = useMemo(() => {
    return location.pathname.includes("/check") ? true : false;
  }, [location]);

  const isInItemChecking = useMemo(() => {
    return location.search.includes("?found=true") ? true : false;
  }, [location]);

  const modalTitle = t("header.modal_title");

  const movingText = t("header.modal_moving_text");
  const writeOffText = t("header.modal_writeoff_text");
  const checkingText = t("header.modal_checking_text");

  const modalContent = isInMoving ? movingText : isInWriteOff ? writeOffText : checkingText;

  const confirmRouting = (to: "one_page" | "main_page", event?: BeforeUnloadEvent) => {
    event?.preventDefault();
    Modal.confirm({
      width: 500,
      centered: true,
      title: modalTitle,
      content: modalContent,
      cancelText: t("header.modal_cancel"),
      okText: t("header.modal_confirm"),
      onCancel: () => navigate(to === "one_page" ? -1 : -backNavigatePageCount),
    });
  };

  const navigateBackwards = (to: "one_page" | "main_page") => {
    let isDocReady = JSON.parse(localStorage.getItem("document_is_ready") || "{}");

    if (isInMoving || isInWriteOff || isInChecking || isInItemChecking) {
      confirmRouting(to);
    } else {
      switch (to) {
        case "one_page":
          if (!location.pathname.includes("/registration") || isDocReady !== true) navigate(-1);
          break;
        case "main_page":
          if (location.key === "default") {
            navigate("/", { replace: true });
          } else {
            navigate(-backNavigatePageCount);
          }
          break;
      }
    }
  };

  return (
    <header>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 md:gap-4">
          <Button
            className="h-8 lg:h-10 shadow"
            icon={<IconChevronLeft />}
            onClick={() => navigateBackwards("one_page")}
          >
            <span className="!hidden md:!block">{t("header.back_button_text")}</span>
          </Button>
          <Divider className="m-0 h-8 lg:h-10 hidden md:block" type="vertical" />
        </div>
        <div className="w-full flex items-center justify-center md:justify-between mx-2 md:mx-4">
          <span className="text-lg md:text-xl font-bold md:font-semibold hidden md:block">{pageTitle}</span>
          <div className="text-lg md:text-xl font-bold text-dark-grey whitespace-nowrap">E-SEP</div>
        </div>
        <div className="flex items-center gap-2 md:gap-4">
          <Divider className="m-0 h-8 lg:h-10 hidden md:block" type="vertical" />
          <Button className="h-8 lg:h-10 shadow" icon={<IconCategory />} onClick={() => navigateBackwards("main_page")}>
            <span className="!hidden md:!block">{t("header.main_button_text")}</span>
          </Button>
        </div>
      </div>
      <span className="text-lg md:text-xl font-bold md:font-semibold text-center block md:hidden mt-2 md:mt-0">
        {pageTitle}
      </span>
      <Divider className="my-2 md:my-4 lg:my-6" />
    </header>
  );
}
