export const employees = {
  header: "Employees",
  title: "Employees list",
  add_button: "Add employee",
  edit_button: "Edit employee",
  // table
  name: "Name",
  action: "Action",
  position: "Position",
  gender: "Gender",
  phone: "Phone number",
  location: "Location",
  filial: "Filial",
  email: "E-mail",
  department: "Department",
  employee_status: "Employee status",
  role: "Role",
  username: "Username",
  password: "Password",
  user_status: "User status",
  active: "Active",
  inactive: "Inactive",
  // form
  edit_employee: "Edit employee",
  new_employee: "New employee",
  male: "Male",
  female: "Female",
  // actions
  edit_user: "Edit ESEP user details",
  add_user: "Add ESEP user details",
  add_as_user: "Add as an ESEP user",
  cancel: "Cancel",
  save: "Save",
};
