import { useEffect, useMemo, useState } from "react";
import DashboardTabs from "components/dashboard/dashboard-tabs";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, DatePicker, Layout, Modal, Select, Spin, TimeRangePickerProps } from "antd";
import Sider from "antd/es/layout/Sider";
import { getInventoryDashboard, getItemActionCounts, getMainDashboard, getWriteOffDashboard } from "api/dashboard";
import { MainDashboardType } from "types/dashboard/main-dashboard-types";
import { WriteOffDashboardType } from "types/dashboard/write-off-dashboard-types";
import { InventoryDashboardType } from "types/dashboard/inventory-dashboard-types";
import { useNotification } from "state/notification-context";
import { getOptions, NumberOption, StringOption } from "helpers/options-list";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";
import { LocationType } from "types/location-types";
import { RegistryItemResponse } from "types/registry/item-types";
import { getItems } from "api/registry";
import { useMainContext } from "state/main-context";
import { ActionsDashboardType } from "types/dashboard/actions-dashboard-types";
import dayjs from "dayjs";
import ExportTable from "components/registry/export-to-xlsx";
import { formatExcel } from "helpers/format-excel";

interface OptionType {
  label: string;
  value: number | string | null;
}

interface FilterType {
  options: OptionType[];
  key: string;
  title: string;
}

function DashboardLayout() {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>("main");

  const [mainDashboardData, setMainDashboardData] = useState<MainDashboardType | null>(null);
  const [writeOffDashboardData, setWriteOffDashboardData] = useState<WriteOffDashboardType | null>(null);
  const [inventoryDashboardData, setInventoryDashboardData] = useState<InventoryDashboardType | null>(null);
  const [itemsDashboardData, setItemsDashboardData] = useState<RegistryItemResponse | null>(null);
  const [actionsDashboardData, setActionsDashboardData] = useState<ActionsDashboardType | null>(null);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());
  const [isFiltered, setFiltered] = useState<boolean>(false);
  const [isFiltersModalOpen, setFiltersModalOpen] = useState<boolean>(false);

  const [years, setYears] = useState<NumberOption[]>([]);
  const [categories, setCategories] = useState<StringOption[]>([]);
  const [batches, setBatches] = useState<StringOption[]>([]);
  const [filials, setFilials] = useState<StringOption[]>([]);
  const [locationsList, setLocationsList] = useState<LocationType[]>([]);
  const [locations, setLocations] = useState<StringOption[]>([]);
  const [employees, setEmployees] = useState<StringOption[]>([]);

  const [monthList, setMonthList] = useState<NumberOption[]>([]);
  const [sourceList, setSourceList] = useState<StringOption[]>([]);
  const [methodList, setMethodList] = useState<StringOption[]>([]);
  const [statusList, setStatusList] = useState<StringOption[]>([]);
  const [actionList, setActionList] = useState<StringOption[]>([]);

  const [selectedYear, setSelectedYear] = useState<NumberOption | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<NumberOption | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<StringOption | null>(null);
  const [selectedBatch, setSelectedBatch] = useState<StringOption | null>(null);
  const [selectedSource, setSelectedSource] = useState<StringOption | null>(null);
  const [selectedFilial, setSelectedFilial] = useState<StringOption | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<StringOption | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<StringOption | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<StringOption | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<StringOption | null>(null);
  const [selectedAction, setSelectedAction] = useState<StringOption | null>(null);

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    getData();
  }, [activeTab]);

  const getDataForExport = async () => {
    return new Promise<any[]>(async (resolve) => {
      // eslint-disable-next-line
      formatExcel(actionsDashboardData?.detailed_logs || [], location.pathname).then((formattedData) => {
        resolve(formattedData);
      });
    });
  };

  const formatLocations = (locations: LocationType[]) => {
    const locationOptions: StringOption[] = locations
      .map((item) => ({
        label: item.location_name,
        value: item.location_name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    return locationOptions;
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: t("dashboard.last_7_days"), value: [dayjs().add(-7, "d"), dayjs()] },
    { label: t("dashboard.last_30_days"), value: [dayjs().add(-30, "d"), dayjs()] },
  ];

  const filtersArray: FilterType[] = useMemo(() => {
    let filters: FilterType[] = [
      { options: years, key: "year", title: t("dashboard.year") },
      {
        options: monthList,
        key: "month",
        title: t("dashboard.month"),
      },
      {
        options: categories,
        key: "category",
        title: t("dashboard.category"),
      },
      {
        options: batches,
        key: "batch",
        title: t("dashboard.batch"),
      },
      {
        options: sourceList,
        key: "source",
        title: t("dashboard.source"),
      },
      {
        options: filials,
        key: "filial",
        title: t("dashboard.filial"),
      },
      {
        options: locations,
        key: "location_name",
        title: t("dashboard.location"),
      },
      {
        options: employees,
        key: "name_surname",
        title: t("dashboard.employee"),
      },
      {
        options: methodList,
        key: "method",
        title: t("dashboard.method"),
      },
      {
        options: statusList,
        key: "status",
        title: t("dashboard.status"),
      },
      {
        options: actionList,
        key: "action_type_filter",
        title: t("dashboard.actions"),
      },
    ];

    if (activeTab === "actions") {
      return filters.filter((item) => item.key === "name_surname" || item.key === "action_type_filter");
    } else if (activeTab !== "inventory") {
      return filters.filter(
        (item) => item.key !== "method" && item.key !== "status" && item.key !== "action_type_filter"
      );
    } else {
      return filters.filter((item) => item.key !== "action_type_filter");
    }
  }, [
    activeTab,
    years,
    monthList,
    categories,
    filials,
    locations,
    employees,
    sourceList,
    methodList,
    statusList,
    actionList,
  ]);

  const getData = async () => {
    setFiltered(true);

    try {
      switch (activeTab) {
        case "main":
          searchParams.delete("method");
          searchParams.delete("status");
          searchParams.delete("action_type_filter");

          setSelectedMethod(null);
          setSelectedStatus(null);
          setSelectedAction(null);

          let main_res: MainDashboardType = await getMainDashboard(searchParams);
          setMainDashboardData(main_res);
          break;

        case "write-off":
          searchParams.delete("method");
          searchParams.delete("status");
          searchParams.delete("action_type_filter");

          setSelectedMethod(null);
          setSelectedStatus(null);
          setSelectedAction(null);

          let write_off_res: WriteOffDashboardType = await getWriteOffDashboard(searchParams);
          setWriteOffDashboardData(write_off_res);
          break;

        case "inventory":
          searchParams.delete("action_type_filter");

          setSelectedAction(null);

          let inventory_res: InventoryDashboardType = await getInventoryDashboard(searchParams);
          setInventoryDashboardData(inventory_res);
          break;

        case "items":
          searchParams.delete("method");
          searchParams.delete("status");
          searchParams.delete("action_type_filter");

          setSelectedMethod(null);
          setSelectedStatus(null);
          setSelectedAction(null);

          let items_res: RegistryItemResponse = await getItems(searchParams);
          let itemsParams = new URLSearchParams(searchParams);
          if (itemsParams.has("limit")) {
            itemsParams.set("limit", items_res.total_items.toString());
          } else {
            itemsParams.append("limit", items_res.total_items.toString());
          }

          let all_items_res: RegistryItemResponse = await getItems(itemsParams);
          setItemsDashboardData(all_items_res);
          break;

        case "actions":
          searchParams.delete("year");
          searchParams.delete("month");
          searchParams.delete("category");
          searchParams.delete("batch");
          searchParams.delete("source");
          searchParams.delete("filial");
          searchParams.delete("location_name");
          searchParams.delete("method");
          searchParams.delete("status");

          setSelectedYear(null);
          setSelectedMonth(null);
          setSelectedCategory(null);
          setSelectedBatch(null);
          setSelectedSource(null);
          setSelectedFilial(null);
          setSelectedLocation(null);
          setSelectedMethod(null);
          setSelectedStatus(null);

          let actions_res: ActionsDashboardType = await getItemActionCounts(searchParams);
          setActionsDashboardData(actions_res);
          break;

        default:
          break;
      }
    } catch (error: any) {
      let err = error?.response?.data?.detail;
      showNotification("error", t("notifications.data_error"), err);
    } finally {
      setFiltered(false);
    }
  };

  const getFilterOptions = async () => {
    await getOptions()
      .then((res) => {
        setYears(res.years);
        setCategories(res.categories);
        setBatches(res.batches);
        setFilials(
          user.role_permissions.access_filials
            ? res.filials
            : res.filials.filter((filial) => filial.label === user.filial)
        );
        setLocationsList(res.locationsList);
        setLocations(res.locations);
        setEmployees(res.all_employees);
        setMonthList(res.months);
        setSourceList(res.sources);
        setMethodList(res.methods);
        setStatusList(res.statuses);
        setActionList(res.actions);
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      });
  };

  const getValue = (key: string) => {
    switch (key) {
      case "year":
        return selectedYear;

      case "month":
        return selectedMonth;

      case "category":
        return selectedCategory;

      case "batch":
        return selectedBatch;

      case "source":
        return selectedSource;

      case "filial":
        return selectedFilial;

      case "location_name":
        return selectedLocation;

      case "name_surname":
        return selectedEmployee;

      case "method":
        return selectedMethod;

      case "status":
        return selectedStatus;

      case "action_type_filter":
        return selectedAction;

      default:
        break;
    }
  };

  const appendFilter = (key: string, value: string) => {
    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const handleFilters = (key: string | string[], value?: string | string[]) => {
    setFiltered(true);

    switch (key) {
      case "year":
        setSelectedYear(!Array.isArray(value) ? value : ("" as any));
        break;
      case "month":
        setSelectedMonth(!Array.isArray(value) ? value : ("" as any));
        break;
      case "category":
        setSelectedCategory(!Array.isArray(value) ? value : ("" as any));
        break;
      case "batch":
        setSelectedBatch(!Array.isArray(value) ? value : ("" as any));
        break;
      case "source":
        setSelectedSource(!Array.isArray(value) ? value : ("" as any));
        break;
      case "filial":
        setSelectedFilial(!Array.isArray(value) ? value : ("" as any));
        break;
      case "location_name":
        setSelectedLocation(!Array.isArray(value) ? value : ("" as any));
        break;
      case "name_surname":
        setSelectedEmployee(!Array.isArray(value) ? value : ("" as any));
        break;
      case "method":
        setSelectedMethod(!Array.isArray(value) ? value : ("" as any));
        break;
      case "status":
        setSelectedStatus(!Array.isArray(value) ? value : ("" as any));
        break;
      case "action_type_filter":
        setSelectedAction(!Array.isArray(value) ? value : ("" as any));
        break;

      default:
        break;
    }

    if (!value && value !== null) {
      if (Array.isArray(key)) {
        key.forEach((name) => searchParams.delete(name));
      } else {
        searchParams.delete(key);
      }
    } else {
      if (Array.isArray(key)) {
        key.forEach((name, index) => {
          value[index] !== undefined && appendFilter(name, value[index]);
        });
      } else {
        value !== undefined && !Array.isArray(value) && appendFilter(key, value);
      }
    }

    getData();
  };

  const clearFilters = () => {
    setFiltered(true);

    searchParams.delete("from_date");
    searchParams.delete("to_date");
    searchParams.delete("year");
    searchParams.delete("month");
    searchParams.delete("category");
    searchParams.delete("batch");
    searchParams.delete("source");
    searchParams.delete("filial");
    searchParams.delete("location_name");
    searchParams.delete("name_surname");
    searchParams.delete("method");
    searchParams.delete("status");
    searchParams.delete("action_type_filter");

    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedCategory(null);
    setSelectedBatch(null);
    setSelectedSource(null);
    setSelectedFilial(null);
    setSelectedLocation(null);
    setSelectedEmployee(null);
    setSelectedMethod(null);
    setSelectedStatus(null);
    setSelectedAction(null);

    getData();
  };

  const openFilters = () => {
    setFiltersModalOpen(true);
  };

  const dashboardNode = (
    <Layout className="bg-transparent">
      <Sider className="hidden md:block h-fit p-0" theme="light">
        <div className="flex flex-col gap-2 lg:gap-3 p-2 lg:p-3">
          <DatePicker.RangePicker
            value={[
              searchParams.get("from_date") ? dayjs(searchParams.get("from_date")) : null,
              searchParams.get("to_date") ? dayjs(searchParams.get("to_date")) : null,
            ]}
            format={{
              format: "YYYY-MM-DD",
              type: "mask",
            }}
            presets={rangePresets}
            maxDate={dayjs()}
            allowEmpty={[false, true]}
            onChange={(dates, dateStrings) => {
              if (dates && dateStrings[1] === "") {
                handleFilters(["from_date", "to_date"], [dateStrings[0], new Date().toISOString().split("T")[0]]);
              } else if (dates && dateStrings[1] !== "") {
                handleFilters(["from_date", "to_date"], dateStrings);
              } else {
                handleFilters(["from_date", "to_date"]);
              }
            }}
          />
          {filtersArray.map((filter) => (
            <Select
              placeholder={<span>{filter.title}</span>}
              value={getValue(filter.key)}
              options={filter.options}
              onChange={(value) => {
                if (value) {
                  if (filter.key === "filial") {
                    setSelectedFilial(!Array.isArray(value) ? value : ("" as any));
                    appendFilter("filial", value.value as any);
                    handleFilters("location_name");
                    setLocations(formatLocations(locationsList.filter((location) => location.filial === value.value)));
                  } else {
                    handleFilters(filter.key, value.value as any);
                  }
                }
              }}
              onClear={() => handleFilters(filter.key)}
              allowClear
              labelInValue
              key={filter.key}
            />
          ))}
          {activeTab === "actions" && <ExportTable getAllData={getDataForExport} buttonWithText />}
          <Button color="primary" variant="text" onClick={clearFilters} disabled={!!!searchParams}>
            {t("inputs.search_reset")}
          </Button>
        </div>
      </Sider>
      <Layout className="bg-transparent">
        <DashboardTabs
          mainDashboardData={mainDashboardData}
          writeOffDashboardData={writeOffDashboardData}
          inventoryDashboardData={inventoryDashboardData}
          itemsDashboardData={itemsDashboardData}
          actionsDashboardData={actionsDashboardData}
          switchTab={setActiveTab}
          openFilters={openFilters}
        />
      </Layout>
      <Spin spinning={isFiltered} fullscreen />
      <Modal
        width={400}
        title={t("inputs.search_title")}
        open={isFiltersModalOpen}
        onCancel={() => setFiltersModalOpen(false)}
        onOk={() => setFiltersModalOpen(false)}
        footer={(_, { OkBtn }) => (
          <>
            <Button onClick={clearFilters}>{t("inputs.search_reset")}</Button>
            <OkBtn />
          </>
        )}
        keyboard={false}
        centered
      >
        <div className="flex flex-col gap-3">
          <DatePicker.RangePicker
            value={[
              searchParams.get("from_date") ? dayjs(searchParams.get("from_date")) : null,
              searchParams.get("to_date") ? dayjs(searchParams.get("to_date")) : null,
            ]}
            format={{
              format: "YYYY-MM-DD",
              type: "mask",
            }}
            presets={rangePresets}
            maxDate={dayjs()}
            allowEmpty={[false, true]}
            onChange={(dates, dateStrings) => {
              if (dates && dateStrings[1] === "") {
                handleFilters(["from_date", "to_date"], [dateStrings[0], new Date().toISOString().split("T")[0]]);
              } else if (dates && dateStrings[1] !== "") {
                handleFilters(["from_date", "to_date"], dateStrings);
              } else {
                handleFilters(["from_date", "to_date"]);
              }
            }}
          />
          {filtersArray.map((filter) => (
            <Select
              placeholder={<span>{filter.title}</span>}
              value={getValue(filter.key)}
              options={filter.options}
              onChange={(value) => {
                if (value) {
                  if (filter.key === "filial") {
                    appendFilter("filial", value.value as any);
                    handleFilters("location_name");
                    setLocations(formatLocations(locationsList.filter((location) => location.filial === value.value)));
                  } else {
                    handleFilters(filter.key, value.value as any);
                  }
                }
              }}
              onClear={() => handleFilters(filter.key)}
              allowClear
              labelInValue
              key={filter.key}
            />
          ))}
        </div>
      </Modal>
    </Layout>
  );

  const DashboardData: StageType = {
    id: 1,
    title: t("dashboard.header"),
    node: dashboardNode,
  };

  return <StageLayout stage={DashboardData} />;
}

export default DashboardLayout;
