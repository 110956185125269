import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { IconArrowNarrowRight, IconBuildingWarehouse, IconPackage, IconVocabulary } from "@tabler/icons-react";
import { CategoryType } from "types/ui/category-types";
import { useTranslation } from "react-i18next";

export default function Inventory() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const table_to_item_icon = (
    <div className="flex items-center gap-0 md:gap-3">
      <IconBuildingWarehouse className="stroke-primary" size={60} />
      <IconArrowNarrowRight className="stroke-primary" size={40} />
      <IconPackage size={60} />
    </div>
  );

  const item_to_table_icon = (
    <div className="flex items-center gap-0 md:gap-3">
      <IconPackage className="stroke-primary" size={60} />
      <IconArrowNarrowRight className="stroke-primary" size={40} />
      <IconBuildingWarehouse size={60} />
    </div>
  );

  const inventoryTypes: CategoryType[] = [
    {
      id: "table_to_item",
      title: t("inventory.table_to_item"),
      icon: table_to_item_icon,
      url: "/inventory/table-to-item",
    },
    {
      id: "item_to_table",
      title: t("inventory.item_to_table"),
      icon: item_to_table_icon,
      url: "/inventory/item-to-table",
    },
    {
      id: "journal",
      title: t("inventory.journal"),
      icon: <IconVocabulary size={60} />,
      url: "/inventory/journal-type",
    },
  ];

  const firstStageNode = (
    <div className="flex flex-col justify-center grow gap-4 md:gap-8 lg:gap-12 md:mx-12 lg:mx-16">
      <h2 className="text-lg md:text-xl font-bold text-center">{t("inventory.choose_type_title")}</h2>
      <div className="grow sm:grow-0 grid grid-cols-1 sm:grid-cols-3 justify-items-center gap-4 md:gap-8 lg:gap-12 h-full sm:h-fit">
        {inventoryTypes.map((inventory) => (
          <div
            className="w-2/3 sm:w-full p-4 shadow rounded-xl md:rounded-2xl lg:rounded-3xl bg-white cursor-pointer"
            onClick={() => navigate(`${inventory.url}`)}
            key={inventory.id}
          >
            <div className="flex flex-col items-center justify-center gap-2 h-full md:h-48 lg:h-60">
              {inventory.icon}
              <span className="text-lg md:text-xl font-bold text-center">{inventory.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const firstStageData: StageType = {
    id: 1,
    title: t("inventory.header"),
    node: firstStageNode,
  };

  return <StageLayout stage={firstStageData} />;
}
