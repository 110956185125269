export interface User {
  user_id: number;
  role: string;
  name: string;
  location: string;
  location_id: number;
  filial: string;
  position: string;
  department: string;
  hashed_password: string;
  id_5: number;
  id_employee: number;
  role_permissions: {
    register: boolean;
    inventory: boolean;
    locations: boolean;
    reasons: boolean;
    employees: boolean;
    move: boolean;
    roles: boolean;
    report: boolean;
    writeoff: boolean;
    reestr: boolean;
    scan: boolean;
    reprint: boolean;
    batch: boolean;
    access_filials: boolean;
    access_item_logs: boolean;
  };
}

export interface UserResponse {
  result: User;
}

export const emptyUser: User = {
  user_id: 0,
  role: "",
  name: "",
  location: "",
  location_id: 0,
  filial: "",
  position: "",
  department: "",
  hashed_password: "",
  id_5: 0,
  id_employee: 0,
  role_permissions: {
    register: false,
    inventory: false,
    locations: false,
    reasons: false,
    employees: false,
    move: false,
    roles: false,
    report: false,
    writeoff: false,
    reestr: false,
    scan: false,
    reprint: false,
    batch: false,
    access_filials: false,
    access_item_logs: false,
  },
};
