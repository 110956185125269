import Scan_0 from "assets/img/0.png";
import Scan_90 from "assets/img/90.png";
import Scan_180 from "assets/img/180.png";
import Scan_270 from "assets/img/270.png";
import { Badge } from "antd";
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react";

export default function ScanExample() {
  return (
    <div className="flex items-center gap-3">
      <Badge count={<IconCircleCheckFilled style={{ color: "green" }} />}>
        <img src={Scan_0} alt="0" className="h-40 object-contain" />
      </Badge>
      <Badge count={<IconCircleXFilled style={{ color: "red" }} />}>
        <img src={Scan_180} alt="180" className="h-40 object-contain" />
      </Badge>
      <Badge count={<IconCircleXFilled style={{ color: "red" }} />}>
        <img src={Scan_90} alt="90" className="h-40 object-contain" />
      </Badge>
      <Badge count={<IconCircleXFilled style={{ color: "red" }} />}>
        <img src={Scan_270} alt="270" className="h-40 object-contain" />
      </Badge>
    </div>
  );
}
