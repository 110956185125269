import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Table, TableProps } from "antd";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import NewWriteOff from "components/dictionaries/write-offs/new-write-off";
import { addWriteOffReason, getWriteOffReasons } from "api/write-offs";
import { ReasonType } from "types/write-off-types";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";

export default function WriteOffReasons() {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [reasons, setReasons] = useState<ReasonType[]>([]);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 241;
    } else if (width < 768) {
      return height - 257;
    } else if (width < 1024) {
      return height - 213;
    } else return height - 269;
  }, [window.innerWidth]);

  useEffect(() => {
    getReasons();
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getReasons = async () => {
    setTableLoading(true);

    await getWriteOffReasons()
      .then((res: ReasonType[]) => {
        setReasons(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const createReason = async (data: ReasonType) => {
    setTableLoading(true);

    await addWriteOffReason({ reason: data.reason, who_changed: user.user_id })
      .then(() => {
        getReasons();
        showNotification("success", t("notifications.create_writeoff_reason_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.create_writeoff_reason_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
        closeModal();
      });
  };

  const columns: TableProps<ReasonType>["columns"] = [
    {
      title: t("reasons.name_column"),
      dataIndex: "reason",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
  ];

  const WriteOffNode = (
    <>
      <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-3">
            <h2 className="text-lg md:text-xl font-semibold text-center">{t("reasons.title")}</h2>
            <Button type="primary" onClick={openModal}>
              {t("reasons.add_reason")}
            </Button>
          </div>
          <Table<ReasonType>
            size="small"
            columns={columns}
            dataSource={reasons}
            rowKey="id_write_off"
            pagination={false}
            scroll={{ y: table_height }}
            loading={isTableLoading}
            bordered
          />
        </div>
      </div>
      <NewWriteOff isModalOpen={isModalOpen} closeModal={closeModal} createReason={createReason} />
    </>
  );

  const WriteOffTableData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("directories.header")}
        <IconArrowNarrowRight />
        {t("reasons.header")}
      </div>
    ),
    node: WriteOffNode,
  };

  return <StageLayout stage={WriteOffTableData} />;
}
