import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Table, TableProps, Tag } from "antd";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { addBatch, getBatches, updateBatchStatus } from "api/batches";
import { useEffect, useState } from "react";
import { BatchType } from "types/batch-types";
import NewBatch from "components/dictionaries/batches/new-batch";
import BatchTransfer from "./batch-transfer";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";

export default function Batches() {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [batches, setBatches] = useState<BatchType[]>([]);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isBatchClearing, setBatchClearing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getBatchList();
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const clearBatchInTransfer = () => {
    setBatchClearing(true);
    setTimeout(() => {
      setBatchClearing(false);
    }, 100);
  };

  const getBatchList = async () => {
    setTableLoading(true);

    await getBatches()
      .then((res: BatchType[]) => {
        setBatches(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const createBatch = async (data: BatchType) => {
    setTableLoading(true);

    await addBatch({ batch: data.batch, filial: user.filial, who_changed: user.user_id })
      .then(() => {
        showNotification("success", t("notifications.create_batch_success"));
        getBatchList();
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.create_batch_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
        clearBatchInTransfer();
        closeModal();
      });
  };

  const updateBatch = async (data: BatchType) => {
    setTableLoading(true);

    await updateBatchStatus({ id_batch: data.id_batch, status: !data.status, who_changed: user.user_id })
      .then(() => {
        showNotification("success", t("notifications.edit_batch_success"));
        getBatchList();
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.edit_batch_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
        clearBatchInTransfer();
        closeModal();
      });
  };

  const columns: TableProps<BatchType>["columns"] = [
    {
      title: t("batches.name_column"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.batch.localeCompare(b.batch),
    },
    {
      title: t("batches.filial_column"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.filial.localeCompare(b.filial),
    },
    {
      title: t("batches.status_column"),
      dataIndex: "status",
      render: (value) =>
        value ? <Tag color="success">{t("batches.active")}</Tag> : <Tag color="error">{t("batches.inactive")}</Tag>,
      sorter: (a, b) => `${b.status}`.localeCompare(`${a.status}`),
    },
    {
      render: (value, record) => (
        <Button
          className={`${record.status ? "text-red-600" : "text-green-600"}`}
          type="dashed"
          onClick={() => updateBatch(record)}
          disabled={isTableLoading}
        >
          {record.status ? t("batches.deactivate") : t("batches.activate")}
        </Button>
      ),
    },
  ];

  const BatchesNode = (
    <>
      <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-3">
            <h2 className="text-lg md:text-xl font-semibold text-center">{t("batches.title")}</h2>
            <Button className="shadow" type="primary" onClick={openModal}>
              {t("batches.add_batch")}
            </Button>
          </div>
          <Table<BatchType>
            size="small"
            columns={columns}
            dataSource={batches}
            rowKey="id_batch"
            pagination={false}
            loading={isTableLoading}
            bordered
          />
          <BatchTransfer batch_list={batches} isBatchClearing={isBatchClearing} />
        </div>
      </div>
      <NewBatch isModalOpen={isModalOpen} closeModal={closeModal} createBatch={createBatch} />
    </>
  );

  const BatchesTableData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("directories.header")}
        <IconArrowNarrowRight />
        {t("batches.header")}
      </div>
    ),
    node: BatchesNode,
  };

  return <StageLayout stage={BatchesTableData} />;
}
