import { IconArrowNarrowRight, IconFileTypePdf, IconFilter, IconPhoto } from "@tabler/icons-react";
import { Badge, Button, Image, Input, Modal, Select, Table, TableProps } from "antd";
import StageLayout from "components/layout/page-layout";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { emptyMovingItems, MovingData, MovingItems } from "types/moving-types";
import { StageType } from "types/stage-types";
import { getImages } from "api/moving";
import { useMainContext } from "state/main-context";
import { addToBasket, getBasket } from "api/inventory";
import { BasketPayloadItem, BasketItems } from "types/inventory-types";
import { getItems } from "api/registry";
import { useNotification } from "state/notification-context";
import { FilterOptionsTypes, getOptions } from "helpers/options-list";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function InventoryList() {
  const { user, setLoadingScreen } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const [options, setOptions] = useState<FilterOptionsTypes>();

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [itemsList, setItemsList] = useState<MovingItems>(emptyMovingItems);
  const [itemsForBasket, setItemsForBasket] = useState<MovingData[]>([]);
  const [itemsInBasketCount, setItemsInBasketCount] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(10);
  const [itemImages, setItemImages] = useState<any[]>([]);
  const [isFiltersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 360) {
    //   return height - 484;
    // } else if (width < 366) {
    //   return height - 440;
    // } else if (width < 467) {
    //   return height - 404;
    // } else if (width < 581) {
    //   return height - 376;
    } else if (width < 640) {
      return height - 332;
    } else if (width < 768) {
      return height - 348;
    // } else if (width < 819) {
    //   return height - 376;
    } else if (width < 1024) {
      return height - 344;
    } else return height - 359;
  }, [window.innerWidth]);

  useEffect(() => {
    getInventory(currentLimit, 0);
    getItemsInBasket();
    getQueryValues();
  }, []);

  const shouldDisableImageButton: boolean = useMemo(() => {
    if (itemsForBasket.length < 1) {
      return true;
    }

    const firstPropValue = itemsForBasket[0].id_item;
    return !itemsForBasket.every((row) => row.id_item === firstPropValue);
  }, [itemsForBasket]);

  const getInventory = async (limit?: number, offset?: number, params = searchParams) => {
    setTableLoading(true);

    if (limit !== undefined) {
      params.set("limit", limit.toString());
    }
    if (offset !== undefined) {
      params.set("offset", offset.toString());
    }

    setItemsForBasket([]);

    await getItems(new URLSearchParams(params))
      .then((res: MovingItems) => {
        setItemsList(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getItemsInBasket = async () => {
    await getBasket(user.user_id, new URLSearchParams())
      .then((res: BasketItems) => {
        setItemsInBasketCount(res.total_items);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.basket_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const addItemsToBasket = async () => {
    setTableLoading(true);
    let payload: BasketPayloadItem[] = itemsForBasket.map((item) => ({
      id_check_user: user.user_id,
      id_item: item.id_item,
      status: null,
    }));
    await addToBasket(payload)
      .then((res: any) => {
        setItemsForBasket([]);
        getItemsInBasket();
        showNotification("success", t("notifications.add_to_basket_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.add_to_basket_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getQueryValues = async () => {
    setTableLoading(true);

    await getOptions()
      .then((res) => {
        setOptions(res);
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const appendFilter = (key: string, value: string) => {
    if (key !== "limit" && key !== "offset") {
      searchParams.set("limit", "10");
      searchParams.delete("offset");
    }

    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const clearAllFilters = () => {
    searchParams.delete("offset");
    searchParams.delete("id_item");
    searchParams.delete("item_name");
    searchParams.delete("name_surname");
    searchParams.delete("location_name");
    searchParams.delete("batch");
    searchParams.delete("category");
    searchParams.delete("source");
    // searchParams.delete("filial");

    getInventory();
  };

  const handleSearchChange = (filterName: string | string[], value: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name, index) => {
        value[index] !== undefined && appendFilter(name, value[index]);
      });
    } else {
      value !== undefined && !Array.isArray(value) && appendFilter(filterName, value);
    }

    timeoutId && clearTimeout(timeoutId);

    if (
      filterName !== "name_surname" &&
      filterName !== "location_name" &&
      filterName !== "batch" &&
      filterName !== "category" &&
      filterName !== "source"
      // && filterName !== "filial"
    ) {
      const newTimeoutId = window.setTimeout(() => {
        getInventory();
      }, 1000);

      setTimeoutId(newTimeoutId);
    } else {
      getInventory();
    }

    setCurrentPage(1);
  };

  const handleClearSearch = (filterName: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name) => {
        searchParams.delete(name);
      });
    } else {
      searchParams.delete(filterName);
    }

    getInventory();
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);
    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const onTableChange: TableProps<MovingData>["onChange"] = (pagination, filters, sorter, extra) => {
    const updatedParams = new URLSearchParams(searchParams);

    // Handle pagination
    if (pagination) {
      setCurrentPage(pagination.current || 1);
      setCurrentLimit(pagination.pageSize || 10);

      const newOffset = ((pagination.current || 1) - 1) * (pagination.pageSize || 10);
      updatedParams.set("limit", (pagination.pageSize || 10).toString());
      updatedParams.set("offset", newOffset.toString());
    }

    // Handle sorting
    if (!Array.isArray(sorter)) {
      if (sorter.order) {
        updatedParams.set("sort_by", `${sorter.field}:${sorter.order === "ascend" ? "asc" : "desc"}`);
      } else {
        updatedParams.delete("sort_by");
        searchParams.delete("sort_by");
      }
    } else {
      updatedParams.delete("sort_by");
      searchParams.delete("sort_by");

      let reversedSorter = sorter.reverse();
      reversedSorter.forEach((sort) => {
        updatedParams.append("sort_by", `${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`);
      });
    }

    // Update searchParams with the new params using Array.from()
    Array.from(updatedParams.entries()).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    getInventory(undefined, undefined, updatedParams);
  };

  const columns: TableProps<MovingData>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      width: 160,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
  ];

  const rowSelection: TableProps<MovingData>["rowSelection"] = {
    fixed: window.innerWidth > 640 ? "left" : false,
    type: "checkbox",
    selectedRowKeys: itemsForBasket.map((item) => item.id_stock),
    onChange: (selectedRowKeys: React.Key[], selectedRows: MovingData[]) => {
      setItemsForBasket(selectedRows);
    },
    getCheckboxProps: (record: MovingData) => ({
      disabled: false, // Column configuration not to be checked
      name: record.item_name,
    }),
  };

  const InventoryListNode = (
    <>
      <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 grow">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3 border-b border-gray-200 pb-3">
              <h2 className="text-lg md:text-xl font-semibold">{t("inventory.title")}</h2>
              <div className="flex flex-wrap md:flex-nowrap items-center justify-end gap-3">
                <Button className="shadow grow" icon={<IconFilter />} onClick={() => setFiltersModalOpen(true)}>
                  {t("inputs.search_title")}
                </Button>

                <Button
                  className="shadow"
                  icon={<IconFileTypePdf size={20} />}
                  onClick={() => getItemImages("invoices", itemsForBasket[0].id_invoice)}
                  disabled={shouldDisableImageButton}
                ></Button>

                <Button
                  className="shadow"
                  icon={<IconPhoto size={20} />}
                  onClick={() => getItemImages("items", itemsForBasket[0].id_item)}
                  disabled={shouldDisableImageButton}
                ></Button>

                <div className="hidden">
                  <Image.PreviewGroup
                    items={itemImages}
                    preview={{
                      visible: previewVisible,
                      destroyOnClose: true,
                      imageRender: (original, info) => (
                        <div className="relative flex max-h-[70%]">
                          {original}
                          <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                            {translatePhotoName(itemImages[info.current]?.name)}
                          </div>
                        </div>
                      ),
                      onVisibleChange: (value) => {
                        setPreviewVisible(value);
                        !value && setItemImages([]);
                      },
                    }}
                  >
                    <Image />
                  </Image.PreviewGroup>
                </div>

                <Badge className="grow flex" count={itemsForBasket.length}>
                  <Button
                    className="shadow grow text-white bg-green-600"
                    variant="solid"
                    disabled={!itemsForBasket.length}
                    onClick={addItemsToBasket}
                  >
                    {t("inventory.add_to_basket")}
                  </Button>
                </Badge>
                <Badge className="grow flex" count={itemsInBasketCount}>
                  <Button
                    className="shadow grow"
                    variant="solid"
                    color="primary"
                    disabled={!itemsInBasketCount}
                    onClick={() => navigate("/inventory/table-to-item/basket")}
                  >
                    {t("inventory.go_to_basket")}
                  </Button>
                </Badge>
              </div>
            </div>
          </div>
          <Table<MovingData>
            size="small"
            className="grow"
            dataSource={itemsList.items}
            columns={columns}
            rowSelection={rowSelection}
            rowKey={"id_stock"}
            loading={isTableLoading}
            scroll={{ x: 2000, y: table_height }}
            bordered
            pagination={{
              disabled: isTableLoading,
              total: itemsList.total_items,
              current: currentPage,
              onChange: (page, pageSize) => {
                setCurrentPage(page);
                setCurrentLimit(pageSize);
              },
            }}
            onChange={onTableChange}
          />
        </div>
      </div>
      <Modal
        width={400}
        title={t("inputs.search_title")}
        open={isFiltersModalOpen}
        onCancel={() => setFiltersModalOpen(false)}
        onOk={() => setFiltersModalOpen(false)}
        footer={(_, { OkBtn }) => (
          <>
            <Button onClick={clearAllFilters}>{t("inputs.search_reset")}</Button>
            <OkBtn />
          </>
        )}
        keyboard={false}
        centered
      >
        <div className="flex flex-col gap-3">
          <Input
            placeholder={t("inputs.search_esep_id")}
            value={searchParams.get("id_item") || ""}
            onChange={(e) => handleSearchChange("id_item", e.target.value)}
            onClear={() => handleClearSearch("id_item")}
            allowClear
          />

          <Input
            placeholder={t("inputs.search_name")}
            value={searchParams.get("item_name") || ""}
            onChange={(e) => handleSearchChange("item_name", e.target.value)}
            onClear={() => handleClearSearch("item_name")}
            allowClear
          />

          <Select
            placeholder={t("inputs.search_mrp")}
            value={searchParams.get("name_surname")}
            options={options?.all_employees}
            onChange={(value) => value && handleSearchChange("name_surname", value)}
            onClear={() => handleClearSearch("name_surname")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_location")}
            value={searchParams.get("location_name")}
            options={options?.locations}
            onChange={(value) => value && handleSearchChange("location_name", value)}
            onClear={() => handleClearSearch("location_name")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_batch")}
            value={searchParams.get("batch")}
            options={options?.batches}
            onChange={(value) => value && handleSearchChange("batch", value)}
            onClear={() => handleClearSearch("batch")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_category")}
            value={searchParams.get("category")}
            options={options?.categories}
            onChange={(value) => value && handleSearchChange("category", value)}
            onClear={() => handleClearSearch("category")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_source")}
            value={searchParams.get("source")}
            options={options?.sources}
            onChange={(value) => value && handleSearchChange("source", value)}
            onClear={() => handleClearSearch("source")}
            allowClear
            showSearch
          />
        </div>
      </Modal>
    </>
  );

  const InventoryListStageData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("inventory.header")}
        <IconArrowNarrowRight />
        {location.pathname.includes("table-to-item") ? t("inventory.table_to_item") : t("inventory.item_to_table")}
      </div>
    ),
    node: InventoryListNode,
  };

  return <StageLayout stage={InventoryListStageData} />;
}
