export interface MovingData {
  id_stock: number;
  id_item: number;
  item_name: string;
  quantity_released: number;
  quantity: number;
  id_location: number;
  location_name: string;
  unit_measurement: string;
  id_invoice: number;
  status: string;
  last_check_date: string;
  category: string;
  source: string;
  filial: string;
  name_surname: string;
  stock_responsible: string;
  stock_responsible_id: number;
  batch: string;
  lifecycle: number;
  amortisation_value: number;
  id_employee: number;
}

export interface MovingItems {
  items: MovingData[];
  total_items: number;
}

export interface WriteOff {
  reason: string;
  id_write_off: number;
}

export const emptyMovingData: MovingData = {
  id_stock: 0,
  id_item: 0,
  item_name: "",
  quantity_released: 0,
  quantity: 0,
  id_location: 0,
  location_name: "",
  unit_measurement: "",
  id_invoice: 0,
  status: "",
  last_check_date: "",
  category: "",
  source: "",
  filial: "",
  name_surname: "",
  stock_responsible: "",
  stock_responsible_id: 0,
  batch: "",
  lifecycle: 0,
  amortisation_value: 0,
  id_employee: 0,
};

export const emptyMovingItems = {
  items: [],
  total_items: 0,
};
