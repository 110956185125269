export const employees = {
  header: "Сотрудники",
  title: "Список сотрудников",
  add_button: "Добавить сотрудника",
  edit_button: "Редактировать",
  // table
  name: "Имя",
  action: "Действия",
  position: "Должность",
  gender: "Пол",
  phone: "Номер телефона",
  location: "Локация",
  filial: "Филиал",
  email: "Эл. почта",
  department: "Отдел",
  employee_status: "Статус сотрудника",
  role: "Роль",
  username: "Логин",
  password: "Пароль",
  user_status: "Статус пользователя",
  active: "Активный",
  inactive: "Неактивный",
  // form
  edit_employee: "Редактировать",
  new_employee: "Новый сотрудник",
  male: "Мужской",
  female: "Женский",
  // actions
  edit_user: "Редактировать данные пользователя ESEP",
  add_user: "Добавить данные пользователя ESEP",
  add_as_user: "Добавить как пользователя ESEP",
  cancel: "Отмена",
  save: "Сохранить",
};
