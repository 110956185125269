import { Button, Statistic } from "antd";
import { MainDashboardType } from "types/dashboard/main-dashboard-types";
import InvoiceChart from "./invoice-chart";
import WriteOffPieChart from "./write-off-pie";
import LocationBarChart from "./location-bar";
import Top10TreemapChart from "./top-10-treemap";
import { useTranslation } from "react-i18next";
import compactNumber from "helpers/compact-number";
import { IconFilter } from "@tabler/icons-react";

export default function DashboardMain({
  mainDashboardData,
  openFilters,
}: {
  mainDashboardData: MainDashboardType | null;
  openFilters: () => void;
}) {
  const { t } = useTranslation();

  const statsArray = [
    {
      title: t("dashboard.initial_value"),
      value: mainDashboardData?.total_items.items_value || 0,
      suffix: "KZT",
    },
    {
      title: t("dashboard.quantity_of_goods"),
      value: mainDashboardData?.total_items.items_quantity || 0,
      suffix: t("dashboard.pieces"),
    },
    {
      title: t("dashboard.quantity_of_items"),
      value: mainDashboardData?.total_items.items_count || 0,
      suffix: t("dashboard.pieces"),
    },
    {
      title: t("dashboard.residual_value"),
      value: mainDashboardData?.total_items.current_value || 0,
      suffix: "KZT",
    },
    {
      title: t("dashboard.initial_value_of_goods"),
      value: mainDashboardData?.total_items.items_value_amortised || 0,
      suffix: "KZT",
    },
    {
      title: t("dashboard.goods_for_writeoff"),
      value: mainDashboardData?.total_items.items_quantity_amortised || 0,
      suffix: t("dashboard.pieces"),
    },
    {
      title: t("dashboard.items_for_writeoff"),
      value: mainDashboardData?.total_items.items_count_amortised || 0,
      suffix: t("dashboard.pieces"),
    },
    {
      title: t("dashboard.invoices"),
      value: mainDashboardData?.total_invoices.total_invoices || 0,
      suffix: t("dashboard.pieces"),
    },
  ];

  return (
    <div className="flex flex-col gap-3 md:gap-4 lg:gap-6">
      <Button className="shadow md:hidden" icon={<IconFilter />} onClick={openFilters}>
        {t("inputs.search_title")}
      </Button>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 md:gap-4 lg:gap-6 justify-center flex-wrap">
        {statsArray.map((stat, index) => (
          <div className="p-2 sm:p-3 md:p-4 lg:p-6 border rounded-md bg-white" key={index}>
            <Statistic
              className="h-full flex flex-col justify-between font-bold text-center"
              title={<div className="font-semibold text-black">{stat.title}</div>}
              value={compactNumber(stat.value)}
              suffix={stat.suffix}
              key={index}
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-4 lg:gap-6 justify-center flex-wrap">
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <InvoiceChart mainDashboardData={mainDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <WriteOffPieChart mainDashboardData={mainDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <LocationBarChart mainDashboardData={mainDashboardData} />
        </div>
        <div className="px-3 py-6 rounded-lg border border-[#f0f0f0] bg-white">
          <Top10TreemapChart mainDashboardData={mainDashboardData} />
        </div>
      </div>
    </div>
  );
}
