import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Image, Input, Modal, Select, Table, TableProps } from "antd";
import { IconFileTypePdf, IconFilter, IconPhoto, IconQrcode } from "@tabler/icons-react";
import QrScannerComponent from "components/qr-scanner";
import { getImages } from "api/moving";
import { getItems } from "api/registry";
import { useMainContext } from "state/main-context";
import { emptyMovingItems, MovingData, MovingItems } from "types/moving-types";
import { useNotification } from "state/notification-context";
import { FilterOptionsTypes, getOptions } from "helpers/options-list";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function MovingList() {
  const { setLoadingScreen, itemsToMove, setItemsToMove } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const [options, setOptions] = useState<FilterOptionsTypes>();

  const [movingType, setMovingType] = useState<"single" | "multiple" | "">("");
  const [batch, setBatch] = useState<string>("");

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [itemsList, setItemsList] = useState<MovingItems>(emptyMovingItems);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(10);
  const [itemImages, setItemImages] = useState<any[]>([]);
  const [isFiltersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return movingType === "single" ? height - 333 : height - 413;
    } else if (width < 768) {
      return movingType === "single" ? height - 349 : height - 429;
    } else if (width < 1024) {
      return height - 331;
    } else return height - 387;
  }, [window.innerWidth]);

  const isMovingItems = useMemo(() => {
    return location.pathname.includes("moving") ? true : false;
  }, [location]);

  useEffect(() => {
    setItemsToMove([]);
    getQueryValues();
  }, []);

  useEffect(() => {
    if (movingType === "single" || (movingType === "multiple" && batch)) {
      searchParams.set("limit", "10");
      getMovingList(currentLimit, 0);
    }
  }, [movingType, batch]);

  const shouldDisableImageButton: boolean = useMemo(() => {
    if (itemsToMove.length < 1) {
      return true;
    }

    const firstPropValue = itemsToMove[0].id_item;
    return !itemsToMove.every((row) => row.id_item === firstPropValue);
  }, [itemsToMove]);

  const openQrScannerModal = () => {
    setIsQrModalOpen(true);
  };

  const closeQrScannerModal = () => {
    setIsQrModalOpen(false);
  };

  const setScannedQr = (id: string) => {
    appendFilter("id_item", id);
    getMovingList();
    closeQrScannerModal();
  };

  const getMovingList = async (limit?: number, offset?: number, params = searchParams) => {
    setTableLoading(true);

    if (limit !== undefined) {
      params.set("limit", limit.toString());
    }
    if (offset !== undefined) {
      params.set("offset", offset.toString());
    }

    await getItems(new URLSearchParams(params))
      .then((res: MovingItems) => {
        setItemsList(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getQueryValues = async () => {
    setTableLoading(true);

    await getOptions()
      .then((res) => {
        setOptions(res);
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const appendFilter = (key: string, value: string) => {
    if (key !== "limit" && key !== "offset") {
      searchParams.set("limit", "10");
      searchParams.delete("offset");
    }

    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const clearParams = async () => {
    searchParams.delete("offset");
    searchParams.delete("batch");
    searchParams.delete("item_name");
    searchParams.delete("id_item");
    searchParams.delete("name_surname");
    searchParams.delete("location_name");

    setBatch("");

    setCurrentPage(1);
    setCurrentLimit(10);
  };

  const clearAllFilters = () => {
    searchParams.delete("offset");
    searchParams.delete("id_item");
    searchParams.delete("item_name");
    searchParams.delete("name_surname");
    searchParams.delete("location_name");
    movingType === "single" && searchParams.delete("batch");
    searchParams.delete("category");
    searchParams.delete("source");
    // searchParams.delete("filial");

    getMovingList();
  };

  const handleSearchChange = (filterName: string | string[], value: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name, index) => {
        value[index] !== undefined && appendFilter(name, value[index]);
      });
    } else {
      value !== undefined && !Array.isArray(value) && appendFilter(filterName, value);
    }

    timeoutId && clearTimeout(timeoutId);

    if (
      filterName !== "name_surname" &&
      filterName !== "location_name" &&
      filterName !== "batch" &&
      filterName !== "category" &&
      filterName !== "source"
      // && filterName !== "filial"
    ) {
      const newTimeoutId = window.setTimeout(() => {
        getMovingList();
      }, 1000);

      setTimeoutId(newTimeoutId);
    } else {
      getMovingList();
    }

    setCurrentPage(1);
  };

  const handleClearSearch = (filterName: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name) => {
        searchParams.delete(name);
      });
    } else {
      searchParams.delete(filterName);
    }

    getMovingList();
  };

  const onMovingTypeChange = (value: "single" | "multiple" | "") => {
    setItemsToMove([]);
    clearParams();
    setMovingType(value);
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const movingTypeList = [
    { value: "single", label: t("moving.single") },
    { value: "multiple", label: t("moving.multiple") },
  ];

  const onTableChange: TableProps<MovingData>["onChange"] = (pagination, filters, sorter, extra) => {
    const updatedParams = new URLSearchParams(searchParams);

    // Handle pagination
    if (pagination) {
      setCurrentPage(pagination.current || 1);
      setCurrentLimit(pagination.pageSize || 10);

      const newOffset = ((pagination.current || 1) - 1) * (pagination.pageSize || 10);
      updatedParams.set("limit", (pagination.pageSize || 10).toString());
      updatedParams.set("offset", newOffset.toString());
    }

    console.log("sorter", sorter);

    // Handle sorting
    if (!Array.isArray(sorter)) {
      if (sorter.order) {
        updatedParams.set("sort_by", `${sorter.field}:${sorter.order === "ascend" ? "asc" : "desc"}`);
      } else {
        updatedParams.delete("sort_by");
        searchParams.delete("sort_by");
      }
    } else {
      updatedParams.delete("sort_by");
      searchParams.delete("sort_by");

      let reversedSorter = sorter.reverse();
      reversedSorter.forEach((sort) => {
        updatedParams.append("sort_by", `${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`);
      });
    }

    // Update searchParams with the new params using Array.from()
    Array.from(updatedParams.entries()).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    getMovingList(undefined, undefined, updatedParams);
  };

  const columns: TableProps<MovingData>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: movingType === "multiple",
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.amortisation_value"),
      dataIndex: "amortisation_value",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
  ];

  const rowSelection: TableProps<MovingData>["rowSelection"] = {
    type: movingType === "single" ? "radio" : "checkbox",
    onChange: (selectedRowKeys: React.Key[], selectedRows: MovingData[]) => {
      setItemsToMove(selectedRows);
    },
    getCheckboxProps: (record: MovingData) => ({
      disabled: false, // Column configuration not to be checked
      name: record.item_name,
    }),
  };

  const ProductMovingNode = (
    <>
      <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 grow">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row items-start flex-wrap justify-between gap-3">
              <div className="flex flex-col flex-wrap gap-3 w-full md:w-fit">
                <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
                  <span className="md:text-lg font-semibold">
                    {isMovingItems ? t("moving.moving_type_title") : t("moving.writeoff_type_title")}
                  </span>
                  <Select
                    className="w-full md:w-32"
                    value={movingType}
                    onChange={onMovingTypeChange}
                    options={movingTypeList}
                  />

                  {movingType === "multiple" && (
                    <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
                      <span className="md:text-lg font-semibold">{t("moving.batch_title")}</span>
                      <Select
                        className="w-full md:w-32"
                        value={batch}
                        onChange={(value) => {
                          setBatch(value);
                          appendFilter("batch", value);
                        }}
                        options={options?.batches}
                      />
                    </div>
                  )}
                </div>

                {(movingType === "single" || (movingType === "multiple" && batch)) && (
                  <span className="text-center md:text-left md:text-lg font-semibold">
                    {isMovingItems ? t("moving.moving_item") : t("moving.writeoff_item")}
                  </span>
                )}
              </div>
              {(movingType === "single" || (movingType === "multiple" && batch)) && (
                <div className="flex flex-wrap items-center gap-3 w-full md:w-fit">
                  <Button className="shadow grow" icon={<IconFilter />} onClick={() => setFiltersModalOpen(true)}>
                    {t("inputs.search_title")}
                  </Button>

                  <Button
                    className="shadow"
                    icon={<IconFileTypePdf size={20} />}
                    onClick={() => getItemImages("invoices", itemsToMove[0].id_invoice)}
                    disabled={shouldDisableImageButton}
                  ></Button>

                  <Button
                    className="shadow"
                    icon={<IconPhoto size={20} />}
                    onClick={() => getItemImages("items", itemsToMove[0].id_item)}
                    disabled={shouldDisableImageButton}
                  ></Button>

                  <div className="hidden">
                    <Image.PreviewGroup
                      items={itemImages}
                      preview={{
                        visible: previewVisible,
                        destroyOnClose: true,
                        imageRender: (original, info) => (
                          <div className="relative flex max-h-[70%]">
                            {original}
                            <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                              {translatePhotoName(itemImages[info.current]?.name)}
                            </div>
                          </div>
                        ),
                        onVisibleChange: (value) => {
                          setPreviewVisible(value);
                          !value && setItemImages([]);
                        },
                      }}
                    >
                      <Image />
                    </Image.PreviewGroup>
                  </div>

                  <Button
                    className="shadow grow text-white bg-green-600"
                    variant="solid"
                    onClick={() => navigate("confirm-page", { state: { type: movingType } })}
                    disabled={!itemsToMove.length}
                  >
                    {t("moving.next")}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {(movingType === "single" || (movingType === "multiple" && batch)) && (
            <Table<MovingData>
              size="small"
              className="grow"
              dataSource={itemsList.items}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(_, index) => (index || 0).toString()}
              loading={isTableLoading}
              scroll={{ x: 2000, y: table_height }}
              bordered
              pagination={{
                disabled: isTableLoading,
                total: itemsList.total_items,
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setCurrentLimit(pageSize);
                },
              }}
              onChange={onTableChange}
            />
          )}
        </div>
      </div>
      <QrScannerComponent isModalOpen={isQrModalOpen} onScan={setScannedQr} closeModal={closeQrScannerModal} />
      <Modal
        width={400}
        title={t("inputs.search_title")}
        open={isFiltersModalOpen}
        onCancel={() => setFiltersModalOpen(false)}
        onOk={() => setFiltersModalOpen(false)}
        footer={(_, { OkBtn }) => (
          <>
            <Button onClick={clearAllFilters}>{t("inputs.search_reset")}</Button>
            <OkBtn />
          </>
        )}
        keyboard={false}
        centered
      >
        <div className="flex flex-col gap-3">
          <Input
            placeholder={t("inputs.search_esep_id")}
            value={searchParams.get("id_item") || ""}
            onChange={(e) => handleSearchChange("id_item", e.target.value)}
            onClear={() => handleClearSearch("id_item")}
            allowClear
          />

          <Input
            placeholder={t("inputs.search_name")}
            value={searchParams.get("item_name") || ""}
            onChange={(e) => handleSearchChange("item_name", e.target.value)}
            onClear={() => handleClearSearch("item_name")}
            allowClear
          />

          <Select
            placeholder={t("inputs.search_mrp")}
            value={searchParams.get("name_surname")}
            options={options?.all_employees}
            onChange={(value) => value && handleSearchChange("name_surname", value)}
            onClear={() => handleClearSearch("name_surname")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_location")}
            value={searchParams.get("location_name")}
            options={options?.locations}
            onChange={(value) => value && handleSearchChange("location_name", value)}
            onClear={() => handleClearSearch("location_name")}
            allowClear
            showSearch
          />

          {movingType === "single" && (
            <Select
              placeholder={t("inputs.search_batch")}
              value={searchParams.get("batch")}
              options={options?.batches}
              onChange={(value) => value && handleSearchChange("batch", value)}
              onClear={() => handleClearSearch("batch")}
              allowClear
              showSearch
            />
          )}

          <Select
            placeholder={t("inputs.search_category")}
            value={searchParams.get("category")}
            options={options?.categories}
            onChange={(value) => value && handleSearchChange("category", value)}
            onClear={() => handleClearSearch("category")}
            allowClear
            showSearch
          />

          <Select
            placeholder={t("inputs.search_source")}
            value={searchParams.get("source")}
            options={options?.sources}
            onChange={(value) => value && handleSearchChange("source", value)}
            onClear={() => handleClearSearch("source")}
            allowClear
            showSearch
          />

          <Button icon={<IconQrcode />} onClick={openQrScannerModal}>
            {t("inputs.search_qr")}
          </Button>
        </div>
      </Modal>
    </>
  );

  const ProductMovingData: StageType = {
    id: 1,
    title: isMovingItems ? t("moving.moving_header") : t("moving.writeoff_header"),
    node: ProductMovingNode,
  };

  return <StageLayout stage={ProductMovingData} />;
}
