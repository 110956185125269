export const header = {
  back_button_text: "Go back",
  main_button_text: "Main page",
  modal_title: "Confirm action",
  modal_moving_text:
    "If you move away from the current page, your moving progress will be lost and you will need to start over.",
  modal_writeoff_text:
    "If you move away from the current page, your write-off progress will be lost and you will need to start over.",
  modal_checking_text:
    "If you move away from the current page, your stock count progress will be lost and you will need to start over.",
  modal_cancel: "Continue without saving",
  modal_confirm: "Stay on the page",
};
