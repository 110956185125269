import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";

export default function Forbidden() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const forbiddenPageTitle = <span>{t("error_page.forbidden_title")}</span>;
  const forbiddenPageSubtitle = <span className="text-lg">{t("error_page.forbidden_subtitle")}</span>;

  return (
    <section className="flex flex-col justify-center h-[100dvh]">
      <Result
        status="403"
        title={forbiddenPageTitle}
        subTitle={forbiddenPageSubtitle}
        extra={
          <Button type="primary" onClick={() => navigate("/", { replace: true })}>
            {t("error_page.go_to_main")}
          </Button>
        }
      />
    </section>
  );
}
