import { Button, Table, TableProps } from "antd";
import { RegistryItem, RegistryItemResponse } from "types/registry/item-types";
import { useTranslation } from "react-i18next";
import { IconFilter } from "@tabler/icons-react";
import { useMemo } from "react";

export default function DashboardItems({
  itemsDashboardData,
  openFilters,
}: {
  itemsDashboardData: RegistryItemResponse | null;
  openFilters: () => void;
}) {
  const { t } = useTranslation();

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 335;
    } else if (width < 768) {
      return height - 329;
    } else if (width < 1024) {
      return height - 303;
    } else return height - 337;
  }, [window.innerWidth]);

  const columns: TableProps<RegistryItem>["columns"] = [
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      width: 300,
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.quantity - b.quantity,
      width: 250,
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.sum_with_vat - b.sum_with_vat,
      width: 250,
    },
  ];

  return (
    <div className="flex flex-col gap-3 md:gap-4 lg:gap-6 shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <Button className="shadow md:hidden" icon={<IconFilter />} onClick={openFilters}>
        {t("inputs.search_title")}
      </Button>
      <Table<RegistryItem>
        size="small"
        className="grow"
        dataSource={itemsDashboardData?.items}
        columns={columns}
        rowKey={(_, index) => (index || 0).toString()}
        bordered
        pagination={{ defaultPageSize: 10 }}
        scroll={{ y: table_height }}
      />
    </div>
  );
}
