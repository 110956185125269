import { FilterTypes } from "types/filter-types";
import { getFilterCache } from "api/filter_cache";
import { LocationType } from "types/location-types";
import i18n from "i18n";

export interface NumberOption {
  label: string;
  value: number;
}

export interface StringOption {
  label: string;
  value: string;
}

export interface FilterOptionsTypes {
  years: NumberOption[];
  locationsList: LocationType[];
  locations: StringOption[];
  destinations: NumberOption[];
  reasons: StringOption[];
  filials: StringOption[];
  all_employees: StringOption[];
  responsibles: NumberOption[];
  batches: StringOption[];
  categories: StringOption[];
  months: NumberOption[];
  sources: StringOption[];
  methods: StringOption[];
  statuses: StringOption[];
  actions: StringOption[];
}

export async function getOptions(params?: URLSearchParams): Promise<FilterOptionsTypes> {
  try {
    const filtersResponse: FilterTypes = await getFilterCache(params);

    const yearOptions: NumberOption[] = filtersResponse.years.map((item) => ({
      label: i18n.language === "ru" ? `${item} год` : `${item} year`,
      value: item,
    }));

    const locationsList: LocationType[] = filtersResponse.location_names
      .filter((location) => location.location_name !== "Списанные")
      .sort((a, b) => a.location_name.localeCompare(b.location_name));

    const locationOptions: StringOption[] = filtersResponse.location_names
      .filter((location) => location.location_name !== "Списанные")
      .map((item) => ({
        label: item.location_name,
        value: item.location_name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const destinationOptions: NumberOption[] = filtersResponse.location_names
      .filter((location) => location.location_name !== "Списанные" && location.location_status)
      .map((item) => ({
        label: item.location_name,
        value: item.id_location,
      }));

    const reasonOptions: StringOption[] = filtersResponse.reasons.map((item) => ({
      label: item,
      value: item,
    }));

    const filialOptions: StringOption[] = filtersResponse.filials.map((item) => ({
      label: item,
      value: item,
    }));

    const allEmployeeOptions: StringOption[] = filtersResponse.employee_names.map((item) => ({
      label: item.name_surname,
      value: item.name_surname,
    }));

    const responsibleOptions: NumberOption[] = filtersResponse.employee_names
      .filter((employee) => employee.employee_status !== false)
      .map((item) => ({
        label: item.name_surname,
        value: item.id_employee,
      }));

    const batchOptions: StringOption[] = filtersResponse.batch_names.map((item) => ({
      label: item.batch,
      value: item.batch,
    }));

    const categoryOptions: StringOption[] = filtersResponse.category.map((item) => ({
      label: i18n.language === "ru" ? item : `${item === "ОС" ? "Fixed assets" : "Inventory"}`,
      value: item,
    }));

    const monthOptions: NumberOption[] = [
      { label: i18n.language === "ru" ? "Январь" : "January", value: 1 },
      { label: i18n.language === "ru" ? "Февраль" : "February", value: 2 },
      { label: i18n.language === "ru" ? "Март" : "March", value: 3 },
      { label: i18n.language === "ru" ? "Апрель" : "April", value: 4 },
      { label: i18n.language === "ru" ? "Май" : "May", value: 5 },
      { label: i18n.language === "ru" ? "Июнь" : "June", value: 6 },
      { label: i18n.language === "ru" ? "Июль" : "July", value: 7 },
      { label: i18n.language === "ru" ? "Август" : "August", value: 8 },
      { label: i18n.language === "ru" ? "Сентябрь" : "September", value: 9 },
      { label: i18n.language === "ru" ? "Октябрь" : "October", value: 10 },
      { label: i18n.language === "ru" ? "Ноябрь" : "November", value: 11 },
      { label: i18n.language === "ru" ? "Декабрь" : "December", value: 12 },
    ];

    const sourceOptions: StringOption[] = [
      { label: "E-SEP", value: "E-SEP" },
      { label: "1с", value: "1с" },
    ];

    const methodOptions: StringOption[] = [
      { label: i18n.language === "ru" ? "От учета к товару" : "Book to floor", value: "От учета к товару" },
      { label: i18n.language === "ru" ? "От товара к учету" : "Floor to book", value: "От товара к учету" },
    ];

    const statusOptions: StringOption[] = [
      { label: i18n.language === "ru" ? "Не инвентаризировано" : "Not inventored", value: "none" },
      { label: i18n.language === "ru" ? "Соответствует" : "Matches", value: "Соответствует" },
      {
        label: i18n.language === "ru" ? "Количество не соответствует" : "Quantity does not match",
        value: "Количество не соответствует",
      },
      {
        label: i18n.language === "ru" ? "QR код не соответствует" : "QR code does not match",
        value: "QR код не соответствует",
      },
      { label: i18n.language === "ru" ? "Товара нет" : "No such item", value: "Товара нет" },
    ];

    const actionOptions: StringOption[] = [
      {
        label: i18n.language === "ru" ? "Регистрация" : "Registration",
        value: "Регистрация",
      },
      {
        label: i18n.language === "ru" ? "Регистрация товара (фото)" : "Item registration (photo)",
        value: "Регистрация товара (фото)",
      },
      { label: i18n.language === "ru" ? "Инвентаризация" : "Stock count", value: "Инвентаризация" },
      { label: i18n.language === "ru" ? "Перемещение" : "Transfer", value: "Перемещение" },
      {
        label: i18n.language === "ru" ? "Списание" : "Write-off",
        value: "Списание",
      },
      { label: i18n.language === "ru" ? "Повторная печать" : "QR code reprint", value: "Повторная печать" },
    ];

    return {
      months: monthOptions,
      years: yearOptions,
      locationsList: locationsList,
      locations: locationOptions,
      destinations: destinationOptions,
      reasons: reasonOptions,
      filials: filialOptions,
      all_employees: allEmployeeOptions,
      responsibles: responsibleOptions,
      batches: batchOptions,
      categories: categoryOptions,
      sources: sourceOptions,
      methods: methodOptions,
      statuses: statusOptions,
      actions: actionOptions,
    };
  } catch (error) {
    throw error;
  }
}
