export const register = {
  header: "Регистрация нового товара",

  // step 1
  choose_type_title: "Выберите действие: сканировать ОС/ТМЗ или добавить фото",
  os_type: "Основные средства",
  tmz_type: "Товарно-материальный запас",
  add_photo: "Добавить фотографии",
  next: "Далее",
  old_register_title: "У вас имеется незавершённая регистрация",
  old_register_content:
    "Вы можете продолжить процесс регистрации с того места, где он был прерван, либо начать регистрацию заново.",
  old_register_cancel: "Начать заново",
  old_register_confirm: "Продолжить регистрацию",

  // step 2
  scan_in_progress: "Идёт сканирование документа...",
  alert_title: "Внимание!",
  alert_text_1: "1. Если допущена ошибка при сканировании запустите сканирование заново.",
  alert_text_2: "2. Для корректной обработки данных накладную необходимо повернуть в правильное положение.",
  alert_text_3: "3. Для того чтобы повернуть накладную в нужное положение нажмите на её изображение.",
  alert_text_4: "4. После открытия изображения внизу экрана будут соответствующие кнопки для корректировки положения.",
  scan_example_title: "Нажмите на накладную, чтобы перевернуть её согласно примеру ниже.",
  scanned_pages_title: "Отсканированные страницы",
  scan_again: "Сканировать заново",
  scan_more: "Сканировать следующую страницу",
  enter_data_manually: "Ввести данные вручную",
  continue: "Продолжить",
  parse: "Отправить на обработку",
  delete_scan_button: "Удалить скан",
  delete_scan_title: "Подтвердите действие",
  delete_scan_content: "Вы уверены, что хотите удалить данный скан?",
  delete_scan_confirm: "Удалить",
  delete_scan_cancel: "Отмена",

  // step 3
  parse_in_progress: "Идёт обработка документа...",
  parsed_alert_title: "Если обнаружены ошибки:",
  parsed_alert_text_1: "1. Выберите соответствующий пункт;",
  parsed_alert_text_2: "2. Исправьте данные вручную.",
  parsed_alert_text_3_1: "Внимание!",
  parsed_alert_text_3_2: "Данные в полях 'Количество', 'Сумма' будут округлены до сотых (0.42).",
  parsed_alert_text_4: "4. Обязательно укажите срок службы - редактирование после сохранения будет невозможно.",
  parsed_invoice_title: "Накладная на отпуск запасов на сторону",
  // invoice data
  invoice_data_modal_title: "Предупреждение",
  invoice_data_modal_content:
    "Нажимая 'Создать карту товаров' вы подтверждаете достоверность данных. В случае возврата к этой форме все дальнейшие данные будут перезаписаны.",
  invoice_data_modal_cancel: "Вернуться",
  invoice_data_modal_confirm: "Создать карту товаров",
  invoice_data_sender: "Организация - отправитель",
  invoice_data_iin: "ИИН/БИН",
  invoice_data_number: "Номер документа",
  invoice_data_date: "Дата составления",
  invoice_data_recipient: "Организация - получатель",
  invoice_data_responsible: "Ответственный за поставку",
  invoice_data_organization: "Транспортная организация",
  invoice_data_consignment: "Товаро-транспортная накладная",
  invoice_data_reset_button: "Сбросить изменения",
  invoice_data_new_form_button: "Очистить форму",
  invoice_data_continue_button: "Продолжить",
  // items
  add_item: "Добавить строку данных",
  remove_item: "Удалить выбранные строки",

  // step 4
  print_multiple_qr_title: "Подтвердите действие",
  print_multiple_qr_content: "Вы действительно хотите распечатать QR коды по одному для каждой позиции?",
  print_multiple_qr_confirm: "Да, распечатать",
  print_multiple_qr_cancel: "Нет, вернуться назад",
  save_invoice_title: "Вы действительно хотите сохранить товары?",
  save_invoice_content: "Фотографии к товарам можно будет добавить через функцию 'Добавить фотографии' в модуле 'Регистрация'",
  save_invoice_confirm: "Да, сохранить и завершить",
  save_invoice_cancel: "Нет, вернуться назад",
  step_4_title_1: "Накладная на отпуск №",
  step_4_title_2: "от",
  step_4_title_3: "Зафиксировано товаров:",
  step_4_title_qr: "Выберите товар, чтобы распечатать и наклеить QR-код",
  step_4_print_single_qr: "Распечатать по одному QR коду на каждую позицию",
  step_4_save: "Сохранить карты товаров",
  step_4_continue: "Продолжить",
  step_4_saved: "Карта товаров успешно сохранена!",

  // step 5
  qr_print_in_progress: "Идёт распечатка QR-кодов...",
  qr_print_count: "Распечатать QR-коды в количестве товара по накладной?",
  qr_print_multiple: "Да, распечатать несколько QR-кодов (опция недоступна если количество не целое число)",
  qr_print_single: "Нет, распечатать один QR-код на всю позицию",
  qr_print_continue: "Продолжить",

  // step 6
  qr_ready_title: "Ваши QR-коды напечатаны. Наклейте их на товары чтобы завершить процесс.",
  qr_ready_continue: "Продолжить",
  product_saved: "Карта товара успешно сохранена!",
  product_saving: "Идёт сохранение карты товара...",

  // invoice list
  save_without_photo_title: "Подтвердите действие",
  save_without_photo_content: "Вы уверены, что хотите сохранить накладную без добавления фотографий?",
  save_without_photo_confirm: "Да, сохранить без фотографий",
  save_without_photo_cancel: "Нет, вернуться назад",
  choose_invoice_title: "Выберите накладную для добавления фотографий или сохраните её без фотографий​",
  save_without_photo_button: "Сохранить без фотографий",
  add_photo_button: "Добавить фотографии",
  
  // add photo
  photo: "Фотография",
  absence: "Отсутствует",
  presence: "Есть",
  save_photo_modal_title: "Подтвердите действие",
  save_photo_content: "Вы уверены, что хотите сохранить изменения?",
  save_photo_confirm: "Да, сохранить",
  save_photo_cancel: "Нет, вернуться назад",
  save_photo_title: "Добавьте фотографии для нужных товаров​",
  save_photo_buttom: "Сохранить",
};
