import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Input, Modal, Row } from "antd";
import { ScanDataDefaultValues, TableDefaultValues } from "types/registration-types";
import EditableTable from "./editable-table";
import { useTranslation } from "react-i18next";

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export default function DocumentForm({
  defaultData,
  submitDocument,
}: {
  defaultData: ScanDataDefaultValues;
  submitDocument: (data: ScanDataDefaultValues) => void;
}) {
  const { t } = useTranslation();

  const [fields, setFields] = useState<FieldData[]>([]);
  const [newFields, setNewFields] = useState<FieldData[]>([]);
  const [tableData, setTableData] = useState<TableDefaultValues[]>([]);
  const [isOS, setOS] = useState<boolean>(false);

  useEffect(() => {
    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
    savedRegister.category === "ОС" && setOS(true);

    resetToDefault();
  }, [defaultData]);

  const resetToDefault = () => {
    setFields([
      {
        name: "sender",
        value: defaultData.sender,
      },
      {
        name: "iin_bin",
        value: defaultData.iin_bin.toString(),
      },
      {
        name: "invoice_number",
        value: defaultData.invoice_number,
      },
      {
        name: "invoice_date",
        value: defaultData.invoice_date,
      },
      {
        name: "recipient",
        value: defaultData.recipient,
      },
      {
        name: "delivery_responsible",
        value: defaultData.delivery_responsible,
      },
      {
        name: "transport_organization",
        value: defaultData.transport_organization,
      },
      {
        name: "item_transport_invoice",
        value: defaultData.item_transport_invoice,
      },
    ]);

    setTableData(defaultData.table_data);
  };

  const clearInputs = () => {
    setFields([
      {
        name: "sender",
        value: null,
      },
      {
        name: "iin_bin",
        value: null,
      },
      {
        name: "invoice_number",
        value: null,
      },
      {
        name: "invoice_date",
        value: null,
      },
      {
        name: "recipient",
        value: null,
      },
      {
        name: "delivery_responsible",
        value: null,
      },
      {
        name: "transport_organization",
        value: null,
      },
      {
        name: "item_transport_invoice",
        value: null,
      },
    ]);
    setTableData([]);
  };

  const onFormComplete = () => {
    Modal.confirm({
      width: 500,
      centered: true,
      title: t("register.invoice_data_modal_title"),
      content: t("register.invoice_data_modal_content"),
      cancelText: t("register.invoice_data_modal_cancel"),
      okText: t("register.invoice_data_modal_confirm"),
      onOk: () => {
        let newFieldData: ScanDataDefaultValues = {
          sender: newFields[0].value ? newFields[0].value : fields[0].value,
          iin_bin: newFields[1].value ? newFields[1].value : fields[1].value,
          invoice_number: newFields[2].value ? newFields[2].value : fields[2].value,
          invoice_date: newFields[3].value ? newFields[3].value : fields[3].value,
          recipient: newFields[4].value ? newFields[4].value : fields[4].value,
          delivery_responsible: newFields[5].value ? newFields[5].value : fields[5].value,
          transport_organization: newFields[6].value ? newFields[6].value : fields[6].value,
          item_transport_invoice: newFields[7].value ? newFields[7].value : fields[7].value,
          table_data: tableData.map((item) => ({
            ...item,
            quantity: +(+item.quantity).toFixed(2),
            unit_price: +(+item.unit_price).toFixed(2),
            total_with_vat: +(+item.total_with_vat).toFixed(2),
            vat: +(+item.vat).toFixed(2),
            lifecycle: isOS ? item.lifecycle : undefined,
          })),
        };

        submitDocument(newFieldData);

        localStorage.setItem("document_is_ready", JSON.stringify(true));
      },
    });
  };

  const validateDocument = (e: any) => {
    if (submittable && areAllValuesValid) {
      e.preventDefault();

      onFormComplete();
    }
  };

  // TODO: remove temp ---------
  const [mainForm] = Form.useForm();
  const [submittable, setSubmittable] = React.useState<boolean>(false);

  const values = Form.useWatch([], mainForm);

  useEffect(() => {
    mainForm
      .validateFields({ recursive: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [mainForm, values]);

  const isDateValid = (dateString: string) => {
    return /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  };

  const areAllValuesValid = useMemo(() => {
    return isOS && tableData.length
      ? tableData.every(
          (item) =>
            !!item.row_number &&
            !!item.name.trim() &&
            !!item.nomenclature_number.trim() &&
            !!item.unit_measurement.trim() &&
            !!item.quantity &&
            !!item.unit_price &&
            !!item.total_with_vat &&
            item.lifecycle &&
            !!+item.lifecycle
        )
      : tableData.every(
          (item) =>
            !!item.row_number &&
            !!item.name.trim() &&
            !!item.nomenclature_number.trim() &&
            !!item.unit_measurement.trim() &&
            !!item.quantity &&
            !!item.unit_price &&
            !!item.total_with_vat
        );
  }, [tableData]);
  // TODO: remove temp ---------

  return (
    <Form
      className="flex flex-col gap-3"
      form={mainForm}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        setNewFields(allFields);
      }}
      validateMessages={{
        required: t("validations.required"),
      }}
      autoComplete="off"
      layout="vertical"
    >
      <Row gutter={12}>
        <Col span={10}>
          <Form.Item<ScanDataDefaultValues>
            name="sender"
            label={t("register.invoice_data_sender")}
            rules={[{ required: true }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ScanDataDefaultValues>
            name="iin_bin"
            label={t("register.invoice_data_iin")}
            rules={[{ type: "string", required: true, len: 12 }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item<ScanDataDefaultValues>
            name="invoice_number"
            label={t("register.invoice_data_number")}
            rules={[{ required: true }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item<ScanDataDefaultValues>
            name="invoice_date"
            label={t("register.invoice_data_date")}
            rules={[
              { required: true, len: 10, message: t("validations.required") },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  return isDateValid(value) ? Promise.resolve() : Promise.reject(new Error(t("validations.valid")));
                },
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ScanDataDefaultValues>
            name="recipient"
            label={t("register.invoice_data_recipient")}
            rules={[{ required: true }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ScanDataDefaultValues> name="delivery_responsible" label={t("register.invoice_data_responsible")}>
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ScanDataDefaultValues>
            name="transport_organization"
            label={t("register.invoice_data_organization")}
          >
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<ScanDataDefaultValues>
            name="item_transport_invoice"
            label={t("register.invoice_data_consignment")}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <EditableTable
        dataSource={tableData}
        setDataSource={setTableData}
        resetToDefault={resetToDefault}
        clearInputs={clearInputs}
        validateDocument={validateDocument}
        submittable={submittable}
        areAllValuesValid={areAllValuesValid}
      />
    </Form>
  );
}
