export const dashboard = {
  header: "Отчёты",

  // layout
  last_7_days: "За неделю",
  last_30_days: "За месяц",
  year: "Год",
  month: "Месяц",
  category: "Категория",
  batch: "Партия",
  source: "Источник",
  location: "Локация",
  employee: "Сотрудник",
  filial: "Филиал",
  method: "Метод",
  status: "Статус",

  // tabs
  main: "Главная",
  write_off: "Списания",
  inventory: "Инвентаризация",
  items: "Товары",
  actions: "Активность",

  // units
  pieces: "шт",
  amount: "Сумма, KZT",
  quantity: "Количество, шт",

  // main
  initial_value: "Балансовая стоимость",
  quantity_of_goods: "Количество товаров",
  quantity_of_items: "Количество позиций",
  residual_value: "Остаточная стоимость",
  initial_value_of_goods: "Балансовая стоимость товаров на списание",
  goods_for_writeoff: "Количество товаров на списание",
  items_for_writeoff: "Количество позиций на списание",
  invoices: "Количество накладных",
  // 1
  invoice_by_date: "Количество накладных по дате",
  // 2
  writeoff_pie_title: "Кол-во товаров регулярных и на списание",
  writeoff_pie_1: "Товары на списание",
  writeoff_pie_2: "Товары регулярные",
  // 3
  quantity_by_location: "Количество по локациям",
  // 4
  top_10: "Топ 10 товаров для списания по сумме",

  // write-off
  initial_value_written_off: "Балансовая стоимость списанных товаров",
  written_off_goods: "Количество списанных товаров",
  written_off_items: "Количество списанных позиций",
  // 1
  writeoff_by_days: "Количество списаний по дням",
  // 2
  writeoff_by_users: "Кол-во списаний по пользователям",
  // 3
  writeoff_by_reasons: "Кол-во списаний по причинам",
  // 4
  writeoff_by_mrp: "Кол-во списаний по МОЛ",

  // inventory
  inventory_count: "Количество инвентаризаций",
  inventory_items_count: "Количество инвентаризованных позиций",
  // 1
  inventory_by_inventarizator: "Количество инвентаризаций по пользователям",
  // 2
  inventory_by_status: "Количество инвентаризаций по статусам",
  // 3
  inventory_by_days: "Количество инвентаризаций по дням",
  // 4
  inventory_by_method: "Кол-во инвентаризаций по методу",
};
