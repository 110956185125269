import { Api } from "api";
import LocalInstance from "api/local-instance";

export const checkScan = async () => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/scan_api/?hash_pw=${hash}`);
  return res.data;
};

export const scan = async (payload: { id_5: string; scan_num: number; start_again?: boolean }) => {
  const res = await LocalInstance.post(`/scan_api/`, payload);
  return res.data;
};

export const saveImg = async (payload: { id_5: string; images: string[] }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/save_image/?hash_pw=${hash}`, payload);
  return res.data;
};

export const addToProcessQueue = async (id_5: string) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/get-default-values/?hash_pw=${hash}`, { params: { id_5 } });
  return res.data;
};

export const getParseResult = async (id_5: string) => {
  const res = await Api.get(`/get-task-result/`, { params: { id_5 } });
  return res.data;
};

export const checkQrCode = async (payload: { selected: any; id_5: string; activator_toggle: boolean }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/process-qrcodes/?hash_pw=${hash}`, payload);
  return res.data;
};

export const checkMultipleQrCode = async (payload: { selected: any; id_5: string; activator_toggle: boolean }) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/process-qrcodes-multiple/?hash_pw=${hash}`, payload);
  return res.data;
};

export const getQrCode = async (payload: { qr_images: string[] }) => {
  const res = await LocalInstance.post(`/process-qrcodes/`, payload);
  return res.data;
};

export const sendPhoto = async (payload: {
  id_user: number;
  id_5: string;
  qr_id: number;
  selected: any;
  captured_file: string;
  action_name: string;
}) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/process-captured-photo/?hash_pw=${hash}`, payload);
  return res.data;
};

export const saveInvoice = async (payload: {
  id_5: string;
  user_id: number;
  form_data: any;
  edited_data: any[];
  employee_id: number;
  location_id: number;
}) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.post(`/ProcessData/?hash_pw=${hash}`, payload);
  return res.data;
};

export const getTempInvoices = async () => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/get_temp_invoices/?hash_pw=${hash}`);
  return res.data;
};

export const getTempInvoiceItems = async (id_invoice: number) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.get(`/get_items_for_temp_invoice/${id_invoice}?hash_pw=${hash}`);
  return res.data;
};

export const deleteTempInvoice = async (id_invoice: number) => {
  const hash = localStorage.getItem("hash");
  const res = await Api.delete(`/delete_temp_invoice/${id_invoice}?hash_pw=${hash}`);
  return res.data;
};
