import { ReactNode } from "react";

export type CategoryType = {
  id: string;
  title: string;
  icon: ReactNode;
  url: string;
  disabled?: boolean;
};

export const emptyCategory: CategoryType = {
  id: "",
  title: "",
  icon: "",
  url: "",
  disabled: false,
};
