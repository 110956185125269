import React, { createContext, useContext, ReactNode } from "react";
import { notification } from "antd";
import { IconX } from "@tabler/icons-react";

// Types for notification context
interface NotificationContextType {
  showNotification: (type: "success" | "warning" | "error", title: string, description?: string) => void;
}

// Create Context for Notification
const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

// NotificationProvider component to provide context
export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const showNotification = (type: "success" | "warning" | "error", title: string, description?: string) => {
    const typeToBgColor: Record<string, string> = {
      success: "bg-green-400",
      warning: "bg-yellow-400",
      error: "bg-red-400",
    };

    // Open antd notification with custom styling
    notification.open({
      className: `${typeToBgColor[type]} rounded-lg`,
      message: <div className={type === "warning" ? undefined : "text-white"}>{title}</div>,
      description: <div className={type === "warning" ? undefined : "text-white"}>{description}</div>,
      closeIcon: <IconX className={type === "warning" ? undefined : "text-white"} />,
      duration: 3,
    });
  };

  return <NotificationContext.Provider value={{ showNotification }}>{children}</NotificationContext.Provider>;
};

// Custom hook to use the notification
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};
