export const moving = {
  // moving list
  moving_header: "Перемещение товара",
  writeoff_header: "Списание товара",
  single: "По одному",
  multiple: "По партиям",
  moving_type_title: "Выберите тип перемещения",
  writeoff_type_title: "Выберите тип списания",
  batch_title: "Выберите партию",
  moving_item: "Выберите товар для перемещения",
  writeoff_item: "Выберите товар для списания",
  next: "Далее",

  // moving page
  moving_guide_title: "Инструкция по перемещению",
  moving_guide_1: "1. Выберите конечную локацию;",
  moving_guide_2: "2. Выберите МОЛ (материально-ответственное лицо);",
  moving_guide_3: "3. Выберите количество товара к перемещению;",
  moving_guide_4: "4. Если количество товара дробное число - указать с округлением до сотых (например: 1.45)",
  moving_guide_5: "5. Сделайте фото перемещённого товара.",
  writeoff_guide_title: "Инструкция по списанию",
  writeoff_guide_1: "1. Выберите причину списания",
  writeoff_guide_2: "2. Выберите количество товара к списанию;",
  writeoff_guide_3: "3. Если количество товара дробное число - указать с округлением до сотых (например: 1.45)",
  writeoff_guide_4: "4. Сделайте фото списанного товара.",
  excluded_alert:
    "На выбранной локации у данного лица уже имеются некоторые из выбранных позиций, в связи с чем максимальное количество товара было ограничено",
  move_from: "Откуда",
  move_where: "Куда",
  move_mrp: "Новое МОЛ",
  move_reason: "Причина списания",
  move_quantity: "Количество",
  quantity_alert: "Количество не может быть равно нулю либо превышать общее количество товаров в таблице!",
  confirm_header: "Подтвердите действие",
  confirm_moving_title: "Вы действительно хотите переместить товары?",
  confirm_writeoff_title: "Вы действительно хотите списать товары?",
  cancel: "Отмена",
  confirm: "Подтвердить",
  move_button: "Переместить",
  writeoff_button: "Списать",
  move_success: "Товар успешно перемещен!",
  move_in_progress: "Производим перемещение товара...",
  writeoff_success: "Товар успешно списан!",
  writeoff_in_progress: "Производим списание товара...",
};
