export const reasons = {
  header: "Причины списания",
  name_column: "Название",
  title: "Список причин списания",
  add_reason: "Добавить причину",
  // new reason
  new_reason_title: "Новая причина списания",
  cancel: "Отмена",
  save: "Сохранить",
};
