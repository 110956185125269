import { useEffect, useState } from "react";
import type { FormProps } from "antd";
import { Button, Form, Input, Card, Divider, Space } from "antd";
import { IconLock, IconUser } from "@tabler/icons-react";
import { useMainContext } from "state/main-context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type FieldType = {
  username: string;
  password: string;
};

export default function AuthPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { login } = useMainContext();

  const [isLoading, setLoading] = useState<boolean>(false);

  const onFinish: FormProps<FieldType>["onFinish"] = async (credentials) => {
    try {
      setLoading(true);
      await login(credentials);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    navigate("/", { replace: true });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-dvh">
      <Card className="m-4 sm:m-0 sm:w-96 md:w-[500px] p-2 sm:p-4 md:p-8 lg:p-12 shadow-lg rounded-xl lg:rounded-2xl">
        <Space className="w-full" direction="vertical" size={"large"}>
          <h6 className="text-lg md:text-xl font-bold">E-SEP</h6>
          <Divider className="-mt-[4px] mb-[6px]" />
          <div className="mb-2">
            <h6 className="text-lg md:text-xl font-bold">{t("auth_page.greet")}</h6>
            <p className="text-sm lg:text-base">{t("auth_page.enter_data")}</p>
          </div>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed} disabled={isLoading} autoComplete="off">
            <Form.Item<FieldType> name="username" rules={[{ required: true, message: t("auth_page.username_error") }]}>
              <Input
                className="h-12 rounded-lg lg:rounded-xl"
                placeholder={t("auth_page.username")}
                prefix={<IconUser />}
              />
            </Form.Item>

            <Form.Item<FieldType> name="password" rules={[{ required: true, message: t("auth_page.password_error") }]}>
              <Input.Password
                className="h-12 rounded-lg lg:rounded-xl"
                placeholder={t("auth_page.password")}
                prefix={<IconLock />}
              />
            </Form.Item>

            <Button
              className="h-12 lg:h-16 mt-6 md:mt-8 lg:mt-10 w-full text-sm md:text-md lg:text-lg font-semibold rounded-lg lg:rounded-xl"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t("auth_page.log_in")}
            </Button>
          </Form>
        </Space>
      </Card>
    </div>
  );
}
