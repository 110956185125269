import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Image, Modal, Space, Spin, Table, Tag } from "antd";
import type { TableProps } from "antd";
import CameraCapture from "components/camera-capture";
import { useMainContext } from "state/main-context";
import { deleteTempInvoice, getTempInvoiceItems, sendPhoto } from "api/registration";
import { TableWithPhoto } from "types/registration-types";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";

export default function AddPhoto() {
  const { user, reactData, setReactData } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { id_invoice } = useParams();

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [isCameraActive, setCameraActive] = useState<boolean>(false);
  const [openedItemPhotoId, setOpenedItemPhotoId] = useState<string>("");

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 291;
    } else if (width < 768) {
      return height - 279;
    } else if (width < 1024) {
      return height - 235;
    } else return height - 269;
  }, [window.innerWidth]);

  useEffect(() => {
    getInvoiceItems();

    return () => {
      setReactData([]);
    };
  }, [id_invoice]);

  const getInvoiceItems = async () => {
    setTableLoading(true);

    await getTempInvoiceItems(id_invoice ? +id_invoice : 0)
      .then((res: TableWithPhoto[]) => {
        setReactData(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => setTableLoading(false));
  };

  const openCameraModal = (record: TableWithPhoto) => {
    setOpenedItemPhotoId(record.id_item);
    setCameraActive(true);
  };

  const savePhoto = (photo: string | null) => {
    if (photo) {
      let tableWithPhoto = reactData.map((item) =>
        item.id_item === openedItemPhotoId ? { ...item, photo: photo } : item
      );

      setReactData(tableWithPhoto);

      let tableWithoutPhoto = reactData.map((item) =>
        item.id_item === openedItemPhotoId ? { ...item, photo: "saved" } : item
      );

      localStorage.setItem("in_process", JSON.stringify(tableWithoutPhoto));
    }
  };

  const saveItemMap = async () => {
    try {
      setSaving(true);

      await new Promise<void>(async (resolve, reject) => {
        try {
          await deleteTempInvoice(id_invoice ? +id_invoice : 0);

          const promises: Promise<void>[] = [];

          reactData
            .filter((item) => item.photo)
            .forEach((item) => {
              promises.push(
                sendPhoto({
                  id_user: user.user_id,
                  id_5: user.id_5.toString(),
                  qr_id: +item.id_item,
                  selected: {
                    "Номер по порядку": reactData[0].row_number,
                    "Наименование, характеристика": reactData[0].item_name,
                    "Номенклатурный номер": reactData[0].nomenclature_number,
                    "Единица измерения": reactData[0].unit_measurement,
                    "Количество отпущено": reactData[0].quantity,
                    "Цена за единицу, в KZT": reactData[0].unit_price,
                    "Сумма с НДС, в KZT": reactData[0].total_with_vat,
                    "Сумма НДС, в KZT": reactData[0].vat,
                    "Срок полезной службы": reactData[0].lifecycle,
                  },
                  captured_file: item.photo.replace("data:image/png;base64,", ""),
                  action_name: "Регистрация товара (фото)",
                })
              );
            });

          await Promise.all(promises);

          setReactData([]);
          navigate(-1);
          resolve();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.save_invoice_error"), err);
          reject();
        }
      });
      showNotification("success", t("notifications.invoice_register_success"));
    } catch (error: any) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const photoColumn = (record: TableWithPhoto) => {
    return (
      <Space direction="vertical">
        {record.photo && <Image width={100} src={record.photo || undefined} />}
        <Button onClick={() => openCameraModal(record)}>
          {record.photo ? t("photo.change_photo") : t("photo.add_photo")}
        </Button>
      </Space>
    );
  };

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      width: "600px",
      title: t("register.save_photo_modal_title"),
      content: t("register.save_photo_content"),
      okText: t("register.save_photo_confirm"),
      cancelText: t("register.save_photo_cancel"),
      cancelButtonProps: { disabled: isSaving },
      onOk: saveItemMap,
      keyboard: false,
      centered: true,
    });
  };

  const itemColumns: TableProps<TableWithPhoto>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (id_item: string) => <Tag color={id_item ? "success" : "error"}>{id_item || t("register.absence")}</Tag>,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
    },
    {
      title: t("table.unit_measurement"),
      dataIndex: "unit_measurement",
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity_released",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("photo.title"),
      dataIndex: "photo",
      render: (photo: string) => (
        <Tag color={photo ? "success" : "error"}>{photo ? t("register.presence") : t("register.absence")}</Tag>
      ),
    },
  ];

  const columns: TableProps<TableWithPhoto>["columns"] = [
    ...itemColumns,
    { render: (_, record) => photoColumn(record) },
  ];

  const sixthStageNode = (
    <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col gap-3 grow">
        <div className="flex flex-col md:flex-row lg:items-end lg:justify-between gap-3">
          <h2 className="text-lg lg:text-xl font-semibold text-center">{t("register.save_photo_title")}</h2>
          <Button className="shadow" type="primary" onClick={showConfirm}>
            {t("register.save_photo_buttom")}
          </Button>
        </div>
        <Table<TableWithPhoto>
          size="small"
          className="grow"
          columns={columns}
          dataSource={reactData}
          rowKey="id_item"
          loading={isTableLoading}
          bordered
          pagination={false}
          scroll={{ x: 1200, y: table_height }}
        />
      </div>
    </div>
  );

  const sixthStageData: StageType = {
    id: 3,
    title: t("register.header"),
    node: sixthStageNode,
  };

  return (
    <>
      <StageLayout stage={sixthStageData} />
      <CameraCapture isCameraActive={isCameraActive} setCameraActive={setCameraActive} sendPhoto={savePhoto} />
      <Spin spinning={isSaving} fullscreen></Spin>
    </>
  );
}
