export const register = {
  header: "Register a new item",

  // step 1
  choose_type_title: "Select action: scan for fixed assets/inventory or add photos",
  os_type: "Fixed assets",
  tmz_type: "Inventory",
  add_photo: "Add photos",
  next: "Next",
  old_register_title: "You have an incomplete registration",
  old_register_content:
    "You can continue the registration process from where it was interrupted, or start the registration again.",
  old_register_cancel: "Start again",
  old_register_confirm: "Continue registration",

  // step 2
  scan_in_progress: "Scanning document...",
  alert_title: "Attention!",
  alert_text_1: "1. If an error was made during scanning, restart scanning.",
  alert_text_2: "2. For correct processing of stored data, it is necessary to rotate it to the correct position.",
  alert_text_3: "3. To rotate the invoice to the desired position, click on its image.",
  alert_text_4: "4. After opening the image, the lower screen will respond with buttons for adjusting positions.",
  scan_example_title: "Click on the invoice to flip it as shown in the example below.",
  scanned_pages_title: "Scanned documents",
  scan_again: "Re-scan",
  scan_more: "Scan the next page",
  enter_data_manually: "Enter data manually",
  continue: "Continue",
  parse: "Process document",
  delete_scan_button: "Delete scan",
  delete_scan_title: "Confirm action",
  delete_scan_content: "Are you sure you want to delete this scan?",
  delete_scan_confirm: "Confirm",
  delete_scan_cancel: "Cancel",

  // step 3
  parse_in_progress: "Parsing in progress...",
  parsed_alert_title: "If errors are found:",
  parsed_alert_text_1: "1. Select the appropriate item;",
  parsed_alert_text_2: "2. Correct the data manually.",
  parsed_alert_text_3_1: "Attention!",
  parsed_alert_text_3_2: "Data in the 'quantity' and 'amount' fields will be rounded to hundredths (0.42).",
  parsed_alert_text_4: "4. Be sure to indicate the service life - editing after saving will not be possible.",
  parsed_invoice_title: "Inventory issuance invoice",
  // invoice data
  invoice_data_modal_title: "Warning",
  invoice_data_modal_content:
    "By clicking 'create product card' you confirm the accuracy of the data. If you return to this form, all further data will be overwritten.",
  invoice_data_modal_cancel: "Return back",
  invoice_data_modal_confirm: "Create inventory card",
  invoice_data_sender: "Sending organization",
  invoice_data_iin: "TIN/BIN (taxpayer identification number/business identification number)",
  invoice_data_number: "Document number",
  invoice_data_date: "Date of issue",
  invoice_data_recipient: "Receiving organization",
  invoice_data_responsible: "Delivery responsible",
  invoice_data_organization: "Transport organization",
  invoice_data_consignment: "Consignment note",
  invoice_data_reset_button: "Reset changes",
  invoice_data_new_form_button: "Clear form",
  invoice_data_continue_button: "Continue",
  // items
  add_item: "Add a data entry",
  remove_item: "Delete chosen rows",

  // step 4
  print_multiple_qr_title: "Confirm action",
  print_multiple_qr_content: "Are you sure you want to print QR codes one for each item?",
  print_multiple_qr_confirm: "Yes, print",
  print_multiple_qr_cancel: "No, go back",
  save_invoice_title: "Are you sure to save items?",
  save_invoice_content: "Photos of products can be added via the 'Add photos' function in the 'Registration' module",
  save_invoice_confirm: "Yes, save and close",
  save_invoice_cancel: "No, go back",
  step_4_title_1: "Delivery note №",
  step_4_title_2: "dated",
  step_4_title_3: "Products recorded:",
  step_4_title_qr: "Select a product to print and paste the QR code",
  step_4_print_single_qr: "Print one QR code for each item",
  step_4_save: "Save items lists",
  step_4_continue: "Continue",
  step_4_saved: "Products card saved successfully!",

  // step 5
  qr_print_in_progress: "QR codes are printing...",
  qr_print_count: "Print QR codes based on invoice quantity?",
  qr_print_multiple: "Yes, print multiple QR codes (available if quantity is whole number)",
  qr_print_single: "No, print one QR code for the entire position",
  qr_print_continue: "Continue",

  // step 6
  qr_ready_title: "Your QR codes are printed. Stick them on your products to complete the process.",
  qr_ready_continue: "Continue",
  product_saved: "Product card saved successfully!",
  product_saving: "Product card saving is in progress...",

  // invoice list
  save_without_photo_title: "Confirm action",
  save_without_photo_content: "Are you sure you want to save the invoice without adding photos?",
  save_without_photo_confirm: "Yes, save without photos",
  save_without_photo_cancel: "No, go back",
  choose_invoice_title: "Select the invoice to add photos or save it without photos",
  save_without_photo_button: "Save without photos",
  add_photo_button: "Add photos",

  // add photo
  photo: "Photo",
  absence: "Absent",
  presence: "Present",
  save_photo_modal_title: "Confirm action",
  save_photo_content: "Are you sure you want to save the changes?",
  save_photo_confirm: "Yes, save",
  save_photo_cancel: "No, go back",
  save_photo_title: "Add photos for the required products",
  save_photo_buttom: "Save",
};
