import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { TableProps } from "antd";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { getInvoices } from "api/registry";
import { useMainContext } from "state/main-context";
import {
  emptyRegistryInvoiceResponse,
  RegistryInvoiceItem,
  RegistryInvoiceResponse,
} from "types/registry/inventory-types";
import { useNotification } from "state/notification-context";
import TableLayout from "components/table/table-layout";
import { ActiveFilters } from "types/ui/table-filter-types";
import { useTranslation } from "react-i18next";

export default function InvoicesList() {
  const { setInvoiceItem } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const activeFilters: ActiveFilters = {
    date_filter: true,
    id_filter: false,
    name_filter: false,
    sender_filter: true,
    iin_filter: true,
    qr_filter: false,
    category_filter: true,
    batch_filter: false,
    source_filter: true,
    filial_filter: true,
    location_filter: false,
    reason_filter: false,
    user_writeoff_filter: false,
    responsible_filter: true,
  };

  const [isTableCollapsed, setTableCollapsed] = useState<boolean>(true);

  const [registryItems, setRegistryItems] = useState<RegistryInvoiceResponse>(emptyRegistryInvoiceResponse);

  const getInvoicesData = async (params: URLSearchParams) => {
    try {
      const res: RegistryInvoiceResponse = await getInvoices(params);
      setRegistryItems(res);
      return res.invoices;
    } catch (error: any) {
      let err = error?.response?.data?.detail;
      showNotification("error", t("notifications.data_error"), err);
      return [];
    }
  };

  const onOpenDetails = (item: any) => {
    setInvoiceItem(item);
    navigate(`/registry/invoices/${item.id_invoice}`);
  };

  const toggleTableCollapsing = () => {
    setTableCollapsed(!isTableCollapsed);
  };

  const invoiceColumns: TableProps<RegistryInvoiceItem>["columns"] = [
    {
      title: t("table.invoice_number"),
      dataIndex: "invoice_number",
      render: (value) => (value !== null ? value : "-"),
      width: 110,
      fixed: window.innerWidth > 640 ? "left" : false,
      sorter: { multiple: 1 },
    },
    {
      title: t("table.invoice_date"),
      dataIndex: "invoice_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.invoice_registration_date"),
      dataIndex: "registration_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.sender"),
      dataIndex: "sender",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.iin_bin"),
      dataIndex: "iin_bin",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.recipient"),
      dataIndex: "recipient",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.delivery_responsible"),
      dataIndex: "delivery_responsible",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.transport_organization"),
      dataIndex: "transport_organization",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.item_transport_invoice"),
      dataIndex: "item_transport_invoice",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
  ];

  const ItemsNode = (
    <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <TableLayout
        dataSource={registryItems.invoices}
        dataLength={registryItems.total_invoices}
        columns={invoiceColumns}
        selection={true}
        selectionType="radio"
        rowKey="id_invoice"
        scroll={{ x: isTableCollapsed ? 1500 : 2000 }}
        activeFilters={activeFilters}
        showInvoiceBtn
        showActionBtn
        showCollapseBtn
        isTableCollapsed={isTableCollapsed}
        updateData={getInvoicesData}
        toggleTableCollapsing={toggleTableCollapsing}
        action={onOpenDetails}
        actionText={t("registry.details_title")}
      />
    </div>
  );

  const ItemsData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("registry.header")}
        <IconArrowNarrowRight />
        {t("registry.invoices")}
      </div>
    ),
    node: ItemsNode,
  };

  return <StageLayout stage={ItemsData} />;
}
