export const roles = {
  header: "Роли и доступы",
  title: "Список ролей и их доступов",
  add_role: "Добавить роль",
  role_column: "Роль",
  action_column: "Действия",
  edit_button: "Редактировать",
  registration_column: "Регистрация",
  stock_count_column: "Инвентаризация",
  locations_column: "Локации",
  reasons_column: "Причины списания",
  employees_column: "Сотрудники",
  moving_column: "Перемещение",
  roles_column: "Список ролей",
  dashboard_column: "Отчёты",
  writeoff_column: "Списание",
  registry_column: "Реестр",
  logs_column: "Доступ к логам",
  scan_qr_column: "Сканирование QR",
  print_qr_column: "Повторная печать QR",
  batch_column: "Партии",
  filial_column: "Доступ к филиалам",
  // new role
  edit_title: "Редактировать",
  new_title: "Новая роль",
  role_name: "Название роли",
  cancel: "Отмена",
  save: "Сохранить",
};
