import { auth_page } from "./locale_auth_page";
import { batches } from "./locale_batches";
import { dashboard } from "./locale_dashboard";
import { directories } from "./locale_directories";
import { employees } from "./locale_employees";
import { error_page } from "./locale_error_page";
import { header } from "./locale_header";
import { inputs } from "./locale_inputs";
import { inventory } from "./locale_inventory";
import { locations } from "./locale_locations";
import { main } from "./locale_main";
import { moving } from "./locale_moving";
import { notifications } from "./locale_notifications";
import { photo } from "./locale_photo";
import { print_again } from "./locale_print_again";
import { reasons } from "./locale_reasons";
import { register } from "./locale_register";
import { registry } from "./locale_registry";
import { roles } from "./locale_roles";
import { scan_from_main } from "./locale_scan_from_main";
import { table } from "./locale_table";
import { validations } from "./locale_validations";

export const i18n_ru = {
  translation: {
    auth_page,
    header,
    main,
    table,
    register,
    moving,
    inventory,
    registry,
    dashboard,
    scan_from_main,
    print_again,
    employees,
    directories,
    locations,
    reasons,
    roles,
    batches,
    photo,
    inputs,
    validations,
    notifications,
    error_page,
  },
};
