import { useNavigate } from "react-router-dom";
import { CategoryType } from "types/ui/category-types";

export default function MenuTiles({ tiles }: { tiles: CategoryType[] }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center grow md:mx-12 lg:mx-16">
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-8 lg:gap-12 h-full md:h-fit">
        {tiles.map((tile) => (
          <div
            className="shadow rounded-xl md:rounded-2xl lg:rounded-3xl bg-white cursor-pointer"
            onClick={() => navigate(`${tile.url}`)}
            key={tile.id}
          >
            <div className="flex flex-col items-center justify-center gap-2 h-full md:h-48 lg:h-60">
              {tile.icon}
              <span className="text-lg md:text-xl font-bold">{tile.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
