import { useState } from "react";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { TableProps } from "antd";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { getItems } from "api/registry";
import { useMainContext } from "state/main-context";
import { emptyRegistryItemResponse, RegistryItem, RegistryItemResponse } from "types/registry/item-types";
import { useNotification } from "state/notification-context";
import { useNavigate } from "react-router-dom";
import { ActiveFilters } from "types/ui/table-filter-types";
import TableLayout from "components/table/table-layout";
import { useTranslation } from "react-i18next";

export default function ItemsList() {
  const { setDetailedItem } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const activeFilters: ActiveFilters = {
    date_filter: true,
    id_filter: true,
    name_filter: true,
    sender_filter: false,
    iin_filter: false,
    qr_filter: true,
    category_filter: true,
    batch_filter: true,
    source_filter: true,
    filial_filter: true,
    location_filter: true,
    reason_filter: false,
    user_writeoff_filter: false,
    responsible_filter: true,
  };

  const [isTableCollapsed, setTableCollapsed] = useState<boolean>(true);

  const [registryItems, setRegistryItems] = useState<RegistryItemResponse>(emptyRegistryItemResponse);

  const getItemsData = async (params: URLSearchParams) => {
    try {
      const res: RegistryItemResponse = await getItems(params);
      setRegistryItems(res);
      return res.items;
    } catch (error: any) {
      let err = error?.response?.data?.detail;
      showNotification("error", t("notifications.data_error"), err);
      return [];
    }
  };

  const onOpenDetails = (item: any) => {
    setDetailedItem(item);
    navigate(`/registry/items/${item.id_item}`);
  };

  const toggleTableCollapsing = () => {
    setTableCollapsed(!isTableCollapsed);
  };

  const itemColumns: TableProps<RegistryItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      width: 155,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      width: 185,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },

    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity_released",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity_checked"),
      dataIndex: "quantity_checked",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.stock_date"),
      dataIndex: "stock_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.vat"),
      dataIndex: "vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.amortisation_value"),
      dataIndex: "amortisation_value",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },

    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.stock_count_auditor"),
      dataIndex: "inventory_responsible",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_number"),
      dataIndex: "invoice_number",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_date"),
      dataIndex: "invoice_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },

    {
      title: t("table.iin_bin"),
      dataIndex: "iin_bin",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.sender"),
      dataIndex: "sender",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.recipient"),
      dataIndex: "recipient",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
  ];

  const ItemsNode = (
    <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <TableLayout
        dataSource={registryItems.items}
        dataLength={registryItems.total_items}
        columns={itemColumns}
        selection={true}
        selectionType="radio"
        rowKey="id_stock"
        scroll={{ x: isTableCollapsed ? 3000 : 3600 }}
        activeFilters={activeFilters}
        showInvoiceBtn
        showItemBtn
        showActionBtn
        showCollapseBtn
        isTableCollapsed={isTableCollapsed}
        updateData={getItemsData}
        toggleTableCollapsing={toggleTableCollapsing}
        action={onOpenDetails}
        actionText={t("registry.item_details_title")}
      />
    </div>
  );

  const ItemsData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("registry.header")}
        <IconArrowNarrowRight />
        {t("registry.items")}
      </div>
    ),
    node: ItemsNode,
  };

  return <StageLayout stage={ItemsData} />;
}
