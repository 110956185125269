export interface EmployeeData {
  id_employee: number;
  name_surname: string;
  position: string;
  gender: string;
  phone: string;
  email: string;
  department: string;
  employee_status: boolean;
  id_location: number;
}

export interface Employee extends EmployeeData {
  location_name: string;
  role_name: string;
  filial: string;
}

export interface EmployeeUserData extends Employee {
  login: string;
  password: string;
  role_name: string;
  user_status: boolean;
}

export interface EmployeeFields extends EmployeeUserData {
  is_user: boolean;
}

export const emptyEmployee: Employee = {
  id_employee: 0,
  name_surname: "",
  position: "",
  gender: "",
  phone: "",
  email: "",
  department: "",
  id_location: 0,
  employee_status: true,
  location_name: "",
  role_name: "",
  filial: "",
};

export const emptyEmployeeUserData: EmployeeUserData = {
  id_employee: 0,
  name_surname: "",
  position: "",
  gender: "",
  phone: "",
  email: "",
  department: "",
  id_location: 0,
  employee_status: true,
  role_name: "",
  login: "",
  password: "",
  user_status: true,
  location_name: "",
  filial: "",
};
