import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Image,
  Input,
  Modal,
  Select,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  TableProps,
  TimeRangePickerProps,
} from "antd";
import QrScannerComponent from "components/qr-scanner";
import { IconFileTypePdf, IconFilter, IconPhoto, IconQrcode } from "@tabler/icons-react";
import ExportTable from "components/registry/export-to-xlsx";
import { useNotification } from "state/notification-context";
import { getImages } from "api/moving";
import { FilterOptionsTypes, getOptions, StringOption } from "helpers/options-list";
import { formatExcel } from "helpers/format-excel";
import { useLocation } from "react-router-dom";
import { ActiveFilters } from "types/ui/table-filter-types";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";
import { LocationType } from "types/location-types";
import dayjs from "dayjs";
import { useMainContext } from "state/main-context";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function TableLayout({
  title,
  dataSource,
  dataLength,
  columns,
  selection,
  selectionType,
  rowKey,
  scroll,
  pagination,
  disabledRows,
  activeFilters,
  showInvoiceBtn,
  showItemBtn,
  showActionBtn,
  showCollapseBtn,
  isTableCollapsed,
  updateData,
  toggleTableCollapsing,
  action,
  actionText,
}: {
  title?: ReactNode;
  dataSource: any[];
  dataLength: number;
  columns: TableColumnsType<any>;
  selection?: boolean;
  selectionType?: "checkbox" | "radio";
  rowKey: string;
  scroll?: { x?: number; y?: number };
  pagination?: false | TablePaginationConfig | undefined;
  disabledRows?: React.Key[];
  activeFilters: ActiveFilters;
  showInvoiceBtn?: boolean;
  showItemBtn?: boolean;
  showActionBtn?: boolean;
  showCollapseBtn?: boolean;
  isTableCollapsed?: boolean;
  updateData: (params: URLSearchParams) => Promise<any[]>;
  toggleTableCollapsing?: () => void;
  action?: (item: any) => void;
  actionText?: string;
}) {
  const { user } = useMainContext();
  const { setLoadingScreen } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const location = useLocation();

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentLimit, setCurrentLimit] = useState<number>(10);

  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const [options, setOptions] = useState<FilterOptionsTypes>();

  const [locationOptions, setLocationOptions] = useState<StringOption[]>([]);

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [isFiltersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 279;
    } else if (width < 768) {
      return height - 295;
    } else if (width < 1024) {
      return height - 291;
    } else return height - 347;
  }, [window.innerWidth]);

  useEffect(() => {
    getQueryValues();
    getData(currentLimit, 0);
  }, []);

  const formatLocations = (locations: LocationType[]) => {
    const locationOptions: StringOption[] = locations
      .map((item) => ({
        label: item.location_name,
        value: item.location_name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    return locationOptions;
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: t("dashboard.last_7_days"), value: [dayjs().add(-7, "d"), dayjs()] },
    { label: t("dashboard.last_30_days"), value: [dayjs().add(-30, "d"), dayjs()] },
  ];

  const openQrScannerModal = () => {
    setIsQrModalOpen(true);
  };

  const closeQrScannerModal = () => {
    setIsQrModalOpen(false);
  };

  const setScannedQr = (id: string) => {
    appendFilter("id_item", id);
    getData();
    closeQrScannerModal();
  };

  const appendFilter = (key: string, value: string) => {
    if (key !== "limit" && key !== "offset") {
      searchParams.set("limit", "10");
      searchParams.delete("offset");
    }

    if (searchParams.has(key)) {
      searchParams.set(key, value);
    } else {
      searchParams.append(key, value);
    }
  };

  const getData = async (limit?: number, offset?: number, params = searchParams) => {
    setTableLoading(true);

    if (limit !== undefined) {
      params.set("limit", limit.toString());
    }
    if (offset !== undefined) {
      params.set("offset", offset.toString());
    }

    await updateData(new URLSearchParams(params)).finally(() => {
      setTableLoading(false);
    });
  };

  const getAllData = async () => {
    let initialLimit = currentLimit;
    appendFilter("limit", dataLength.toString());
    searchParams.delete("offset");

    return new Promise<any[]>(async (resolve) => {
      let res = await updateData(searchParams);
      appendFilter("limit", initialLimit.toString());
      formatExcel(res, location.pathname).then((formattedData) => {
        resolve(formattedData);
      });
    });
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const getQueryValues = async () => {
    setTableLoading(true);

    await getOptions()
      .then((res) => {
        setOptions(res);
        setLocationOptions(res.locations);
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const handleSearchChange = (filterName: string | string[], value: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name, index) => {
        value[index] !== undefined && appendFilter(name, value[index]);
      });
    } else {
      value !== undefined && !Array.isArray(value) && appendFilter(filterName, value);
    }

    timeoutId && clearTimeout(timeoutId);

    if (
      !Array.isArray(filterName) &&
      filterName !== "from_date" &&
      filterName !== "to_date" &&
      filterName !== "user_writeoff" &&
      filterName !== "name_surname" &&
      filterName !== "location_name" &&
      filterName !== "category" &&
      filterName !== "source" &&
      filterName !== "filial"
    ) {
      const newTimeoutId = window.setTimeout(() => {
        getData();
      }, 1000);

      setTimeoutId(newTimeoutId);
    } else {
      getData();
    }

    setCurrentPage(1);
  };

  const handleClearSearch = (filterName: string | string[]) => {
    if (Array.isArray(filterName)) {
      filterName.forEach((name) => {
        searchParams.delete(name);
      });
    } else {
      searchParams.delete(filterName);
    }

    getData();
  };

  const clearAllFilters = () => {
    searchParams.delete("offset");
    searchParams.delete("from_date");
    searchParams.delete("to_date");
    searchParams.delete("item_name");
    searchParams.delete("name_surname");
    searchParams.delete("location_name");
    searchParams.delete("category");
    searchParams.delete("source");
    searchParams.delete("filial");

    getData();
  };

  const handleActionButtonClick = (item: any) => {
    action && action(item);
  };

  const onTableChange: TableProps["onChange"] = (pagination, filters, sorter, extra) => {
    const updatedParams = new URLSearchParams(searchParams);

    // Handle pagination
    if (pagination) {
      setCurrentPage(pagination.current || 1);
      setCurrentLimit(pagination.pageSize || 10);

      const newOffset = ((pagination.current || 1) - 1) * (pagination.pageSize || 10);
      updatedParams.set("limit", (pagination.pageSize || 10).toString());
      updatedParams.set("offset", newOffset.toString());
    }

    // Handle sorting
    if (!Array.isArray(sorter)) {
      if (sorter.order) {
        updatedParams.set("sort_by", `${sorter.field}:${sorter.order === "ascend" ? "asc" : "desc"}`);
      } else {
        updatedParams.delete("sort_by");
        searchParams.delete("sort_by");
      }
    } else {
      updatedParams.delete("sort_by");
      searchParams.delete("sort_by");

      let reversedSorter = sorter.reverse();
      reversedSorter.forEach((sort) => {
        updatedParams.append("sort_by", `${sort.field}:${sort.order === "ascend" ? "asc" : "desc"}`);
      });
    }

    // Update searchParams with the new params using Array.from()
    Array.from(updatedParams.entries()).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    getData(undefined, undefined, updatedParams);
  };

  const rowSelection: TableProps["rowSelection"] = {
    type: selectionType,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows && setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: false,
      name: record.name,
    }),
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-3">
        <div className={`flex flex-col lg:flex-row items-center ${title ? "justify-between" : "justify-end"}`}>
          {title ? title : undefined}
          <div className="flex flex-wrap md:flex-nowrap items-center gap-3">
            <Button className="shadow grow" icon={<IconFilter />} onClick={() => setFiltersModalOpen(true)}>
              {t("inputs.search_title")}
            </Button>

            {showInvoiceBtn && (
              <Button
                className="shadow"
                icon={<IconFileTypePdf size={20} />}
                onClick={() => getItemImages("invoices", selectedRows[0].id_invoice)}
                disabled={!selectedRows.length}
              ></Button>
            )}

            {showItemBtn && (
              <Button
                className="shadow"
                icon={<IconPhoto size={20} />}
                onClick={() => getItemImages("items", selectedRows[0].id_item)}
                disabled={!selectedRows.length}
              ></Button>
            )}

            {(showInvoiceBtn || showItemBtn) && (
              <div className="hidden">
                <Image.PreviewGroup
                  items={itemImages}
                  preview={{
                    visible: previewVisible,
                    destroyOnClose: true,
                    imageRender: (original, info) => (
                      <div className="relative flex max-h-[70%]">
                        {original}
                        <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                          {translatePhotoName(itemImages[info.current]?.name)}
                        </div>
                      </div>
                    ),
                    onVisibleChange: (value) => {
                      setPreviewVisible(value);
                      !value && setItemImages([]);
                    },
                  }}
                >
                  <Image />
                </Image.PreviewGroup>
              </div>
            )}

            {location.pathname.includes("registry") && <ExportTable getAllData={getAllData} />}

            {showCollapseBtn && (
              <Button className="shadow grow" onClick={toggleTableCollapsing}>
                {isTableCollapsed ? t("table.expand_table") : t("table.collapse_table")}
              </Button>
            )}

            {showActionBtn && (
              <Button
                className="shadow grow text-white bg-green-600"
                variant="solid"
                onClick={() => handleActionButtonClick(selectedRows[0])}
                disabled={!selectedRows.length}
              >
                {actionText}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Table
        bordered
        size="small"
        className="grow"
        dataSource={dataSource}
        columns={columns}
        rowSelection={selection ? rowSelection : undefined}
        rowKey={(_, index) => (index || 0).toString()}
        loading={isTableLoading}
        scroll={{ x: scroll?.x, y: table_height }}
        pagination={
          pagination !== false && {
            disabled: isTableLoading,
            total: dataLength,
            current: currentPage,
            pageSize: currentLimit,
          }
        }
        onChange={onTableChange}
      />
      <QrScannerComponent isModalOpen={isQrModalOpen} onScan={setScannedQr} closeModal={closeQrScannerModal} />
      <Modal
        width={400}
        title={t("inputs.search_title")}
        open={isFiltersModalOpen}
        onCancel={() => setFiltersModalOpen(false)}
        onOk={() => setFiltersModalOpen(false)}
        footer={(_, { OkBtn }) => (
          <>
            <Button onClick={clearAllFilters}>{t("inputs.search_reset")}</Button>
            <OkBtn />
          </>
        )}
        keyboard={false}
        centered
      >
        <div className="flex flex-col gap-3">
          {activeFilters.date_filter && (
            <DatePicker.RangePicker
              value={[
                searchParams.get("from_date") ? dayjs(searchParams.get("from_date")) : null,
                searchParams.get("to_date") ? dayjs(searchParams.get("to_date")) : null,
              ]}
              format={{
                format: "YYYY-MM-DD",
                type: "mask",
              }}
              presets={rangePresets}
              maxDate={dayjs()}
              allowEmpty={[false, true]}
              onChange={(dates, dateStrings) => {
                if (dates && dateStrings[1] === "") {
                  handleSearchChange(
                    ["from_date", "to_date"],
                    [dateStrings[0], new Date().toISOString().split("T")[0]]
                  );
                } else if (dates && dateStrings[1] !== "") {
                  handleSearchChange(["from_date", "to_date"], dateStrings);
                } else {
                  handleClearSearch(["from_date", "to_date"]);
                }
              }}
            />
          )}

          {activeFilters.id_filter && (
            <Input
              placeholder={t("inputs.search_esep_id")}
              value={searchParams.get("id_item") || ""}
              onChange={(e) => handleSearchChange("id_item", e.target.value)}
              onClear={() => handleClearSearch("id_item")}
              allowClear
            />
          )}

          {activeFilters.name_filter && (
            <Input
              placeholder={t("inputs.search_name")}
              value={searchParams.get("item_name") || ""}
              onChange={(e) => handleSearchChange("item_name", e.target.value)}
              onClear={() => handleClearSearch("item_name")}
              allowClear
            />
          )}

          {activeFilters.user_writeoff_filter && (
            <Select
              placeholder={t("inputs.search_user_writeoff")}
              value={searchParams.get("user_writeoff")}
              options={options?.all_employees}
              onChange={(value) => value && handleSearchChange("user_writeoff", value)}
              onClear={() => handleClearSearch("user_writeoff")}
              allowClear
              showSearch
            />
          )}

          {activeFilters.responsible_filter && (
            <Select
              placeholder={t("inputs.search_mrp")}
              value={searchParams.get("name_surname")}
              options={options?.all_employees}
              onChange={(value) => value && handleSearchChange("name_surname", value)}
              onClear={() => handleClearSearch("name_surname")}
              allowClear
              showSearch
            />
          )}

          {activeFilters.sender_filter && (
            <Input
              placeholder={t("inputs.search_sender")}
              value={searchParams.get("sender") || ""}
              onChange={(e) => handleSearchChange("sender", e.target.value)}
              onClear={() => handleClearSearch("sender")}
              allowClear
            />
          )}

          {activeFilters.iin_filter && (
            <Input
              placeholder={t("inputs.search_iin")}
              value={searchParams.get("iin_bin") || ""}
              onChange={(e) => handleSearchChange("iin_bin", e.target.value)}
              onClear={() => handleClearSearch("iin_bin")}
              allowClear
            />
          )}

          {activeFilters.location_filter && (
            <Select
              placeholder={t("inputs.search_location")}
              value={searchParams.get("location_name")}
              options={locationOptions}
              onChange={(value) => value && handleSearchChange("location_name", value)}
              onClear={() => handleClearSearch("location_name")}
              allowClear
              showSearch
            />
          )}

          {activeFilters.category_filter && (
            <Select
              placeholder={t("inputs.search_category")}
              value={searchParams.get("category")}
              options={options?.categories}
              onChange={(value) => value && handleSearchChange("category", value)}
              onClear={() => handleClearSearch("category")}
              allowClear
              showSearch
            />
          )}

          {activeFilters.source_filter && (
            <Select
              placeholder={t("inputs.search_source")}
              value={searchParams.get("source")}
              options={options?.sources}
              onChange={(value) => value && handleSearchChange("source", value)}
              onClear={() => handleClearSearch("source")}
              allowClear
              showSearch
            />
          )}

          {activeFilters.filial_filter && (
            <Select
              placeholder={t("inputs.search_filial")}
              value={searchParams.get("filial")}
              options={
                user.role_permissions.access_filials
                  ? options?.filials
                  : options?.filials.filter((filial) => filial.label === user.filial)
              }
              onChange={(value) => {
                if (value) {
                  appendFilter("filial", value);
                  handleClearSearch("location_name");
                  setLocationOptions(
                    formatLocations(
                      options ? options.locationsList.filter((location) => location.filial === value) : []
                    )
                  );
                }
              }}
              onClear={() => handleClearSearch(["filial", "location_name"])}
              allowClear
              showSearch
            />
          )}

          {activeFilters.qr_filter && (
            <Button icon={<IconQrcode />} onClick={openQrScannerModal}>
              {t("inputs.search_qr")}
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
}
