import { IconArrowNarrowRight, IconFileTypePdf, IconPhoto } from "@tabler/icons-react";
import { Alert, Badge, Button, Image, Spin, Table, TableProps } from "antd";
import StageLayout from "components/layout/page-layout";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasketItem, emptyBasketItems } from "types/inventory-types";
import { StageType } from "types/stage-types";
import { useMainContext } from "state/main-context";
import { deleteFromBasket, getBasket, checkInventoryPrintQr } from "api/inventory";
import { BasketItems } from "types/inventory-types";
import { getImages } from "api/moving";
import { useNotification } from "state/notification-context";
import { getQrCode } from "api/registration";
import { useTranslation } from "react-i18next";
import compactNumber from "helpers/compact-number";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function Basket() {
  const { OsType, user, setLoadingScreen, setItemInventory } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isPrintingFor1c, setPrintingFor1c] = useState<boolean>(false);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [itemsList, setItemsList] = useState<BasketItems>(emptyBasketItems);
  const [selectedItems, setSelectedItems] = useState<BasketItem[]>([]);

  const [searchParams] = useState<URLSearchParams>(new URLSearchParams());

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  
  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 431) {
    //   return height - 522;
    // } else if (width < 441) {
    //   return height - 494;
    // } else if (width < 463) {
    //   return height - 450;
    // } else if (width < 482) {
    //   return height - 428;
    } else if (width < 640) {
      return height - 392;
    // } else if (width < 718) {
    //   return height - 415;
    } else if (width < 768) {
      return height - 371;
    // } else if (width < 849) {
    //   return height - 400;
    // } else if (width < 942) {
    //   return height - 364;
    } else if (width < 1024) {
      return height - 332;
    // } else if (width < 1218) {
    //   return height - 427;
    } else return height - 403;
  }, [window.innerWidth]);

  const selected1cItems = useMemo(() => {
    return selectedItems.filter(
      (item) => item.status === null && (item.source.toLowerCase() === "1с" || item.source.toLowerCase() === "1c")
    );
  }, [selectedItems]);

  useEffect(() => {
    getItemsInBasket();
  }, []);

  const sumOfBasket = useMemo(() => {
    let sum = itemsList.items.reduce((sum, item) => {
      return sum + item.sum_with_vat;
    }, 0);

    return compactNumber(sum);
  }, [itemsList]);

  const getItemsInBasket = async () => {
    setTableLoading(true);

    await getBasket(user.user_id, searchParams)
      .then((res: BasketItems) => {
        setItemsList(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.basket_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const deleteItemsFromBasket = async () => {
    setTableLoading(true);

    let id_items = selectedItems.map((item) => item.id_item);
    console.log(id_items);

    await deleteFromBasket({ id_items, id_check_user: user.user_id })
      .then(() => {
        setSelectedItems([]);
        getItemsInBasket();
        showNotification("success", t("notifications.delete_from_basket_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.delete_from_basket_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const printFor1c = async () => {
    setPrintingFor1c(true);

    let mapped1cItems = selected1cItems.map((item) => ({
      "E-SEP ID": item.id_item,
      Наименование_характеристика: item.item_name,
      Источник: item.source,
      "Статус инвентаризации": item.status,
      id_user: user.user_id,
    }));

    await checkInventoryPrintQr(mapped1cItems)
      .then(async (res: any) => {
        await getQrCode({ qr_images: res });
        showNotification("success", t("notifications.qr_print_success"));
      })
      .catch((error: any) => {
        if (error.code === "ERR_NETWORK") {
          showNotification("error", t("notifications.qr_error"), t("notifications.qr_error_printer"));
        } else {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.qr_error"), err);
        }
      })
      .finally(() => setPrintingFor1c(false));
  };

  const startChecking = () => {
    setItemInventory(selectedItems[0]);
    navigate("/inventory/table-to-item/basket/check");
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const columns: TableProps<BasketItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.id_item - b.id_item,
      width: 160,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.name_surname.localeCompare(b.name_surname),
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.sum_with_vat - b.sum_with_vat,
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.filial.localeCompare(b.filial),
    },
    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: (a, b) => a.last_check_date?.localeCompare(b.last_check_date),
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.status?.localeCompare(b.status),
    },
  ];

  const rowSelection: TableProps<BasketItem>["rowSelection"] = {
    fixed: window.innerWidth > 640 ? "left" : false,
    type: "checkbox",
    selectedRowKeys: selectedItems.map((item) => item.id_stock),
    onChange: (selectedRowKeys: React.Key[], selectedRows: BasketItem[]) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record: BasketItem) => ({
      disabled: false, // Column configuration not to be checked
      name: record.item_name,
    }),
  };

  const BasketNode = (
    <>
      <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 grow">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3 border-b border-gray-200 pb-3">
              <h2 className="text-lg md:text-xl font-semibold text-center">{t("inventory.choose_item")}</h2>
              <div className="flex flex-wrap md:flex-nowrap items-center md:justify-end gap-3">
                <Button
                  className="shadow"
                  icon={<IconFileTypePdf size={20} />}
                  disabled={selectedItems.length !== 1}
                  onClick={() => getItemImages("invoices", selectedItems[0].id_invoice)}
                />

                <Button
                  className="shadow"
                  icon={<IconPhoto size={20} />}
                  disabled={selectedItems.length !== 1}
                  onClick={() => getItemImages("items", selectedItems[0].id_item)}
                />

                <Badge className="grow flex" count={selectedItems.length}>
                  <Button
                    className="shadow grow"
                    variant="solid"
                    color="danger"
                    disabled={!selectedItems.length}
                    onClick={deleteItemsFromBasket}
                  >
                    {t("inventory.delete_from_basket")}
                  </Button>
                </Badge>

                <Button
                  className="shadow grow"
                  variant="solid"
                  color="primary"
                  disabled={OsType !== "Desktop" || !selected1cItems.length}
                  onClick={printFor1c}
                >
                  {t("inventory.print_1c")}
                </Button>

                <Button
                  className="shadow grow text-white bg-green-600"
                  variant="solid"
                  disabled={selectedItems.length !== 1}
                  onClick={startChecking}
                >
                  {t("inventory.start_inventory")}
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-between flex-wrap gap-1 md:gap-3">
              <h4 className="text-base font-semibold">
                {t("inventory.total_1")} {itemsList.total_items} {t("inventory.total_2")} {sumOfBasket} KZT
              </h4>
              <Alert className="w-fit h-fit px-2 py-1" message={t("inventory.alert")} type="info" showIcon />
            </div>
          </div>

          <Table<BasketItem>
            size="small"
            className="grow"
            dataSource={itemsList.items}
            columns={columns}
            rowSelection={rowSelection}
            rowKey={"id_stock"}
            loading={isTableLoading}
            scroll={{ x: 2000, y: table_height }}
            bordered
            pagination={false}
          />
        </div>
      </div>
      <div className="hidden">
        <Image.PreviewGroup
          items={itemImages}
          preview={{
            visible: previewVisible,
            destroyOnClose: true,
            imageRender: (original, info) => (
              <div className="relative flex max-h-[70%]">
                {original}
                <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                  {translatePhotoName(itemImages[info.current]?.name)}
                </div>
              </div>
            ),
            onVisibleChange: (value) => {
              setPreviewVisible(value);
              !value && setItemImages([]);
            },
          }}
        >
          <Image />
        </Image.PreviewGroup>
      </div>
      <Spin spinning={isPrintingFor1c} size="large" fullscreen />
    </>
  );

  const emptyBasketNode = (
    <div className="content-center grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col items-center gap-1 md:gap-2 lg:gap-3">
        <h2 className="text-lg md:text-xl font-semibold">{t("inventory.basket_empty")}</h2>
        <Button className="shadow w-fit" type="primary" onClick={() => navigate(-1)}>
          {t("inventory.back_to_list")}
        </Button>
      </div>
    </div>
  );

  const BasketStageData: StageType = {
    id: 3,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("inventory.header")}
        <IconArrowNarrowRight />
        {t("inventory.table_to_item")}
        <IconArrowNarrowRight />
        {t("inventory.basket_header")}
      </div>
    ),
    node: !!itemsList.total_items ? BasketNode : emptyBasketNode,
  };

  return <StageLayout stage={BasketStageData} />;
}
