import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18n_ru } from "./ru";
import { i18n_en } from "./en";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

function getDefaults() {
  return {
    order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag"],
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupSessionStorage: "i18nextLng",
    caches: ["localStorage"],
    excludeCacheFor: ["cimode"],
  };
}

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ru: i18n_ru,
      en: i18n_en,
    },
    fallbackLng: "ru",
    detection: getDefaults(),
    supportedLngs: ["ru", "en"],
  });

export default i18n;
