import { useEffect, useState } from "react";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import DocumentForm from "components/register/document/document-form";
import { Modal, Spin } from "antd";
import { addToProcessQueue, getParseResult } from "api/registration";
import { useMainContext } from "state/main-context";
import { emptyScanDataDefaultValues, ScanDataDefaultValues, ScanDefaultValues } from "types/registration-types";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "@tabler/icons-react";

export default function EditScannedDocument({ setStep }: { setStep: (step: number) => void }) {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [defaultData, setDefaultData] = useState<ScanDataDefaultValues>(emptyScanDataDefaultValues);

  useEffect(() => {
    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");

    let isDocReady = JSON.parse(localStorage.getItem("document_is_ready") || "{}");
    isDocReady === true && setStep(4);

    if (savedRegister?.data?.iin_bin) {
      if (savedRegister.data.iin_bin === "000000000000") {
        setDefaultData(emptyScanDataDefaultValues);
        setLoading(false);
      } else {
        setDefaultData(savedRegister?.data);
        setLoading(false);
      }
    } else {
      addDocumentToProcess();
    }
  }, []);

  const addDocumentToProcess = async () => {
    try {
      await addToProcessQueue(user.id_5.toString());

      getParsedDocument();
    } catch (error: any) {
      let err = error?.response?.data?.detail;
      showNotification("error", t("notifications.parse_invoice_error"), err);

      setLoading(false);
    }
  };

  const getParsedDocument = async () => {
    try {
      let res: ScanDefaultValues = await getParseResult(user.id_5.toString());

      switch (res.status) {
        case "Processing":
          showNotification(
            "warning",
            t("notifications.parse_invoice_in_progress"),
            `${t("notifications.parse_queue_count")} ${res.tasks_in_queue}`
          );

          setTimeout(() => {
            getParsedDocument();
          }, 5000);

          break;

        case "Completed":
          showNotification("success", t("notifications.parse_invoice_success"));

          let parsedRegister = JSON.parse(localStorage.getItem("register") || "{}");
          let register_step_3 = { ...parsedRegister, data: res.data };

          localStorage.setItem("register", JSON.stringify(register_step_3));
          setDefaultData(res.data);

          setLoading(false);

          break;

        default:
          break;
      }
    } catch (error: any) {
      let err = error?.response?.data?.detail;
      showNotification("error", t("notifications.parse_invoice_error"), err);

      setLoading(false);
    }
  };

  const navigateToFourthStep = (data: ScanDataDefaultValues) => {
    let registerCategory = JSON.parse(localStorage.getItem("register") || "{}");
    let register_step_3 = { ...registerCategory, data: data };

    localStorage.setItem("register", JSON.stringify(register_step_3));

    setStep(4);
  };

  const thirdStageParsingNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">{t("register.parse_in_progress")}</h2>
      <Spin size="large"></Spin>
    </div>
  );

  const infoModal = () => {
    Modal.info({
      title: <span className="font-medium">{t("register.parsed_alert_title")}</span>,
      content: (
        <ol>
          <li>{t("register.parsed_alert_text_1")}</li>
          <li>{t("register.parsed_alert_text_2")}</li>
          <li>
            3. <strong>{t("register.parsed_alert_text_3_1")}</strong> {t("register.parsed_alert_text_3_2")}
          </li>
          {JSON.parse(localStorage.getItem("register") || "{}")?.category === "ОС" ? (
            <li>{t("register.parsed_alert_text_4")}</li>
          ) : undefined}
        </ol>
      ),
    });
  };

  const thirdStageNode = (
    <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-center gap-1">
          <h2 className="text-xl font-bold text-center uppercase">{t("register.parsed_invoice_title")}</h2>

          <IconInfoCircle className="text-primary cursor-pointer" onClick={infoModal} />
        </div>
        <DocumentForm defaultData={defaultData} submitDocument={navigateToFourthStep} />
      </div>
    </div>
  );

  const thirdStageData: StageType = {
    id: 1,
    title: t("register.header"),
    node: isLoading ? thirdStageParsingNode : thirdStageNode,
  };

  return <StageLayout stage={thirdStageData} />;
}
