import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { InventoryDashboardType } from "types/dashboard/inventory-dashboard-types";
import { useTranslation } from "react-i18next";

export default function InventorizatorBarChart({
  inventoryDashboardData,
}: {
  inventoryDashboardData: InventoryDashboardType | null;
}) {
  const { t } = useTranslation();

  const sortedData = useMemo(() => {
    return inventoryDashboardData?.quantity_by_user.sort((a, b) => b.quantity - a.quantity) || [];
  }, [inventoryDashboardData]);

  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: t("dashboard.inventory_by_inventarizator"),
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: sortedData?.map((item) => item.name_surname),
    },
  };
  return <ReactApexChart options={options} series={[{ data: sortedData.map((item) => item.quantity) }]} type="bar" />;
}
