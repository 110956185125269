const compactNumber = (number: number) => {
  let language = localStorage.getItem("i18nextLng");
  let langCode = language === "ru" ? "ru-RU" : "en-EN";

  return number.toLocaleString(langCode, {
    notation: "compact",
    compactDisplay: "short",
  });
};

export default compactNumber;
