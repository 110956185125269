import { useEffect, useState } from "react";
import { EmployeeFields, EmployeeUserData } from "types/employee-types";
import { Button, Checkbox, Form, FormProps, Input, Modal, Radio, Select } from "antd";
import { useNotification } from "state/notification-context";
import { Role } from "types/roles-types";
import { getRoles } from "api/roles";
import { NumberOption, StringOption } from "helpers/options-list";
import { getFilterCache, updateFilterCache } from "api/filter_cache";
import { LocationType } from "types/location-types";
import { useTranslation } from "react-i18next";
import { useMainContext } from "state/main-context";

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export default function NewEmployee({
  isModalOpen,
  data,
  closeModal,
  saveEmployeeData,
}: {
  isModalOpen: boolean;
  data: EmployeeUserData;
  closeModal: () => void;
  saveEmployeeData: (data: EmployeeUserData) => Promise<void>;
}) {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [fields, setFields] = useState<FieldData[]>([]);
  const [locations, setLocations] = useState<LocationType[]>([]);

  const [locationOptions, setLocationOptions] = useState<NumberOption[]>([]);
  const [filials, setFilials] = useState<StringOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<StringOption[]>([]);

  const [isUser, setUser] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getOptionsList();
    getRolesDict();
  }, []);

  useEffect(() => {
    form.resetFields();
    setLocationOptions(formatLocations(locations.filter((location) => location.filial === data.filial)));
    setUser(false);
    setFields([
      {
        name: "id_employee",
        value: data.id_employee,
      },
      {
        name: "name_surname",
        value: data.name_surname,
      },
      {
        name: "position",
        value: data.position,
      },
      {
        name: "gender",
        value: data.gender,
      },
      {
        name: "phone",
        value: data.phone,
      },
      {
        name: "filial",
        value: data.filial,
      },
      {
        name: "id_location",
        value: {
          label: data.location_name,
          value: data.id_location,
        },
      },
      {
        name: "department",
        value: data.department,
      },
      {
        name: "email",
        value: data.email,
      },
      {
        name: "employee_status",
        value: data.employee_status,
      },
      {
        name: "is_user",
        value: false,
      },
      {
        name: "role_name",
        value: data.role_name,
      },
      {
        name: "login",
        value: data.login,
      },
      {
        name: "password",
        value: data.password,
      },
      {
        name: "user_status",
        value: data.user_status !== null ? data.user_status : true,
      },
    ]);
  }, [data, closeModal]);

  const formatLocations = (locations: LocationType[]) => {
    const locationOptions: NumberOption[] = locations
      .map((item) => ({
        label: item.location_name,
        value: item.id_location,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    return locationOptions;
  };

  const getOptionsList = async () => {
    await getFilterCache()
      .then((res: { location_names: LocationType[]; filials: any[] }) => {
        setLocations(res.location_names.filter((location) => location.location_name !== "Списанные"));

        const filialOptions: StringOption[] = res.filials.map((item) => ({
          label: item,
          value: item,
        }));

        setFilials(
          user.role_permissions.access_filials
            ? filialOptions
            : filialOptions.filter((filial) => filial.label === user.filial)
        );
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      });
  };

  const getRolesDict = async () => {
    await getRoles()
      .then((roles_res: Role[]) => {
        let rolesOptions: StringOption[] = roles_res.map((item) => ({
          label: item.role,
          value: item.role,
        }));
        setRoleOptions(rolesOptions);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      });
  };

  const onFinish: FormProps<EmployeeFields>["onFinish"] = async (payloadData) => {
    setLoading(true);

    let locationId =
      // @ts-ignore
      typeof payloadData.id_location === "object" ? payloadData.id_location.value : payloadData.id_location;

    let employeePayload: EmployeeUserData = {
      id_employee: data.id_employee,
      name_surname: payloadData.name_surname,
      position: payloadData.position,
      gender: payloadData.gender,
      phone: payloadData.phone,
      id_location: locationId,
      filial: payloadData.filial,
      location_name: locations.find((location) => location.id_location === locationId)?.location_name || "",
      department: payloadData.department,
      email: payloadData.email,
      employee_status: payloadData.employee_status,
      login: payloadData.login,
      password: payloadData.password,
      role_name: payloadData.role_name,
      user_status: payloadData.user_status,
    };

    saveEmployeeData(employeePayload)
      .then(() => {
        form.resetFields();
        setFields([{ name: "employee_status", value: true }]);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.save_employee_error"), err);
      })
      .finally(() => {
        setLoading(false);
        setUser(false);
      });
  };

  return (
    <Modal
      title={data.id_employee ? t("employees.edit_employee") : t("employees.new_employee")}
      open={isModalOpen}
      footer={false}
      closable={false}
      centered
    >
      <Form
        form={form}
        fields={fields}
        onFinish={onFinish}
        disabled={isLoading}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        clearOnDestroy
        validateMessages={{
          required: t("validations.required"),
        }}
        autoComplete="off"
      >
        <Form.Item<EmployeeFields> name="name_surname" label={t("employees.name")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item<EmployeeFields> name="position" label={t("employees.position")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item<EmployeeFields> name="gender" label={t("employees.gender")} rules={[{ required: true }]}>
          <Select>
            <Select.Option value="Мужской">{t("employees.male")}</Select.Option>
            <Select.Option value="Женский">{t("employees.female")}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item<EmployeeFields> name="phone" label={t("employees.phone")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item<EmployeeFields> name="filial" label={t("employees.filial")} rules={[{ required: true }]}>
          <Select
            options={filials}
            onChange={(value) => {
              form.resetFields(["id_location"]);
              setLocationOptions(formatLocations(locations.filter((location) => location.filial === value)));
            }}
          />
        </Form.Item>
        <Form.Item<EmployeeFields> name="email" label={t("employees.email")}>
          <Input />
        </Form.Item>
        <Form.Item<EmployeeFields> name="department" label={t("employees.department")}>
          <Input />
        </Form.Item>
        <Form.Item<EmployeeFields> name="id_location" label={t("employees.location")} rules={[{ required: true }]}>
          <Select options={locationOptions} />
        </Form.Item>
        <Form.Item<EmployeeFields> name="employee_status" label={t("employees.employee_status")}>
          <Radio.Group disabled={!data.id_employee}>
            <Radio value={true} checked>
              {t("employees.active")}
            </Radio>
            <Radio value={false}>{t("employees.inactive")}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item<EmployeeFields> name="is_user" valuePropName="checked">
          <Checkbox value={isUser} onChange={(e) => setUser(e.target.checked)}>
            {data.login
              ? t("employees.edit_user")
              : data.id_employee
              ? t("employees.add_user")
              : t("employees.add_as_user")}
          </Checkbox>
        </Form.Item>

        <Form.Item<EmployeeFields>
          name="role_name"
          label={t("employees.role")}
          rules={[{ required: isUser ? true : false }]}
        >
          <Select options={roleOptions} disabled={!isUser} />
        </Form.Item>
        <Form.Item<EmployeeFields>
          name="login"
          label={t("employees.username")}
          rules={[{ required: isUser ? true : false }]}
        >
          <Input disabled={!isUser} />
        </Form.Item>
        <Form.Item<EmployeeFields>
          name="password"
          label={t("employees.password")}
          rules={[{ required: isUser ? true : false }]}
        >
          <Input.Password disabled={!isUser} />
        </Form.Item>
        <Form.Item<EmployeeFields> name="user_status" label={t("employees.user_status")}>
          <Radio.Group disabled={!isUser || !data.id_employee || data.user_status === null}>
            <Radio value={true} checked>
              {t("employees.active")}
            </Radio>
            <Radio value={false}>{t("employees.inactive")}</Radio>
          </Radio.Group>
        </Form.Item>

        <div className="flex items-center justify-end gap-3">
          <Button className="shadow" onClick={closeModal} loading={isLoading}>
            {t("employees.cancel")}
          </Button>
          <Button className="shadow" type="primary" htmlType="submit" loading={isLoading}>
            {t("employees.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
