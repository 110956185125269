export const registry = {
  header: "Registry",

  // type choice
  invoices: "Invoices",
  items: "Items",
  writeoff_items: "Written-off items",
  choose_type: "Select registry type",
  next: "Next",

  // export xlsx
  export_title: "Export data",
  export_content: "Export data from table?",

  // invoices
  invoice_title: 'Invoice #',
  details_title: 'Invoice details',

  // items
  item_details_title: "Item details",
};
