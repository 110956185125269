export interface Scan {
  base64_image: string;
  message: string;
}

export interface ScanImage {
  id: number;
  img: string;
}

export interface TableDefaultValues {
  row_number: number;
  name: string;
  nomenclature_number: string;
  unit_measurement: string;
  quantity: number;
  unit_price: number;
  total_with_vat: number;
  vat: number;
  lifecycle: number | undefined;
}

export interface TableWithPhoto extends TableDefaultValues {
  item_name: string;
  id_item: string;
  esep_id: string;
  photo: string;
}

export interface ScanDataDefaultValues {
  invoice_date: string;
  invoice_number: string;
  sender: string;
  recipient: string;
  delivery_responsible: string;
  transport_organization: string;
  iin_bin: string;
  item_transport_invoice: string;
  table_data: TableDefaultValues[];
}

export interface ScanDefaultValues {
  status: string;
  message: string;
  data: {
    invoice_date: string;
    invoice_number: string;
    sender: string;
    recipient: string;
    delivery_responsible: string;
    transport_organization: string;
    iin_bin: string;
    item_transport_invoice: string;
    table_data: TableDefaultValues[];
  };
  tasks_in_queue: number;
}

export interface ScanData {
  "Номер по порядку": string;
  "Наименование, характеристика": string;
  "Номенклатурный номер": string;
  "Единица измерения": string;
  "Количество отпущено": string;
  "Цена за единицу, в KZT": string;
  "Сумма с НДС, в KZT": string;
  "Срок полезной службы": string;
}

export interface Document {
  id_5: string;
  user_id: number;

  form_data: {
    iin_bin: string;
    invoice_number: string;
    invoice_date: string;
    sender: string;
    recipient: string;
    delivery_responsible: string;
    transport_organization: string;
    item_transport_invoice: string;
    category: string;
    filial: string;
    table_data: string[][];
  };

  edited_data: ScanData[];
  employee_id: number;
  location_id: number;
}

// Empty types

export const emptyScanImage: ScanImage = {
  id: 0,
  img: "",
};

export const emptyTableDefaultValues: TableDefaultValues = {
  row_number: 0,
  name: "",
  nomenclature_number: "",
  unit_measurement: "",
  quantity: 0,
  unit_price: 0,
  total_with_vat: 0,
  vat: 0,
  lifecycle: 0,
};

export const emptyScanDataDefaultValues: ScanDataDefaultValues = {
  invoice_date: "",
  invoice_number: "",
  sender: "",
  recipient: "",
  delivery_responsible: "",
  transport_organization: "",
  iin_bin: "",
  item_transport_invoice: "",
  table_data: [],
};

export const emptyScanDefaultValues: ScanDefaultValues = {
  status: "",
  message: "",
  data: {
    invoice_date: "",
    invoice_number: "",
    sender: "",
    recipient: "",
    delivery_responsible: "",
    transport_organization: "",
    iin_bin: "",
    item_transport_invoice: "",
    table_data: [],
  },
  tasks_in_queue: 0,
};
