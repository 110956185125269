import { IconArrowNarrowRight, IconFileTypePdf, IconPhoto } from "@tabler/icons-react";
import { Alert, Button, Image, Input, Modal, Space, Spin, Table, TableProps } from "antd";
import { addImage, addInventory, updateItem } from "api/inventory";
import CameraCapture from "components/camera-capture";
import StageLayout from "components/layout/page-layout";
import QrScannerComponent from "components/qr-scanner";
import { useEffect, useMemo, useState } from "react";
import { useMainContext } from "state/main-context";
import { StageType } from "types/stage-types";
import { getImages } from "api/moving";
import { useNavigate } from "react-router-dom";
import { useNotification } from "state/notification-context";
import { getItems } from "api/registry";
import { RegistryItem, RegistryItemResponse } from "types/registry/item-types";
import { useTranslation } from "react-i18next";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function ItemToTable() {
  const navigate = useNavigate();

  const { user, setLoadingScreen } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [inventoryCheckData, setInventoryCheckData] = useState<RegistryItem[]>([]);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isItemSaving, setItemSaving] = useState<boolean>(false);

  const [checkStage, setCheckStage] = useState<number>(1);
  const [checkTitle, setCheckTitle] = useState<string>("");
  const [checkBtnText, setCheckBtnText] = useState<string>("");

  const [isItemFound, setIsItemFound] = useState<boolean | null>(null);
  const [isQrMatches, setIsQrMatches] = useState<boolean | null>(null);
  const [realQuantity, setRealQuantity] = useState<string>("0");

  const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
  const [isCameraActive, setCameraActive] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string | null>(null);

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  useEffect(() => {
    isQrMatches === false && confirmCompletion();
  }, [isQrMatches]);

  const totalQuantity = useMemo(() => {
    return inventoryCheckData.reduce((sum, item) => {
      return sum + item.quantity;
    }, 0);
  }, [inventoryCheckData]);

  const status: string = useMemo(() => {
    if (isQrMatches === false) return "QR код не соответствует";
    if (+realQuantity !== totalQuantity) return "Количество не соответствует";
    return "Соответствует";
  }, [isQrMatches, realQuantity, totalQuantity]);

  const completeInventory = async () => {
    setItemSaving(true);

    let data = {
      id_item: inventoryCheckData[0].id_item,
      quantity_checked: +realQuantity,
      id_check_user: user.user_id,
      status: status,
      method: "От товара к учету",
      action_name: `Инвентаризация: Статус - ${status}, Факт - ${realQuantity}`,
    };

    let photo_data = {
      id_item: inventoryCheckData[0].id_item,
      photo: (photo || "").replace("data:image/png;base64,", ""),
      action: `Инвентаризация_${user.name}_${status}`,
    };

    let promises = [await updateItem(inventoryCheckData[0].id_item, data), addInventory(data)];

    if (photo) {
      promises.unshift(await addImage(photo_data));
    }

    Promise.all(promises)
      .then(() => {
        setInventoryCheckData([]);
        setCheckStage(1);
        setIsItemFound(null);
        setIsQrMatches(null);
        setRealQuantity("0");
        setPhoto(null);

        setItemSaving(false);
        navigate("", { replace: true });
        showNotification("success", t("notifications.stock_count_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.stock_count_error"), err);
      })
      .finally(() => setItemSaving(false));
  };

  const openQrModal = () => {
    setIsQrModalOpen(true);
  };

  const closeQrScannerModal = () => {
    setIsQrModalOpen(false);
  };

  const checkScannedQr = async (id: string) => {
    closeQrScannerModal();

    setTableLoading(true);

    let searchParams = new URLSearchParams();
    searchParams.append("id_item", id);

    await getItems(searchParams)
      .then((res: RegistryItemResponse) => {
        if (res.items.length) {
          setIsItemFound(true);
          setInventoryCheckData(res.items);
          navigate("?found=true", { replace: true });
        } else {
          setIsItemFound(false);
          setInventoryCheckData([]);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  useMemo(() => {
    switch (checkStage) {
      case 1:
        if (isItemFound) {
          setCheckTitle(t("inventory.is_match"));
          setCheckBtnText(t("inventory.is_match_button"));
        } else if (isItemFound === false) {
          setCheckTitle(t("inventory.scan_qr_title"));
          setCheckBtnText(t("inventory.check_scan_again"));
        } else {
          setCheckTitle(t("inventory.scan_qr_title"));
          setCheckBtnText(t("inventory.check_scan_button"));
        }
        break;
      case 2:
        setCheckTitle(t("inventory.check_photo"));
        setCheckBtnText(t("inventory.check_save_button"));
        break;
      default:
        break;
    }
  }, [checkStage, isItemFound, photo]);

  const checkBtnFunc = async () => {
    switch (checkStage) {
      case 1:
        if (isItemFound) {
          setIsQrMatches(true);
          setCheckStage(2);
        } else {
          openQrModal();
        }
        break;
      case 2:
        if (photo) {
          Modal.confirm({
            centered: true,
            title: t("inventory.end_inventory"),
            cancelText: t("inventory.end_cancel"),
            okText: t("inventory.end_confirm"),
            onOk: completeInventory,
          });
        }
        break;
      default:
        break;
    }
  };

  const confirmCompletion = () => {
    Modal.confirm({
      centered: true,
      title: t("inventory.qr_mismatch_title"),
      content: t("inventory.qr_mismatch_content"),
      cancelText: t("inventory.cancel"),
      okText: t("inventory.confirm"),
      onCancel: () => setIsQrMatches(null),
      onOk: completeInventory,
    });
  };

  const columns: TableProps<RegistryItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value ? new Date(value).toLocaleDateString() : "-"),
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => (value !== null ? value : "-"),
    },
  ];

  const CheckNode = (
    <>
      <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3 w-full">
          <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3 border-b border-gray-200 pb-3">
            <h2 className="text-lg md:text-xl font-semibold">{checkTitle}</h2>
            <div className="flex flex-wrap md:flex-nowrap items-center gap-3">
              <Button
                className="shadow grow"
                icon={<IconFileTypePdf size={20} />}
                disabled={!isItemFound}
                onClick={() => getItemImages("invoices", inventoryCheckData[0].id_invoice)}
              >
                {t("photo.open_pdf")}
              </Button>
              <Button
                className="shadow grow"
                icon={<IconPhoto size={20} />}
                disabled={!isItemFound}
                onClick={() => getItemImages("items", inventoryCheckData[0].id_item)}
              >
                {t("photo.open_photo")}
              </Button>
            </div>
          </div>
          <Table<RegistryItem>
            size="small"
            dataSource={inventoryCheckData}
            columns={columns}
            rowKey={(_, index) => (index || 0).toString()}
            loading={isTableLoading}
            pagination={false}
          />
          {checkStage === 1 && isItemFound === false && (
            <Alert className="text-red-600" message={t("inventory.alert_not_found")} type="error" showIcon />
          )}
          {checkStage === 2 && (
            <>
              <div className="flex items-center gap-4 text-lg font-semibold">
                <span className="text-lg font-semibold">{t("inventory.quantity")}</span>
                <Input
                  className="max-w-32"
                  type="number"
                  min={0}
                  step={"any"}
                  value={realQuantity}
                  onChange={(e) => setRealQuantity(e.target.value)}
                />
              </div>
              <Space direction="vertical">
                <span className="text-lg font-semibold">{t("photo.title")}</span>
                {photo && <Image width={100} src={photo || undefined} />}
                <Button onClick={() => setCameraActive(true)}>
                  {photo ? t("photo.change_photo") : t("photo.add_photo")}
                </Button>
              </Space>
            </>
          )}
          <div className="flex items-center justify-end gap-3">
            {checkStage === 1 && isItemFound && (
              <Button className="shadow" variant="solid" color="danger" onClick={() => setIsQrMatches(false)}>
                {t("inventory.item_qr_mismatch")}
              </Button>
            )}
            <Button
              className="shadow text-white bg-green-600 active:!text-white active:!border-green-500 active:!bg-green-500 hover:!text-white hover:!border-green-500 hover:!bg-green-500"
              variant="solid"
              disabled={
                checkStage === 2 && (!photo || +realQuantity < 0 || isNaN(+realQuantity) || realQuantity === "")
              }
              onClick={checkBtnFunc}
            >
              {checkBtnText}
            </Button>
          </div>
        </div>
      </div>
      <QrScannerComponent isModalOpen={isQrModalOpen} onScan={checkScannedQr} closeModal={closeQrScannerModal} />
      <div className="hidden">
        <Image.PreviewGroup
          items={itemImages}
          preview={{
            visible: previewVisible,
            destroyOnClose: true,
            imageRender: (original, info) => (
              <div className="relative flex max-h-[70%]">
                {original}
                <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                  {translatePhotoName(itemImages[info.current]?.name)}
                </div>
              </div>
            ),
            onVisibleChange: (value) => {
              setPreviewVisible(value);
              !value && setItemImages([]);
            },
          }}
        >
          <Image />
        </Image.PreviewGroup>
      </div>
    </>
  );

  const savingNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">{t("inventory.inventory_in_progress")}</h2>
      <Spin size="large"></Spin>
    </div>
  );

  const CheckStageData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("inventory.header")}
        <IconArrowNarrowRight />
        {t("inventory.item_to_table")}
      </div>
    ),
    node: isItemSaving ? savingNode : CheckNode,
  };

  return (
    <>
      <StageLayout stage={CheckStageData} />
      <CameraCapture isCameraActive={isCameraActive} setCameraActive={setCameraActive} sendPhoto={setPhoto} />
    </>
  );
}
