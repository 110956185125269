import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { InventoryDashboardType } from "types/dashboard/inventory-dashboard-types";
import { useTranslation } from "react-i18next";

export default function InventoryMethodChart({
  inventoryDashboardData,
}: {
  inventoryDashboardData: InventoryDashboardType | null;
}) {
  const { t } = useTranslation();

  const writeOffReasonData = useMemo(() => {
    return inventoryDashboardData?.quantity_by_method.map((item) => item.quantity) || [];
  }, [inventoryDashboardData]);

  const options: ApexOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: t("dashboard.inventory_by_method"),
    },
    labels:
      inventoryDashboardData?.quantity_by_method.map((item) => (item.method === null ? "Не проверено" : item.method)) ||
      [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return <ReactApexChart options={options} series={writeOffReasonData} type="pie" />;
}
