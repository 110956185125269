export const batches = {
  header: "Партии",
  name_column: "Название",
  filial_column: "Филиал",
  status_column: "Статус",
  active: "Активный",
  inactive: "Неактивный",
  deactivate: "Деактивировать",
  activate: "Активировать",
  title: "Список партий",
  add_batch: "Добавить партию",
  // transfer
  transfer_alert_1: "1. Выберите товары в таблице для добавления в партию.",
  transfer_alert_2: "2. Выберите нужную партию в выпадающем меню справа.",
  transfer_alert_3: "3. Нажмите 'Добавить в партию'.",
  transfer_title: "Выберите товар для добавления в партию",
  transfer_add: "Добавить в партию",
  delete_title: "Подтвердите действие",
  delete_content: "Удалить выбранные товары из партий?",
  delete_cancel: "Отмена",
  delete_confirm: "Удалить",
  delete_from_batch: "Удалить из партии",
  choose_batch: "Выберите партию",
  // new batch
  new_batch_title: "Новая партия",
  cancel: "Отмена",
  save: "Сохранить",
};
