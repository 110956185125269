import { useState } from "react";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { TableProps } from "antd";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { getWriteOffs } from "api/registry";
import {
  emptyRegistryWriteOffResponse,
  RegistryWriteOff,
  RegistryWriteOffResponse,
} from "types/registry/write-off-types";
import { useNotification } from "state/notification-context";
import { ActiveFilters } from "types/ui/table-filter-types";
import TableLayout from "components/table/table-layout";
import { useTranslation } from "react-i18next";

export default function WriteOffsList() {
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const activeFilters: ActiveFilters = {
    date_filter: true,
    id_filter: true,
    name_filter: true,
    sender_filter: false,
    iin_filter: false,
    qr_filter: false,
    category_filter: true,
    batch_filter: true,
    source_filter: true,
    filial_filter: true,
    location_filter: false,
    reason_filter: true,
    user_writeoff_filter: true,
    responsible_filter: true,
  };

  const [isTableCollapsed, setTableCollapsed] = useState<boolean>(true);

  const [writeOffItems, setWriteOffItems] = useState<RegistryWriteOffResponse>(emptyRegistryWriteOffResponse);

  const getWriteOffsData = async (params: URLSearchParams) => {
    try {
      const res: RegistryWriteOffResponse = await getWriteOffs(params);
      setWriteOffItems(res);
      return res.items;
    } catch (error: any) {
      let err = error?.response?.data?.detail;
      showNotification("error", t("notifications.data_error"), err);
      return [];
    }
  };

  const toggleTableCollapsing = () => {
    setTableCollapsed(!isTableCollapsed);
  };

  const writeOffColumns: TableProps<RegistryWriteOff>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      width: 155,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      width: 185,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },

    {
      title: t("table.quantity_released"),
      dataIndex: "quantity_released",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.quantity_writeoff"),
      dataIndex: "quantity",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.writeoff_date"),
      dataIndex: "stock_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.writeoff_reason"),
      dataIndex: "reason",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.writeoff_user"),
      dataIndex: "user_writeoff",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
    },

    {
      title: t("table.sum_with_vat"),
      dataIndex: "sum_with_vat",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_number"),
      dataIndex: "invoice_number",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.invoice_date"),
      dataIndex: "invoice_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.iin_bin"),
      dataIndex: "iin_bin",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },

    {
      title: t("table.sender"),
      dataIndex: "sender",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.recipient"),
      dataIndex: "recipient",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
      sorter: { multiple: 1 },
      hidden: isTableCollapsed,
    },
  ];

  const ItemsNode = (
    <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <TableLayout
        dataSource={writeOffItems.items}
        dataLength={writeOffItems.total_items}
        columns={writeOffColumns}
        selection={true}
        selectionType="radio"
        rowKey="id_stock"
        scroll={{ x: isTableCollapsed ? 2000 : 2500 }}
        activeFilters={activeFilters}
        showInvoiceBtn
        showItemBtn
        showCollapseBtn
        isTableCollapsed={isTableCollapsed}
        updateData={getWriteOffsData}
        toggleTableCollapsing={toggleTableCollapsing}
      />
    </div>
  );

  const ItemsData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("registry.header")}
        <IconArrowNarrowRight />
        {t("registry.writeoff_items")}
      </div>
    ),
    node: ItemsNode,
  };

  return <StageLayout stage={ItemsData} />;
}
