import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Modal, Table, Tag } from "antd";
import type { TableProps } from "antd";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { emptyScanDataDefaultValues, ScanDataDefaultValues, TableWithPhoto } from "types/registration-types";
import { checkMultipleQrCode, getQrCode, saveInvoice } from "api/registration";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";

export default function InvoiceAfterScan({ setStep }: { setStep: (step: number) => void }) {
  const { user, setInvoice, setReactData } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [scanData, setScanData] = useState<ScanDataDefaultValues>(emptyScanDataDefaultValues);
  const [tableData, setTableData] = useState<TableWithPhoto[]>([]);
  const [category, setCategory] = useState<string>("");
  const [isOS, setOS] = useState<boolean>(false);

  const [isSaving, setSaving] = useState<boolean>(false);
  const [isInvoiceMapSaved, setInvoiceMapSaved] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");

    setScanData(savedRegister.data);
    setTableData(savedRegister?.data?.table_data);
    setCategory(savedRegister.category);
    savedRegister.category === "ОС" && setOS(true);
  }, []);

  const isSingleEsepIdBinded: boolean = useMemo(() => {
    return tableData?.some((obj) => obj.esep_id) ? true : false;
  }, [tableData]);

  const isEveryEsepIdBinded: boolean = useMemo(() => {
    return tableData?.every((obj) => obj.esep_id) ? true : false;
  }, [tableData]);

  const getMultipleQrCodes = async () => {
    const itemsForQr: any[] = [];

    tableData.forEach((item) => {
      let newItem = {
        "Номер по порядку": item.row_number,
        "Наименование, характеристика": item.name,
        "Номенклатурный номер": item.nomenclature_number,
        "Единица измерения": item.unit_measurement,
        "Количество отпущено": +item.quantity,
        "Цена за единицу, в KZT": item.unit_price,
        "Сумма с НДС, в KZT": item.total_with_vat,
        "Сумма НДС, в KZT": item.vat,
        "Срок полезной службы": item.lifecycle,
      };

      itemsForQr.push(newItem);
    });

    const dataForQr = {
      selected: itemsForQr,
      id_5: user.id_5.toString(),
      activator_toggle: false,
    };

    await checkMultipleQrCode(dataForQr)
      .then(async (res: any) => {
        await getQrCode({ qr_images: res.qr_images });
        showNotification("success", t("notifications.qr_print_success"));

        let dataWithQr = tableData.map((item, index) => ({
          ...item,
          esep_id: res.fats[index].id,
        }));

        let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");

        savedRegister.data.table_data = dataWithQr;

        localStorage.setItem("register", JSON.stringify(savedRegister));
        setTableData(dataWithQr);
      })
      .catch((error: any) => {
        if (error.code === "ERR_NETWORK") {
          showNotification("error", t("notifications.qr_error"), t("notifications.qr_error_printer"));
        } else {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.qr_error"), err);
        }
      });
  };

  const saveInvoiceMap = async () => {
    setSaving(true);

    let updatedTable = tableData.map((item) => {
      return {
        "Номер по порядку": item.row_number,
        "Наименование, характеристика": item.name,
        "Номенклатурный номер": item.nomenclature_number,
        "Единица измерения": item.unit_measurement,
        "Количество отпущено": +item.quantity,
        "Цена за единицу, в KZT": item.unit_price,
        "Сумма с НДС, в KZT": item.total_with_vat,
        "Сумма НДС, в KZT": item.vat,
        id_item: item.esep_id,
        "Срок полезной службы": item.lifecycle,
      };
    });

    await saveInvoice({
      id_5: user.id_5.toString(),
      user_id: user.user_id,
      form_data: {
        ...scanData,
        category: category,
        filial: user.filial,
      },
      edited_data: updatedTable,
      employee_id: user.id_employee,
      location_id: user.location_id,
    })
      .then(() => {
        localStorage.removeItem("register");
        localStorage.removeItem("in_process");
        localStorage.removeItem("document_is_ready");

        setInvoice([]);
        setReactData([]);

        setInvoiceMapSaved(true);
        showNotification("success", t("notifications.invoice_register_success"));

        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.save_invoice_error"), err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const rowSelection: TableProps<TableWithPhoto>["rowSelection"] = {
    type: "radio",
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableWithPhoto[]) => {
      setSelectedRowKeys(selectedRowKeys);

      localStorage.setItem("in_process", JSON.stringify(selectedRows));
      setReactData(selectedRows);
    },
    getCheckboxProps: (record: TableWithPhoto) => ({
      disabled: !!record.esep_id,
      name: record.name,
    }),
  };

  const { confirm } = Modal;

  const showQrConfirm = () => {
    confirm({
      width: "600px",
      title: t("register.print_multiple_qr_title"),
      content: t("register.print_multiple_qr_content"),
      okText: t("register.print_multiple_qr_confirm"),
      cancelText: t("register.print_multiple_qr_cancel"),
      onOk: () => getMultipleQrCodes(),
      keyboard: false,
      centered: true,
    });
  };

  const showConfirm = () => {
    confirm({
      width: "600px",
      title: t("register.save_invoice_title"),
      content: t("register.save_invoice_content"),
      okText: t("register.save_invoice_confirm"),
      cancelText: t("register.save_invoice_cancel"),
      cancelButtonProps: { disabled: isSaving },
      onOk: () => saveInvoiceMap(),
      keyboard: false,
      centered: true,
    });
  };

  const itemColumns: TableProps<TableWithPhoto>["columns"] = [
    {
      title: t("table.row_number"),
      dataIndex: "row_number",
    },
    {
      title: t("table.item_name"),
      dataIndex: "name",
    },
    {
      title: t("table.nomenclature_number"),
      dataIndex: "nomenclature_number",
    },
    {
      title: t("table.unit_measurement"),
      dataIndex: "unit_measurement",
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "total_with_vat",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.vat"),
      dataIndex: "vat",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      hidden: !isOS,
    },
    {
      title: "E-SEP ID",
      dataIndex: "esep_id",
      render: (esep_id: string) => <Tag color={esep_id ? "success" : "error"}>{esep_id || t("register.absence")}</Tag>,
    },
  ];

  const fourthStageNode = (
    <div className="flex flex-col gap-3 grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div>
        <h2 className="text-xl font-bold text-green-600">
          {t("register.step_4_title_1")}
          {scanData?.invoice_number} {t("register.step_4_title_2")}{" "}
          {new Date(scanData?.invoice_date).toLocaleDateString()}
        </h2>
        <h2 className="text-xl font-bold text-green-600">
          {t("register.step_4_title_3")} {scanData?.table_data?.length}
        </h2>
      </div>

      <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between flex-wrap gap-3">
        <div className="text-base font-medium">{t("register.step_4_title_qr")}</div>

        <div className="flex items-center flex-wrap gap-3">
          <Button className="shadow h-fit text-wrap" disabled={isSingleEsepIdBinded} onClick={showQrConfirm}>
            {t("register.step_4_print_single_qr")}
          </Button>

          <Button
            className="shadow"
            type="primary"
            disabled={!selectedRowKeys.length && !isEveryEsepIdBinded}
            onClick={() => (isEveryEsepIdBinded ? showConfirm() : setStep(5))}
          >
            {`${isEveryEsepIdBinded ? t("register.step_4_save") : t("register.step_4_continue")}`}
          </Button>
        </div>
      </div>

      <Table<TableWithPhoto>
        size="small"
        rowSelection={rowSelection}
        columns={itemColumns}
        dataSource={tableData}
        rowKey={(_, index) => (index || 0).toString()}
        bordered
        pagination={false}
      />
    </div>
  );

  const fourthStageSavingNode = (
    <div className="flex flex-col items-center justify-center gap-14 grow">
      <h2 className="text-2xl font-bold text-center">{t("register.step_4_saved")}</h2>
      <div className="flex items-center gap-32">
        <IconCircleCheckFilled className="fill-primary" size={200} />
      </div>
    </div>
  );

  const fourthStageData: StageType = {
    id: 1,
    title: t("register.header"),
    node: isInvoiceMapSaved ? fourthStageSavingNode : fourthStageNode,
  };

  return <StageLayout stage={fourthStageData} />;
}
