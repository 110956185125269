import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Button, Input, Table } from "antd";
import { TableDefaultValues } from "types/registration-types";
import { useTranslation } from "react-i18next";

const EditableTable: React.FC<{
  dataSource: TableDefaultValues[];
  setDataSource: React.Dispatch<React.SetStateAction<TableDefaultValues[]>>;
  resetToDefault: () => void;
  clearInputs: () => void;
  validateDocument: (e: any) => void;
  submittable: boolean;
  areAllValuesValid: boolean;
}> = ({ dataSource, setDataSource, resetToDefault, clearInputs, validateDocument, submittable, areAllValuesValid }) => {
  const { t } = useTranslation();
  const [itemsToDelete, setItemsToDelete] = useState<TableDefaultValues[]>([]);

  const [count, setCount] = useState<number>(1);
  const [isOS, setOS] = useState<boolean>(false);

  useEffect(() => {
    let savedRegister = JSON.parse(localStorage.getItem("register") || "{}");
    savedRegister.category === "ОС" && setOS(true);

    if (dataSource.length) {
      setCount(dataSource.length + 1);
    }
  }, [dataSource]);

  const handleAdd = () => {
    const newData: TableDefaultValues = {
      row_number: count,
      name: "",
      nomenclature_number: "",
      unit_measurement: "",
      quantity: 0,
      unit_price: 0,
      total_with_vat: 0,
      vat: 0,
      lifecycle: 0,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: TableDefaultValues) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.row_number === item.row_number);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const handleDelete = () => {
    const newData = dataSource.filter(
      (data) => data.row_number !== itemsToDelete.find((item) => item.row_number === data.row_number)?.row_number
    );

    const updatedData = newData.map((data, index) => ({
      ...data,
      row_number: index + 1,
    }));

    newData.length === 0 && setCount(1);
    setDataSource(updatedData);
    setItemsToDelete([]);
  };

  const handleNewForm = () => {
    setCount(1);
    clearInputs();
  };

  const itemColumns: TableProps<TableDefaultValues>["columns"] = [
    {
      title: t("table.row_number"),
      dataIndex: "row_number",
    },
    {
      title: t("table.item_name"),
      dataIndex: "name",
      render: (value, record) => (
        <Input
          value={value}
          onChange={(e) => handleSave({ ...record, name: e.target.value })}
          status={value ? undefined : "error"}
        />
      ),
    },
    {
      title: t("table.nomenclature_number"),
      dataIndex: "nomenclature_number",
      render: (value, record) => (
        <Input
          value={value}
          onChange={(e) => handleSave({ ...record, nomenclature_number: e.target.value })}
          status={value ? undefined : "error"}
        />
      ),
    },
    {
      title: t("table.unit_measurement"),
      dataIndex: "unit_measurement",
      render: (value, record) => (
        <Input
          value={value}
          onChange={(e) => handleSave({ ...record, unit_measurement: e.target.value })}
          status={value ? undefined : "error"}
        />
      ),
    },
    {
      title: t("table.quantity_released"),
      dataIndex: "quantity",
      render: (value, record) => (
        <Input
          type="number"
          value={value || 0}
          onChange={(e) => handleSave({ ...record, quantity: +e.target.value })}
          status={value ? undefined : "error"}
        />
      ),
    },
    {
      title: t("table.unit_price"),
      dataIndex: "unit_price",
      render: (value, record) => (
        <Input
          type="number"
          value={value || 0}
          onChange={(e) => handleSave({ ...record, unit_price: +e.target.value })}
          status={value ? undefined : "error"}
        />
      ),
    },
    {
      title: t("table.sum_with_vat"),
      dataIndex: "total_with_vat",
      render: (value, record) => (
        <Input
          type="number"
          value={value || 0}
          onChange={(e) => handleSave({ ...record, total_with_vat: +e.target.value })}
          status={value ? undefined : "error"}
        />
      ),
    },
    {
      title: t("table.vat"),
      dataIndex: "vat",
      render: (value, record) => (
        <Input type="number" value={value || 0} onChange={(e) => handleSave({ ...record, vat: +e.target.value })} />
      ),
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      render: (value, record) => (
        <Input
          type="number"
          value={value || 0}
          onChange={(e) => handleSave({ ...record, lifecycle: +e.target.value })}
          status={value ? undefined : "error"}
        />
      ),
      hidden: !isOS,
    },
  ];

  const rowSelection: TableProps<TableDefaultValues>["rowSelection"] = {
    type: "checkbox",
    selectedRowKeys: itemsToDelete.map((item) => item.row_number),
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableDefaultValues[]) => {
      setItemsToDelete(selectedRows);
    },
    getCheckboxProps: (record: TableDefaultValues) => ({
      disabled: false, // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between gap-3">
        <div className="flex items-center gap-3">
          <Button className="shadow grow lg:grow-0 text-white bg-green-600" variant="solid" onClick={handleAdd}>
            {t("register.add_item")}
          </Button>
          <Button
            className="shadow grow lg:grow-0"
            variant="solid"
            color="danger"
            onClick={handleDelete}
            disabled={!itemsToDelete.length}
          >
            {t("register.remove_item")}
          </Button>
        </div>

        <div className="flex items-center gap-3">
          <Button className="shadow grow lg:grow-0" onClick={resetToDefault}>
            {t("register.invoice_data_reset_button")}
          </Button>
          <Button className="shadow grow lg:grow-0" onClick={handleNewForm}>
            {t("register.invoice_data_new_form_button")}
          </Button>
          <Button
            className="shadow grow lg:grow-0"
            type="primary"
            htmlType="submit"
            onClick={validateDocument}
            disabled={!submittable || !areAllValuesValid || !dataSource.length}
          >
            {t("register.invoice_data_continue_button")}
          </Button>
        </div>
      </div>

      <Table<TableDefaultValues>
        size="small"
        dataSource={dataSource}
        columns={itemColumns}
        rowSelection={rowSelection}
        rowKey={"row_number"}
        bordered
        pagination={false}
      />
    </>
  );
};

export default EditableTable;
