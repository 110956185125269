import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Table, TableProps } from "antd";
import { IconArrowNarrowRight, IconCheck, IconX } from "@tabler/icons-react";
import { emptyRole, Role } from "types/roles-types";
import NewRole from "./new-role";
import { useEffect, useMemo, useState } from "react";
import { addRole, updateRole } from "api/roles";
import { getRoles } from "api/roles";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";

export default function RolesAndAccess() {
  const { user } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const [roles, setRoles] = useState<Role[]>([]);
  const [roleToEdit, setRoleToEdit] = useState<Role>(emptyRole);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 241;
    } else if (width < 768) {
      return height - 257;
    } else if (width < 1024) {
      return height - 213;
    } else return height - 269;
  }, [window.innerWidth]);

  useEffect(() => {
    getRolesData();
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setRoleToEdit(emptyRole);
    setModalOpen(false);
  };

  const editRole = (role: Role) => {
    setModalOpen(true);
    setRoleToEdit(role);
  };

  const getRolesData = async () => {
    setTableLoading(true);

    await getRoles()
      .then((res: Role[]) => {
        setRoles(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const saveRoleData = async (data: Role) => {
    setTableLoading(true);

    const newRoleData: Role = {
      id_role: data.id_role,
      role: data.role,
      register: data.register || false,
      inventory: data.inventory || false,
      locations: data.locations || false,
      reasons: data.reasons || false,
      employees: data.employees || false,
      move: data.move || false,
      roles: data.roles || false,
      report: data.report || false,
      writeoff: data.writeoff || false,
      reestr: data.reestr || false,
      access_item_logs: data.access_item_logs || false,
      scan: data.scan || false,
      reprint: data.reprint || false,
      batch: data.batch || false,
      access_filials: data.access_filials || false,
    };

    if (newRoleData.id_role) {
      await updateRole({ ...newRoleData, who_changed: user.user_id })
        .then((res: any) => {
          getRolesData();
          showNotification("success", t("notifications.edit_role_success"));
        })
        .catch((error: any) => {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.edit_role_error"), err);
        })
        .finally(() => {
          setTableLoading(false);
          closeModal();
        });
    } else {
      await addRole({ role: newRoleData.role, who_changed: user.user_id })
        .then((res: any) => {
          updateRole({ ...newRoleData, id_role: res.role, who_changed: user.user_id })
            .then(() => {
              getRolesData();
              showNotification("success", t("notifications.create_role_success"));
            })
            .catch((error: any) => {
              let err = error?.response?.data?.detail;
              showNotification("error", t("notifications.create_role_error"), err);
            });
        })
        .catch((error: any) => {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.create_role_error"), err);
        })
        .finally(() => {
          setTableLoading(false);
          closeModal();
        });
    }
  };

  const columns: TableProps<Role>["columns"] = [
    {
      title: t("roles.role_column"),
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
      width: 120,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("roles.action_column"),
      render: (value, record) => (
        <Button className="text-primary border-primary" type="dashed" onClick={() => editRole(record)}>
          {t("roles.edit_button")}
        </Button>
      ),
      width: 160,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("roles.registration_column"),
      dataIndex: "register",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.stock_count_column"),
      dataIndex: "inventory",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.locations_column"),
      dataIndex: "locations",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.reasons_column"),
      dataIndex: "reasons",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.employees_column"),
      dataIndex: "employees",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.moving_column"),
      dataIndex: "move",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.roles_column"),
      dataIndex: "roles",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.dashboard_column"),
      dataIndex: "report",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.writeoff_column"),
      dataIndex: "writeoff",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.registry_column"),
      dataIndex: "reestr",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.logs_column"),
      dataIndex: "access_item_logs",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.scan_qr_column"),
      dataIndex: "scan",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.print_qr_column"),
      dataIndex: "reprint",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.batch_column"),
      dataIndex: "batch",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
    {
      title: t("roles.filial_column"),
      dataIndex: "access_filials",
      render: (value) => (value ? <IconCheck className="text-green-600" /> : <IconX className="text-red-600" />),
    },
  ];

  const RolesNode = (
    <>
      <div className="grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between gap-3">
            <h2 className="text-lg md:text-xl font-semibold text-center">{t("roles.title")}</h2>
            <Button type="primary" onClick={openModal}>
              {t("roles.add_role")}
            </Button>
          </div>
          <Table<Role>
            size="small"
            columns={columns}
            dataSource={roles}
            rowKey="id_role"
            pagination={false}
            loading={isTableLoading}
            scroll={{ x: 2300, y: table_height }}
            bordered
          />
        </div>
      </div>
      <NewRole isModalOpen={isModalOpen} data={roleToEdit} closeModal={closeModal} saveRoleData={saveRoleData} />
    </>
  );

  const RolesTableData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("directories.header")}
        <IconArrowNarrowRight />
        {t("roles.header")}
      </div>
    ),
    node: RolesNode,
  };

  return <StageLayout stage={RolesTableData} />;
}
