import { useEffect, useState } from "react";
import { Button, Form, FormProps, Input, Modal, Switch } from "antd";
import { Role } from "types/roles-types";
import { useTranslation } from "react-i18next";

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export default function NewRole({
  isModalOpen,
  data,
  closeModal,
  saveRoleData,
}: {
  isModalOpen: boolean;
  data: Role;
  closeModal: () => void;
  saveRoleData: (data: Role) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [fields, setFields] = useState<FieldData[]>([]);

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setFields([
      {
        name: "id_role",
        value: data.id_role,
      },
      {
        name: "role",
        value: data.role,
      },
      {
        name: "register",
        value: data.register || false,
      },
      {
        name: "inventory",
        value: data.inventory || false,
      },
      {
        name: "locations",
        value: data.locations || false,
      },
      {
        name: "reasons",
        value: data.reasons || false,
      },
      {
        name: "employees",
        value: data.employees || false,
      },
      {
        name: "move",
        value: data.move || false,
      },
      {
        name: "roles",
        value: data.roles || false,
      },
      {
        name: "report",
        value: data.report || false,
      },
      {
        name: "writeoff",
        value: data.writeoff || false,
      },
      {
        name: "reestr",
        value: data.reestr || false,
      },
      {
        name: "access_item_logs",
        value: data.access_item_logs || false,
      },
      {
        name: "scan",
        value: data.scan || false,
      },
      {
        name: "reprint",
        value: data.reprint || false,
      },
      {
        name: "batch",
        value: data.batch || false,
      },
      {
        name: "access_filials",
        value: data.access_filials || false,
      },
    ]);
  }, [data]);

  useEffect(() => {
    form.resetFields();
  }, [closeModal]);

  const onFinish: FormProps<Role>["onFinish"] = async (payloadData) => {
    setLoading(true);

    saveRoleData(payloadData).then(() => {
      setLoading(false);
    });
  };

  return (
    <Modal
      title={data.id_role ? t("roles.edit_title") : t("roles.new_title")}
      open={isModalOpen}
      footer={false}
      closable={false}
      width={500}
      centered
    >
      <Form
        className="role-form"
        form={form}
        fields={fields}
        onFinish={onFinish}
        disabled={isLoading}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        clearOnDestroy
        validateMessages={{
          required: t("validations.required"),
        }}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item<Role> name="id_role" required hidden>
          <Input />
        </Form.Item>
        <Form.Item<Role> name="role" label={t("roles.role_name")} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item<Role> name="register" label={t("roles.registration_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="inventory" label={t("roles.stock_count_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="locations" label={t("roles.locations_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="reasons" label={t("roles.reasons_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="employees" label={t("roles.employees_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="move" label={t("roles.moving_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="roles" label={t("roles.roles_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="report" label={t("roles.dashboard_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="writeoff" label={t("roles.writeoff_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="reestr" label={t("roles.registry_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="access_item_logs" label={t("roles.logs_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="scan" label={t("roles.scan_qr_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="reprint" label={t("roles.print_qr_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="batch" label={t("roles.batch_column")} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item<Role> name="access_filials" label={t("roles.filial_column")} valuePropName="checked">
          <Switch />
        </Form.Item>

        <div className="flex items-center justify-end gap-3">
          <Button type="primary" onClick={closeModal} loading={isLoading}>
            {t("roles.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("roles.save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
