import { RegistryInvoiceItem } from "types/registry/inventory-types";
import { RegistryItem } from "types/registry/item-types";
import { RegistryWriteOff } from "types/registry/write-off-types";
import { getTranslation } from "./i18n-utils";
import { ActionLogType } from "types/dashboard/actions-dashboard-types";

export const formatExcel = async (data: any[], pathname: string) => {
  const t = getTranslation;
  let formattedTable: any[] = [];

  if (pathname.includes("registry/invoices")) {
    data.forEach((column: RegistryInvoiceItem) => {
      let col = {
        [t("table.invoice_number")]: column.invoice_number,
        [t("table.invoice_date")]: column.invoice_date !== null ? new Date(column.invoice_date).toLocaleString() : "-",
        [t("table.invoice_registration_date")]:
          column.registration_date !== null ? new Date(column.registration_date).toLocaleString() : "-",
        [t("table.mrp")]: column.name_surname,
        [t("table.sender")]: column.sender,
        [t("table.iin_bin")]: column.iin_bin,
        [t("table.item_category")]: column.category,
        [t("table.item_source")]: column.source,
        [t("table.filial")]: column.filial,
        [t("table.recipient")]: column.recipient,
        [t("table.delivery_responsible")]: column.delivery_responsible,
        [t("table.transport_organization")]: column.transport_organization,
        [t("table.item_transport_invoice")]: column.item_transport_invoice,
        [t("table.sum_with_vat")]: column.sum_with_vat,
      };

      formattedTable.push(col);
    });
  } else if (pathname.includes("registry/items")) {
    data.forEach((column: RegistryItem) => {
      let col = {
        "E-SEP ID": column.id_item.toString(),
        [t("table.item_name")]: column.item_name,
        [t("table.item_category")]: column.category,
        [t("table.item_batch")]: column.batch,
        [t("table.item_source")]: column.source,
        [t("table.item_location")]: column.location_name,
        [t("table.quantity")]: column.quantity,
        [t("table.quantity_released")]: column.quantity_released,
        [t("table.quantity_checked")]: column.quantity_checked,
        [t("table.mrp")]: column.stock_responsible,
        [t("table.stock_date")]: column.stock_date !== null ? new Date(column.stock_date).toLocaleString() : "-",
        [t("table.lifecycle")]: column.lifecycle,
        [t("table.unit_price")]: column.unit_price,
        [t("table.sum_with_vat")]: column.sum_with_vat,
        [t("table.vat")]: column.vat,
        [t("table.amortisation_value")]: column.amortisation_value,
        [t("table.stock_count_date")]:
          column.last_check_date !== null ? new Date(column.last_check_date).toLocaleString() : "-",
        [t("table.stock_count_status")]: column.status,
        [t("table.stock_count_auditor")]: column.inventory_responsible,
        [t("table.invoice_number")]: column.invoice_number,
        [t("table.invoice_date")]: column.invoice_date !== null ? new Date(column.invoice_date).toLocaleString() : "-",
        [t("table.iin_bin")]: column.iin_bin,
        [t("table.sender")]: column.sender,
        [t("table.recipient")]: column.recipient,
        [t("table.filial")]: column.filial,
      };

      formattedTable.push(col);
    });
  } else if (pathname.includes("registry/write-off")) {
    data.forEach((column: RegistryWriteOff) => {
      let col = {
        "E-SEP ID": column.id_item.toString(),
        [t("table.item_name")]: column.item_name,
        [t("table.item_category")]: column.category,
        [t("table.item_batch")]: column.batch,
        [t("table.item_source")]: column.source,
        [t("table.quantity_released")]: column.quantity_released,
        [t("table.quantity_writeoff")]: column.quantity,
        [t("table.writeoff_date")]: column.stock_date !== null ? new Date(column.stock_date).toLocaleString() : "-",
        [t("table.writeoff_reason")]: column.reason,
        [t("table.writeoff_user")]: column.user_writeoff,
        [t("table.mrp")]: column.name_surname,
        [t("table.sum_with_vat")]: column.sum_with_vat,
        [t("table.invoice_number")]: column.sum_with_vat,
        [t("table.invoice_date")]: column.invoice_date !== null ? new Date(column.invoice_date).toLocaleString() : "-",
        [t("table.iin_bin")]: column.iin_bin,
        [t("table.sender")]: column.sender,
        [t("table.recipient")]: column.recipient,
        [t("table.filial")]: column.filial,
      };

      formattedTable.push(col);
    });
  } else if (pathname.includes("report")) {
    data.forEach((column: ActionLogType) => {
      let col = {
        "E-SEP ID": column.id_item.toString(),
        [t("table.item_name")]: column.item_name,
        [t("table.mrp")]: column.name_surname,
        [t("table.action_name")]: column.action_name,
        [t("table.action_type")]: column.action_type,
        [t("table.action_date")]: column.action_date !== null ? new Date(column.action_date).toLocaleString() : "-",
      };

      formattedTable.push(col);
    });
  }

  return formattedTable;
};
