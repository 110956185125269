export const table = {
  // invoice
  invoice_number: "Номер накладной",
  invoice_date: "Дата накладной",
  invoice_registration_date: "Дата регистрации в системе",
  sender: "Отправитель",
  iin_bin: "ИИН/БИН",
  recipient: "Получатель",
  delivery_responsible: "Ответственный за поставку",
  transport_organization: "Транспортная организация",
  item_transport_invoice: "Товарно-транспортная накладная",
  // item
  row_number: "Номер по порядку",
  item_name: "Наименование товара",
  nomenclature_number: "Номенклатурный номер",
  item_category: "Категория товара",
  item_batch: "Партия",
  item_source: "Источник",
  filial: "Филиал",
  item_location: "Локация",
  quantity: "Количество в локации",
  quantity_released: "Количество отпущено",
  quantity_writeoff: "Списанное количество",
  quantity_checked: "Количество по факту",
  writeoff_reason: "Причина списания",
  writeoff_user: "Списал товар",
  mrp: "МОЛ",
  stock_date: "Дата действия",
  writeoff_date: "Дата списания",
  lifecycle: "Срок полезной службы, мес",
  unit_measurement: "Единица измерения",
  unit_price: "Цена за единицу, в KZT",
  sum_with_vat: "Сумма с НДС, в KZT",
  vat: "Сумма НДС, в KZT",
  amortisation_value: "Остаточная стоимость, в KZT",
  stock_count_date: "Дата инвентаризации",
  stock_count_status: "Статус инвентаризации",
  stock_count_auditor: "Инвентаризатор",
  // item details
  action_name: "Активность",
  action_type: "Тип активности",
  action_date: "Дата",
  user: "Пользователь",
  // action button
  expand_table: "Развернуть таблицу",
  collapse_table: "Свернуть таблицу",
};
