export const registry = {
  header: "Реестр",

  // type choice
  invoices: "Накладные",
  items: "Товары",
  writeoff_items: "Списанные товары",
  choose_type: "Выберите тип реестра",
  next: "Далее",

  // export xlsx
  export_title: "Экспорт данных",
  export_content: "Экспортировать данные из таблицы?",

  // invoices
  invoice_title: "Накладная №",
  details_title: "Детали накладной",

  // items
  item_details_title: "Детали товара",
};
