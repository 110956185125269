export const moving = {
  // moving list
  moving_header: "Item transfer",
  writeoff_header: "Item write-off",
  single: "By one",
  multiple: "By batch",
  moving_type_title: "Select transfer type",
  writeoff_type_title: "Select write-off type",
  batch_title: "Select batch",
  moving_item: "Select an item for transfer",
  writeoff_item: "Select an item for write-off",
  next: "Next",

  // moving page
  moving_guide_title: "Transfer instructions",
  moving_guide_1: "1. Select the final location;",
  moving_guide_2: "2. Select the MRP (materially responsible person);",
  moving_guide_3: "3. Select the quantity of goods to be transfered;",
  moving_guide_4:
    "4. If the quantity of the product is a fractional number, indicate it rounded to the nearest hundredth (for example: 1.45)",
  moving_guide_5: "5. Take a photo of the transfered goods.",
  writeoff_guide_title: "Instructions for writing-off",
  writeoff_guide_1: "1. Select the reason for the write-off;",
  writeoff_guide_2: "2. Select the quantity of goods to be written off;",
  writeoff_guide_3:
    "3. If the quantity of goods is a fractional number, indicate rounded to the nearest hundredth (for example: 1.45)",
  writeoff_guide_4: "4. Take a photo of the written-off item.",
  excluded_alert:
    "At the selected location, this person already has some of the selected items, therefore the maximum quantity of goods was limited",
  move_from: "From",
  move_where: "Where",
  move_mrp: "New responsible person",
  move_reason: "Write-off reason",
  move_quantity: "Quantity",
  quantity_alert: "Quantity cannot be zero or exceed total available inventory in the table!",
  confirm_header: "Confirm action",
  confirm_moving_title: "Are you sure you want to transfer the items?",
  confirm_writeoff_title: "Are you sure you want to write off the items?",
  cancel: "Cancel",
  confirm: "Confirm",
  move_button: "Transfer",
  writeoff_button: "Write off",
  move_success: "The product has been successfully transfered!",
  move_in_progress: "Transfer in progress...",
  writeoff_success: "The product has been successfully written off!",
  writeoff_in_progress: "Write-off in progress...",
};
