export const photo = {
  title: "Фото",
  change_photo: "Сменить фото",
  add_photo: "Добавить фото",

  // capture photo
  capture_photo_title: "Съёмка фото",
  save: "Сохранить",
  cancel: "Отмена",
  retake_photo: "Сделать повторный снимок",
  take_photo: "Сделать снимок",

  // photo preview
  open_pdf: "Открыть PDF документ",
  open_photo: "Открыть фото",
};
