export const locations = {
  header: "Locations",
  name_column: "Location name",
  filial_column: "Filial",
  status_column: "Status",
  active: "Active",
  inactive: "Inactive",
  deactivate: "Deactivate",
  activate: "Activate",
  title: "Locations list",
  add_location: "Add location",
  add_filial: "Add filial",
  // new location
  new_location_title: "New location",
  cancel: "Cancel",
  save: "Save",
  // new filial
  new_filial_title: "New filial",
};
