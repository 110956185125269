import React, { createContext, useContext, useState, ReactNode, useMemo, useEffect } from "react";
import { emptyUser, User, UserResponse } from "types/user-types";
import AuthPage from "components/auth-page";
import { authenticateUser, getClientUrls, getUser } from "api/auth";
import { useNavigate } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import { ScanImage, TableWithPhoto } from "types/registration-types";
import { MovingData } from "types/moving-types";
import { BasketItem, emptyBasketItem, emptyInventoryJournalItem, InventoryJournalItem } from "types/inventory-types";
import { emptyRegistryInvoiceItem, RegistryInvoiceItem } from "types/registry/inventory-types";
import { useNotification } from "state/notification-context";
import { RegistryItem } from "types/registry/item-types";
import ruLocale from "antd/es/locale/ru_RU";
import enLocale from "antd/es/locale/en_US";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

interface MainContext {
  OsType: "Desktop" | "Mobile" | "Unknown";
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  loadingScreen: boolean;
  setLoadingScreen: React.Dispatch<React.SetStateAction<boolean>>;
  hasToken: boolean;
  user: User;
  login: (payload: { username: string; password: string }) => Promise<void>;
  logout: () => void;
  invoice: ScanImage[];
  setInvoice: React.Dispatch<React.SetStateAction<ScanImage[]>>;
  reactData: TableWithPhoto[];
  setReactData: React.Dispatch<React.SetStateAction<TableWithPhoto[]>>;
  itemsToMove: MovingData[];
  setItemsToMove: React.Dispatch<React.SetStateAction<MovingData[]>>;
  itemInventory: BasketItem;
  setItemInventory: React.Dispatch<React.SetStateAction<BasketItem>>;
  inventoryJournalItem: InventoryJournalItem;
  setInventoryJournalItem: React.Dispatch<React.SetStateAction<InventoryJournalItem>>;
  invoiceItem: RegistryInvoiceItem;
  setInvoiceItem: React.Dispatch<React.SetStateAction<RegistryInvoiceItem>>;
  detailedItem: RegistryItem | null;
  setDetailedItem: React.Dispatch<React.SetStateAction<RegistryItem | null>>;
}

const MainContextContext = createContext<MainContext | undefined>(undefined);

export const MainContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const { showNotification } = useNotification();

  const { t } = useTranslation();

  const [isAppLoading, setAppLoading] = useState<boolean>(false);
  const [OsType, setOsType] = useState<"Desktop" | "Mobile" | "Unknown">("Unknown");
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [hash, setHash] = useState<string | null>(localStorage.getItem("hash"));
  const [user, setUser] = useState<User>(emptyUser);
  const [invoice, setInvoice] = useState<ScanImage[]>([]);
  const [reactData, setReactData] = useState<TableWithPhoto[]>([]);
  const [itemsToMove, setItemsToMove] = useState<MovingData[]>([]);
  const [itemInventory, setItemInventory] = useState<BasketItem>(emptyBasketItem);
  const [inventoryJournalItem, setInventoryJournalItem] = useState<InventoryJournalItem>(emptyInventoryJournalItem);
  const [invoiceItem, setInvoiceItem] = useState<RegistryInvoiceItem>(emptyRegistryInvoiceItem);
  const [detailedItem, setDetailedItem] = useState<RegistryItem | null>(null);

  const getOS = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      setOsType("Mobile");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOsType("Mobile");
    } else if (/Win/i.test(userAgent)) {
      setOsType("Desktop");
    } else if (/Mac/i.test(userAgent)) {
      setOsType("Desktop");
    } else if (/Linux/i.test(userAgent)) {
      setOsType("Desktop");
    } else setOsType("Unknown");
  };

  const localHash = localStorage.getItem("hash");

  const hasToken: boolean = useMemo(() => {
    if (localHash !== null) {
      setHash(localHash);
      return true;
    } else return false;
  }, [localHash]);

  const login = async (payload: { username: string; password: string }) => {
    await authenticateUser(payload)
      .then(() => {
        setHash(localStorage.getItem("hash"));
        showNotification("success", t("notifications.auth_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.auth_error"), err);
      });
  };

  const logout = () => {
    showNotification("success", t("notifications.logout_success"));
    localStorage.clear();
    setHash(null);
    setUser(emptyUser);
    navigate("/");
  };

  const getUserData = async () => {
    await getUser()
      .then(async (user_res: UserResponse) => {
        setUser(user_res.result);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.user_data_error"), err);
      });
  };

  const initApp = async () => {
    if (hash !== null) {
      setAppLoading(true);
      getOS();
      Promise.all([await getClientUrls(), await getUserData()]);
      setAppLoading(false);
    } else {
      await getClientUrls();
    }
  };

  const value: MainContext = {
    OsType,
    darkMode,
    setDarkMode,
    loadingScreen,
    setLoadingScreen,
    hasToken,
    user,
    login,
    logout,
    invoice,
    setInvoice,
    reactData,
    setReactData,
    itemsToMove,
    setItemsToMove,
    itemInventory,
    setItemInventory,
    inventoryJournalItem,
    setInventoryJournalItem,
    invoiceItem,
    setInvoiceItem,
    detailedItem,
    setDetailedItem,
  };

  const dynamicChildren = () => {
    switch (hasToken) {
      case true:
        if (!isAppLoading) {
          return (
            <>
              <ConfigProvider locale={i18n.language === "ru" ? ruLocale : enLocale}>{children}</ConfigProvider>
              <Spin spinning={loadingScreen} fullscreen />
            </>
          );
        } else {
          // TODO: make a loading screen
          return <Spin fullscreen />;
        }

      default:
        if (window.location.pathname === "/delete_account") {
          return children;
        } else return <AuthPage />;
    }
  };

  useEffect(() => {
    initApp();
  }, [hash]);

  return <MainContextContext.Provider value={value}>{dynamicChildren()}</MainContextContext.Provider>;
};

export const useMainContext = (): MainContext => {
  const context = useContext(MainContextContext);
  if (!context) {
    throw new Error("useMainContext must be used within a MainContextProvider");
  }
  return context;
};
