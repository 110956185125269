import { useState, useRef, useEffect } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

interface CameraProps {
  isCameraActive: boolean;
  setCameraActive: (state: boolean) => void;
  sendPhoto: (photo: string | null) => void;
}

const CameraCapture: React.FC<CameraProps> = ({ isCameraActive, setCameraActive, sendPhoto }) => {
  const { t } = useTranslation();

  const [photo, setPhoto] = useState<string | null>(null); // Store the photo in the state
  const videoRef = useRef<HTMLVideoElement | null>(null); // Ref for video element
  const canvasRef = useRef<HTMLCanvasElement | null>(null); // Ref for canvas element

  const savePhoto = () => {
    sendPhoto(photo);
    hideCaptureModal();
  };

  const hideCaptureModal = () => {
    setCameraActive(false);
    setPhoto(null);
  };

  useEffect(() => {
    isCameraActive && startCamera();

    // Clean up on component unmount (stop the camera)
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [isCameraActive]);

  // Request camera access on component mount
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  // Function to capture the photo
  const capturePhoto = () => {
    if (photo) {
      setPhoto(null);
    } else if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (context) {
        // Set canvas size to match the video size
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;

        // Draw the current frame from the video onto the canvas
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        // Get the image data from the canvas and convert it to a base64 string
        const photoUrl = canvas.toDataURL("image/png");
        setPhoto(photoUrl); // Save the captured photo in state
      }
    }
  };

  return (
    <div>
      <Modal
        title={t("photo.capture_photo_title")}
        open={isCameraActive}
        onOk={savePhoto}
        onCancel={hideCaptureModal}
        okText={t("photo.save")}
        cancelText={t("photo.cancel")}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <Button type="primary" onClick={capturePhoto}>
              {photo ? t("photo.retake_photo") : t("photo.take_photo")}
            </Button>
            {photo && <OkBtn />}
          </>
        )}
        keyboard={false}
        centered
      >
        {/* Video element displaying the camera feed */}
        <video
          ref={videoRef}
          style={photo ? { display: "none" } : undefined}
          autoPlay
          muted
          width="100%"
          height="auto"
        />

        {/* Canvas element (hidden, used for capturing the photo) */}
        <canvas ref={canvasRef} style={{ display: "none" }} />

        {photo && <img src={photo} alt="Captured" style={{ maxWidth: "100%" }} />}
      </Modal>
    </div>
  );
};

export default CameraCapture;
