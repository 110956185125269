export const notifications = {
  // auth
  auth_success: "Авторизация прошла успешно",
  auth_error: "Ошибка при авторизации",
  logout_success: "Деавторизация прошла успешно",
  user_data_error: "Ошибка при получении данных пользователя",
  // get data
  data_error: "Ошибка при получении данных",
  item_count_error: "Ошибка при получении количества товаров",
  // filters
  filters_error: "Не удалось получить фильтры",
  // export
  export_success: "Данные успешно экспортированы",
  // photo
  photo_error: "Фотографии отсутствуют",
  // scan
  scan_error: "Ошибка при сканировании",
  scan_error_scanner: "Не удалось подключиться к сканеру",
  // qr
  qr_access_denied: "Доступ к камере запрещен",
  qr_print_success: "QR-код(ы) распечатаны",
  qr_error: "Ошибка при печати QR-кодов",
  qr_error_printer: "Не удалось подключиться к принтеру",
  // register
  save_invoice_error: "Ошибка при сохранении накладной",
  save_invoice_success: "Накладная успешно сохранена",
  parse_invoice_error: "Ошибка при обработке накладной",
  parse_invoice_in_progress: "Документ обрабатывается. Пожалуйста, подождите",
  parse_queue_count: "Активных сессий обработки:",
  parse_invoice_success: "Документ успешно обработан",
  invoice_register_error: "Ошибка при сохранении товаров",
  invoice_register_success: "Товары успешно сохранены",
  item_register_success: "Карта товара успешно сохранена",
  // transfer / write-off
  transfer_success: "Товар успешно перемещен",
  writeoff_success: "Товар успешно списан",
  transfer_error: "Ошибка при перемещении товаров",
  writeoff_error: "Ошибка при списании товаров",
  // stock count
  stock_count_success: "Инвентаризация успешно сохранена",
  stock_count_error: "Ошибка при сохранении инвентаризации",
  basket_error: "Ошибка при получении корзины",
  add_to_basket_success: "Товары добавлены в корзину",
  add_to_basket_error: "Ошибка при добавлении в корзину",
  delete_from_basket_success: "Товары удалены из корзины",
  delete_from_basket_error: "Ошибка при удалении из корзины",
  // employees
  save_employee_success: "Данные сотрудника успешно сохранены",
  save_employee_error: "Ошибка при сохранении данных сотрудника",
  // locations
  create_location_success: "Локация успешно создана",
  create_location_error: "Ошибка при создании локации",
  create_filial_success: "Филиал успешно создан",
  create_filial_error: "Ошибка при создании филиала",
  edit_location_success: "Локация успешно изменена",
  edit_location_error: "Ошибка при изменении локации",
  create_writeoff_reason_success: "Причина списания успешно создана",
  create_writeoff_reason_error: "Ошибка при создании причины списания",
  // roles
  edit_role_success: "Роль успешно изменена",
  edit_role_error: "Ошибка при изменении роли",
  create_role_success: "Роль успешно создана",
  create_role_error: "Ошибка при создании роли",
  // batches
  create_batch_success: "Партия успешно создана",
  create_batch_error: "Ошибка при создании партии",
  edit_batch_success: "Партия успешно изменена",
  edit_batch_error: "Ошибка при изменении партии",
  add_to_batch_success: "Товары успешно добавлены в партию",
  add_to_batch_error: "Ошибка при добавлении в партию",
  delete_from_batch_success: "Товары успешно удалены из партии",
  delete_from_batch_error: "Ошибка при удалении из партии",
};
