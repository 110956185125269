export interface RegistryItem {
  id_item: number;
  item_name: string;
  category: string;
  batch: string;
  source: string;

  location_name: string;
  quantity: number;
  quantity_released: number;
  quantity_checked: number;
  stock_responsible: string;

  stock_date: string;
  lifecycle: number;
  unit_price: number;
  sum_with_vat: number;
  vat: number;
  amortisation_value: number;

  last_check_date: string;
  status: string;
  inventory_responsible: string;
  invoice_number: string;
  invoice_date: string;

  iin_bin: string;
  sender: string;
  recipient: string;
  filial: string;

  // unused
  id_stock: number;
  id_location: number;
  unit_measurement: string;
  id_invoice: number;
  delivery_responsible: string;
  transport_organization: string;
}

export interface RegistryItemDetails {
  id_item: number;
  id_user: number;
  action_name: string;
  action_date: string;
  action_type: string;
  id_log_item: number;
  name_surname: string;
}

export interface RegistryItemResponse {
  items: RegistryItem[];
  total_items: number;
}

export const emptyRegistryItemResponse: RegistryItemResponse = {
  items: [],
  total_items: 0,
};

export const emptyRegistryItemDetails: RegistryItemDetails = {
  id_item: 0,
  id_user: 0,
  action_name: "",
  action_date: "",
  action_type: "",
  id_log_item: 0,
  name_surname: "",
};
