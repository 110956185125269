export const reasons = {
  header: "Reasons for write-off",
  name_column: "Name",
  title: "Reasons list",
  add_reason: "Add reason",
  // new reason
  new_reason_title: "New write-off reason",
  cancel: "Cancel",
  save: "Save",
};
