import { useNavigate } from "react-router-dom";
import { Button, Divider, Dropdown, MenuProps } from "antd";
import {
  IconBook2,
  IconBuildingWarehouse,
  IconCheck,
  IconLanguage,
  IconLogout,
  IconPackage,
  IconPackageExport,
  IconPackageOff,
  IconQrcode,
  IconReportAnalytics,
  IconScan,
  IconSettings,
  IconTable,
  IconUser,
  IconUserCircle,
} from "@tabler/icons-react";
import { useMainContext } from "state/main-context";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import MenuTiles from "helpers/menu-tiles";
import { CategoryType } from "types/ui/category-types";

export default function MainPage() {
  const { OsType, user, logout } = useMainContext();

  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    localStorage.setItem("i18nextLng", lng);
    i18n.changeLanguage(lng);
  };

  const isLanguageRussian = useMemo(() => {
    return localStorage.getItem("i18nextLng") === "ru" ? true : false;
  }, [changeLanguage]);

  const mainMenuTiles: CategoryType[] = [
    { id: "registry", title: t("main.registry"), icon: <IconTable size={60} />, url: "/registry" },
    { id: "report", title: t("main.report"), icon: <IconReportAnalytics size={60} />, url: "/report" },
    { id: "inventory", title: t("main.inventory"), icon: <IconBuildingWarehouse size={60} />, url: "/inventory" },
    { id: "registration", title: t("main.registration"), icon: <IconPackage size={60} />, url: "/registration" },
    {
      id: "product-moving",
      title: t("main.product_moving"),
      icon: <IconPackageExport size={60} />,
      url: "/product-moving",
    },
    {
      id: "product-write-off",
      title: t("main.product_write_off"),
      icon: <IconPackageOff size={60} />,
      url: "/product-write-off",
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: user.name,
      icon: <IconUser />,
      style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
    },
    {
      key: "1",
      label: t("main.scan"),
      icon: <IconScan />,
      onClick: () => navigate("/scan-from-main"),
    },
    {
      key: "2",
      label: t("main.print"),
      icon: <IconQrcode />,
      onClick: () => navigate("/print-again"),
      disabled: OsType !== "Desktop",
    },
    {
      key: "3",
      label: t("main.employees"),
      icon: <IconUserCircle />,
      onClick: () => navigate("/employees"),
    },
    {
      key: "4",
      label: t("main.dictionaries"),
      icon: <IconBook2 />,
      onClick: () => navigate("/dictionaries"),
    },
    {
      key: "5",
      label: t("main.change_language"),
      icon: <IconLanguage />,
      children: [
        {
          key: "5-1",
          label: "Русский",
          icon: isLanguageRussian ? <IconCheck /> : null,
          onClick: () => changeLanguage("ru"),
        },
        {
          key: "5-2",
          label: "English",
          icon: !isLanguageRussian ? <IconCheck /> : null,
          onClick: () => changeLanguage("en"),
        },
      ],
    },
    {
      key: "6",
      label: t("main.logout"),
      icon: <IconLogout />,
      onClick: () => logout(),
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <section className="flex flex-col p-2 sm:p-3 md:p-4 lg:p-6 h-[100dvh]">
      <div className="flex items-center justify-between">
        <div className="text-lg md:text-xl font-bold text-dark-grey">E-SEP</div>
        <Dropdown menu={menuProps} placement="bottomLeft" trigger={["click"]}>
          <Button className="shadow" icon={<IconSettings />}></Button>
        </Dropdown>
      </div>
      <Divider className="my-2 md:my-4 lg:my-6" />
      <MenuTiles tiles={mainMenuTiles} />
    </section>
  );
}
