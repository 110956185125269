export const inventory = {
  header: "Инвентаризация",
  basket_header: "Корзина",

  // type choice
  choose_type_title: "Выберите метод инвентаризации",
  table_to_item: "От учета к товару",
  item_to_table: "От товара к учету",
  journal: "Журнал учёта товаров",
  next: "Далее",

  // list
  title: "Добавьте товары в корзину для инвентаризации",
  add_to_basket: "Добавить в корзину",
  go_to_basket: "Перейти к корзине",

  // basket
  choose_item: "Выберите товар для начала инвентаризации",
  delete_from_basket: "Удалить из корзины",
  print_1c: "Распечатать QR-код для 1С",
  start_inventory: "Начать инвентаризацию",
  total_1: "Итого позиций:",
  total_2: "шт., сумма:",
  alert: "Инвентаризацию может пройти только одна позиция за раз",
  basket_empty: "Корзина пуста",
  back_to_list: "Вернуться к списку",

  // process
  check_found_title: "Найдите товар, указанный ниже в таблице, затем нажмите 'Далее'",
  check_found_button: "Далее",
  check_not_found_title: "Товар не был найден, сохраните данные, чтобы продолжить",
  check_not_found_button: "Сохранить данные",
  check_scan_title: "Отсканируйте QR-код товара, указанного ниже в таблице",
  check_next_button: "Далее",
  check_scan_again: "Сканировать повторно",
  check_scan_button: "Сканировать",
  check_photo: "Сфотографируйте товар из таблицы и укажите его количество",
  check_save_button: "Сохранить инвентаризацию",
  modal_found_title: "Был ли найден товар?",
  modal_not_found: "Нет",
  modal_found: "Да",
  modal_header: "Товар отсутствует",
  modal_content: "Инвентаризация будет завершена со статусом 'Товара нет'. Продолжить?",
  cancel: "Отменить",
  confirm: "Подтвердить",
  checked_title: "Проверено",
  end_inventory: "Завершить инвентаризацию?",
  end_cancel: "Отмена",
  end_confirm: "Завершить",
  qr_match: "QR-код совпадает с данными товара",
  qr_not_match: "QR-код не совпадает с данными товара",
  quantity: "Введите актуальное количество товара:",
  qr_mismatch_title: "Несоответствие QR-кода",
  qr_mismatch_content: "Инвентаризация будет завершена со статусом 'QR-код не соответствует'. Продолжить?",
  save: "Сохранить",
  inventory_in_progress: "Сохраняем данные товаров...",

  // item to table
  is_match: "Соответcтвует ли товар на котором был просканирован QR-код найденному товару?",
  is_match_button: "Соответствует",
  scan_qr_title: "Отсканируйте QR-код, чтобы вывести информацию о товаре",
  alert_not_found: "Товар не найден",
  item_qr_mismatch: "Не соответствует",

  // journal choice
  table_to_item_journal: "Журнал от учета к товару",
  item_to_table_journal: "Журнал от товара к учету",
  choose_journal_type: "Выберите вид журнала",

  // journal table
  unique_items: "Проверено единиц",
  non_matching_items: "Неверное количество",
  method: "Метод инвентаризации",
  details_button: "Подробнее",
  details_title: "Нажмите на кнопку 'Подробнее' для просмотра информации об инвентаризации.",

  // journal details
  journal_details_title: "Детали инвентаризации",
  checked_items_title: "Проверенные единицы:",
};
