import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Image, Table, TableProps } from "antd";
import { IconArrowNarrowRight, IconFileTypePdf, IconPhoto } from "@tabler/icons-react";
import { getImages } from "api/moving";
import { getItemLogs } from "api/registry";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { RegistryItem, RegistryItemDetails } from "types/registry/item-types";
import { useTranslation } from "react-i18next";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function ItemDetails() {
  const { setLoadingScreen, detailedItem } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const { id_item } = useParams();

  const [itemDetailData, setItemDetailData] = useState<RegistryItemDetails[]>([]);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 388;
    } else if (width < 768) {
      return height - 426;
    } else if (width < 1024) {
      return height - 426;
    } else return height - 442;
  }, [window.innerWidth]);

  useEffect(() => {
    getItemsList();
  }, []);

  const getItemsList = async () => {
    setTableLoading(true);

    id_item &&
      (await getItemLogs(+id_item)
        .then((res: RegistryItemDetails[]) => {
          setItemDetailData(res);
        })
        .catch((error: any) => {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.data_error"), err);
        })
        .finally(() => {
          setTableLoading(false);
        }));
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const itemColumns: TableProps<RegistryItem>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
    },

    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
    },
  ];

  const columns: TableProps<RegistryItemDetails>["columns"] = [
    {
      title: t("table.action_name"),
      dataIndex: "action_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.action_name.localeCompare(b.action_name),
      width: 300,
    },
    {
      title: t("table.action_type"),
      dataIndex: "action_type",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.action_type.localeCompare(b.action_type),
      width: 400,
    },
    {
      title: t("table.action_date"),
      dataIndex: "action_date",
      render: (value) => (value !== null ? new Date(value).toLocaleString() : "-"),
      sorter: (a, b) => a.action_date.localeCompare(b.action_date),
      width: 200,
    },
    {
      title: t("table.user"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.name_surname.localeCompare(b.name_surname),
      width: 200,
    },
  ];

  const fourthStageNode = (
    <div className="flex flex-col gap-3 shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white grow">
      <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3">
        <h2 className="text-lg md:text-xl font-semibold">{detailedItem?.item_name}</h2>
        <div className="flex flex-wrap md:flex-nowrap items-center gap-3">
          <Button
            className="shadow grow"
            icon={<IconFileTypePdf size={20} />}
            onClick={() => getItemImages("invoices", detailedItem ? detailedItem.id_invoice : 0)}
          >
            {t("photo.open_pdf")}
          </Button>

          <Button
            className="shadow grow"
            icon={<IconPhoto size={20} />}
            onClick={() => getItemImages("items", detailedItem ? detailedItem.id_item : 0)}
          >
            {t("photo.open_photo")}
          </Button>

          <div className="hidden">
            <Image.PreviewGroup
              items={itemImages}
              preview={{
                visible: previewVisible,
                destroyOnClose: true,
                imageRender: (original, info) => (
                  <div className="relative flex max-h-[70%]">
                    {original}
                    <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                      {translatePhotoName(itemImages[info.current]?.name)}
                    </div>
                  </div>
                ),
                onVisibleChange: (value) => {
                  setPreviewVisible(value);
                  !value && setItemImages([]);
                },
              }}
            >
              <Image />
            </Image.PreviewGroup>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <Table<RegistryItem>
          size="small"
          dataSource={detailedItem ? [detailedItem] : undefined}
          columns={itemColumns}
          rowKey="id_item"
          loading={isTableLoading}
          pagination={false}
          scroll={{ x: 1200 }}
        />
        <h6 className="md:text-lg lg:text-xl font-semibold">{t("registry.item_details_title")}</h6>
        <Table<RegistryItemDetails>
          size="small"
          dataSource={itemDetailData}
          columns={columns}
          rowKey="id_log_item"
          loading={isTableLoading}
          pagination={{ defaultPageSize: 40, hideOnSinglePage: true }}
          scroll={{ y: table_height }}
        />
      </div>
    </div>
  );

  const fourthStageData: StageType = {
    id: 3,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("registry.header")}
        <IconArrowNarrowRight />
        {t("registry.items")}
        <IconArrowNarrowRight />
        {t("registry.item_details_title")}
      </div>
    ),
    node: fourthStageNode,
  };

  return <StageLayout stage={fourthStageData} />;
}
