export const table = {
  // invoice
  invoice_number: "Invoice number",
  invoice_date: "Invoice date",
  invoice_registration_date: "Registration date",
  sender: "Sender",
  iin_bin: "TIN/BIN",
  recipient: "Recipient",
  delivery_responsible: "Delivery responsible",
  transport_organization: "Transport organization",
  item_transport_invoice: "Consignment note",
  // item
  row_number: "#",
  item_name: "Item name",
  nomenclature_number: "Item number",
  item_category: "Item category",
  item_batch: "Batch",
  item_source: "Source",
  filial: "Filial",
  item_location: "Location",
  quantity: "Quantity in location",
  quantity_released: "Quantity issued",
  quantity_writeoff: "Writen off quantity",
  quantity_checked: "Actual quantity",
  writeoff_reason: "Write-off reason",
  writeoff_user: "Written-off by",
  mrp: "MRP",
  stock_date: "Action date",
  writeoff_date: "Write-off date",
  lifecycle: "Useful life, months",
  unit_measurement: "Unit of measure",
  unit_price: "Unit price, in KZT",
  sum_with_vat: "Amount including VAT, in KZT",
  vat: "VAT amount, in KZT",
  amortisation_value: "Residual value, in KZT",
  stock_count_date: "Stock count date",
  stock_count_status: "Stock count status",
  stock_count_auditor: "Stock count auditor",
  // item details
  action_name: "Activity",
  action_type: "Activity type",
  action_date: "Date",
  user: "User",
  // action button
  expand_table: "Expand table",
  collapse_table: "Collapse table",
};
