import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Image, Table, TableProps, Tag } from "antd";
import { IconArrowNarrowRight, IconFileTypePdf, IconPhoto } from "@tabler/icons-react";
import { getImages } from "api/moving";
import { getInvoiceItems } from "api/registry";
import { RegistryInvoiceItem, RegistryInvoiceItemDetail } from "types/registry/inventory-types";
import { useMainContext } from "state/main-context";
import { useNotification } from "state/notification-context";
import { useTranslation } from "react-i18next";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function InvoiceDetails() {
  const { setLoadingScreen, invoiceItem } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const { id_invoice } = useParams();

  const [invoiceDetailData, setInvoiceDetailData] = useState<RegistryInvoiceItemDetail[]>([]);
  const [selectedItems, setSelectedItems] = useState<RegistryInvoiceItemDetail[]>([]);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 388;
    } else if (width < 768) {
      return height - 426;
    } else if (width < 1024) {
      return height - 426;
    } else return height - 442;
  }, [window.innerWidth]);

  useEffect(() => {
    getInvoiceItemsList();
  }, []);

  const getInvoiceItemsList = async () => {
    setTableLoading(true);

    await getInvoiceItems(!!id_invoice ? +id_invoice : 0)
      .then((res: RegistryInvoiceItemDetail[]) => {
        setInvoiceDetailData(res);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const invoiceColumns: TableProps<RegistryInvoiceItem>["columns"] = [
    {
      title: t("table.invoice_number"),
      dataIndex: "invoice_number",
      render: (value) => (value !== null ? value : "-"),
      width: 110,
      fixed: window.innerWidth > 640 ? "left" : false,
    },
    {
      title: t("table.invoice_date"),
      dataIndex: "invoice_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
    },
    {
      title: t("table.invoice_registration_date"),
      dataIndex: "registration_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.sender"),
      dataIndex: "sender",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.iin_bin"),
      dataIndex: "iin_bin",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
    },
  ];

  const columns: TableProps<RegistryInvoiceItemDetail>["columns"] = [
    {
      title: "№",
      dataIndex: "index",
      render: (_, __, index) => index + 1,
      width: 60,
    },
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.id_item - b.id_item,
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value: number) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
      sorter: (a, b) => a.item_name.localeCompare(b.name_surname),
    },
    {
      title: t("table.stock_count_date"),
      dataIndex: "last_check_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
      sorter: (a, b) => a.item_name.localeCompare(b.last_check_date),
    },
    {
      title: t("table.stock_count_status"),
      dataIndex: "status",
      render: (value) => <Tag color={value === t("inventory.is_match_button") ? "success" : "error"}>{value}</Tag>,
      sorter: (a, b) => a.item_name.localeCompare(b.status),
    },
  ];

  const rowSelection: TableProps<RegistryInvoiceItemDetail>["rowSelection"] = {
    type: "radio",
    onChange: (selectedRowKeys: React.Key[], selectedRows: RegistryInvoiceItemDetail[]) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record: RegistryInvoiceItemDetail) => ({
      disabled: false, // Column configuration not to be checked
      name: record.item_name,
    }),
  };

  const fourthStageNode = (
    <div className="flex flex-col shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col gap-3 grow">
        <h2 className="text-lg md:text-xl font-semibold">
          {t("registry.invoice_title")}
          {invoiceDetailData[0]?.invoice_number} (ID: {id_invoice})
        </h2>
        <div className="flex flex-col gap-3">
          <Table<RegistryInvoiceItem>
            size="small"
            className="grow"
            dataSource={[invoiceItem]}
            columns={invoiceColumns}
            rowKey="id_invoice"
            loading={isTableLoading}
            pagination={false}
            scroll={{ x: 1200 }}
          />
          <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3">
            <h6 className="md:text-lg lg:text-xl font-semibold">{t("registry.details_title")}</h6>
            <div className="flex items-center flex-wrap gap-3">
              <Button
                className="shadow grow"
                icon={<IconFileTypePdf size={20} />}
                onClick={() => getItemImages("invoices", +(id_invoice || 0))}
              >
                {t("photo.open_pdf")}
              </Button>

              <Button
                className="shadow grow"
                icon={<IconPhoto size={20} />}
                onClick={() => getItemImages("items", selectedItems[0].id_item)}
                disabled={!selectedItems.length}
              >
                {t("photo.open_photo")}
              </Button>

              <div className="hidden">
                <Image.PreviewGroup
                  items={itemImages}
                  preview={{
                    visible: previewVisible,
                    destroyOnClose: true,
                    imageRender: (original, info) => (
                      <div className="relative flex max-h-[70%]">
                        {original}
                        <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                          {translatePhotoName(itemImages[info.current]?.name)}
                        </div>
                      </div>
                    ),
                    onVisibleChange: (value) => {
                      setPreviewVisible(value);
                      !value && setItemImages([]);
                    },
                  }}
                >
                  <Image />
                </Image.PreviewGroup>
              </div>
            </div>
          </div>
          <Table<RegistryInvoiceItemDetail>
            size="small"
            dataSource={invoiceDetailData}
            columns={columns}
            rowSelection={rowSelection}
            rowKey={(_, index) => (index || 0).toString()}
            loading={isTableLoading}
            pagination={{ defaultPageSize: 40, hideOnSinglePage: true }}
            scroll={{ x: 1400, y: table_height }}
          />
        </div>
      </div>
    </div>
  );

  const fourthStageData: StageType = {
    id: 3,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("registry.header")}
        <IconArrowNarrowRight />
        {t("registry.invoices")}
        <IconArrowNarrowRight />
        {t("registry.details_title")}
      </div>
    ),
    node: fourthStageNode,
  };

  return <StageLayout stage={fourthStageData} />;
}
