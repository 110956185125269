import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Button, Image, Modal, Spin, Table } from "antd";
import type { TableProps } from "antd";
import { IconFileTypePdf } from "@tabler/icons-react";
import { useNotification } from "state/notification-context";
import { getImages } from "api/moving";
import { deleteTempInvoice, getTempInvoices } from "api/registration";
import { RegistryInvoiceItem } from "types/registry/inventory-types";
import { useTranslation } from "react-i18next";
import { useMainContext } from "state/main-context";
import { translatePhotoName } from "helpers/translate-photo-name";

export default function RegisterInvoiceList() {
  const { setLoadingScreen } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [tempInvoices, setTempInvoices] = useState<RegistryInvoiceItem[]>([]);

  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [isSaving, setSaving] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<RegistryInvoiceItem[]>([]);

  const [itemImages, setItemImages] = useState<any[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const table_height = useMemo(() => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width < 640) {
      return height - 363;
    } else if (width < 768) {
      return height - 307;
    } else if (width < 1024) {
      return height - 275;
    } else return height - 291;
  }, [window.innerWidth]);

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    setTableLoading(true);

    await getTempInvoices()
      .then((res: any) => {
        setTempInvoices(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.data_error"), err);
      })
      .finally(() => setTableLoading(false));
  };

  const deleteInvoice = async () => {
    setSaving(true);

    await deleteTempInvoice(selectedItems[0].id_invoice)
      .then(() => {
        getInvoices();
        setSelectedItems([]);
        showNotification("success", t("notifications.save_invoice_success"));
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.save_invoice_error"), err);
      })
      .finally(() => {
        setTimeout(() => {
          setSaving(false);
        }, 2000);
      });
  };

  const getItemImages = async (folder: string, id: number) => {
    setLoadingScreen(true);

    await getImages({ folder, id })
      .then((res: any) => {
        let imageArray = res.images.map((img: any) => ({ src: `data:image/png;base64,${img.base64}`, name: img.name }));
        setItemImages(imageArray);

        if (imageArray.length === 0) {
          showNotification("warning", t("notifications.photo_error"));
        } else {
          setPreviewVisible(true);
        }
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("warning", t("notifications.photo_error"), err);
      })
      .finally(() => setLoadingScreen(false));
  };

  const { confirm } = Modal;

  const showNoPhotoConfirm = () => {
    confirm({
      width: "600px",
      title: t("register.save_without_photo_title"),
      content: t("register.save_without_photo_content"),
      okText: t("register.save_without_photo_confirm"),
      cancelText: t("register.save_without_photo_cancel"),
      cancelButtonProps: { loading: isSaving },
      onOk: deleteInvoice,
      keyboard: false,
      centered: true,
    });
  };

  const columns: TableProps<RegistryInvoiceItem>["columns"] = [
    {
      title: t("table.invoice_number"),
      dataIndex: "invoice_number",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.invoice_date"),
      dataIndex: "invoice_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
    },
    {
      title: t("table.invoice_registration_date"),
      dataIndex: "registration_date",
      render: (value) => (value !== null ? new Date(value).toLocaleDateString() : "-"),
    },
    {
      title: t("table.mrp"),
      dataIndex: "name_surname",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.sender"),
      dataIndex: "sender",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.iin_bin"),
      dataIndex: "iin_bin",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
    },
  ];

  const rowSelection: TableProps<RegistryInvoiceItem>["rowSelection"] = {
    type: "radio",
    onChange: (selectedRowKeys: React.Key[], selectedRows: RegistryInvoiceItem[]) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record: RegistryInvoiceItem) => ({
      disabled: false, // Column configuration not to be checked
      name: record.invoice_number,
    }),
  };

  const sixthStageNode = (
    <div className="flex flex-col grow shadow w-full h-full p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col gap-3 grow">
        <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between gap-3">
          <h2 className="text-lg font-semibold">{t("register.choose_invoice_title")}</h2>
          <div className="flex flex-wrap md:flex-nowrap items-center gap-3">
            <Button
              className="shadow"
              icon={<IconFileTypePdf size={20} />}
              onClick={() => getItemImages("invoices", selectedItems[0].id_invoice)}
              disabled={!selectedItems.length}
            ></Button>
            <div className="hidden">
              <Image.PreviewGroup
                items={itemImages}
                preview={{
                  visible: previewVisible,
                  destroyOnClose: true,
                  imageRender: (original, info) => (
                    <div className="relative flex max-h-[70%]">
                      {original}
                      <div className="absolute inset-x-0 bottom-6 flex w-fit place-self-center px-6 py-3 text-white rounded-[100px] bg-black-50">
                        {translatePhotoName(itemImages[info.current]?.name)}
                      </div>
                    </div>
                  ),
                  onVisibleChange: (value) => {
                    setPreviewVisible(value);
                    !value && setItemImages([]);
                  },
                }}
              >
                <Image />
              </Image.PreviewGroup>
            </div>
            <Button
              className="shadow grow lg:grow-0"
              type="primary"
              onClick={showNoPhotoConfirm}
              disabled={!selectedItems.length}
            >
              {t("register.save_without_photo_button")}
            </Button>
            <Button
              className="shadow grow lg:grow-0 text-white bg-green-600 active:!text-white active:!border-green-500 active:!bg-green-500 hover:!text-white hover:!border-green-500 hover:!bg-green-500"
              variant="solid"
              onClick={() => navigate(`/registration/${selectedItems[0].id_invoice}`)}
              disabled={!selectedItems.length}
            >
              {t("register.add_photo_button")}
            </Button>
          </div>
        </div>
        <Table<RegistryInvoiceItem>
          size="small"
          className="grow"
          columns={columns}
          rowSelection={rowSelection}
          dataSource={tempInvoices}
          rowKey="id_invoice"
          loading={isTableLoading}
          bordered
          pagination={false}
          scroll={{ x: 1200, y: table_height }}
        />
      </div>
    </div>
  );

  const sixthStageSavingNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      <h2 className="text-lg md:text-xl font-semibold text-center">{t("register.product_saving")}</h2>
      <Spin size="large"></Spin>
    </div>
  );

  const sixthStageData: StageType = {
    id: 2,
    title: t("register.header"),
    node: isSaving ? sixthStageSavingNode : sixthStageNode,
  };

  return (
    <>
      <StageLayout stage={sixthStageData} />
    </>
  );
}
