import Header from "components/header";
import { StageType } from "types/stage-types";

export default function StageLayout({ stage }: { stage: StageType }) {
  return (
    <section className="flex flex-col p-2 sm:p-3 md:p-4 lg:p-6 min-h-[100dvh]">
      <Header pageTitle={stage.title} backNavigatePageCount={stage.id} />
      {stage.node}
    </section>
  );
}
