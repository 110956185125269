import * as XLSX from "xlsx";
import { useNotification } from "state/notification-context";
import { Button, Modal } from "antd";
import { IconFileTypeXls } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function ExportTable({
  getAllData,
  buttonWithText,
}: {
  getAllData: () => Promise<any[]>;
  buttonWithText?: boolean;
}) {
  const location = useLocation();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const fileName = useMemo(() => {
    if (location.pathname.includes("registry/invoices")) {
      return `${t("registry.invoices")} ${new Date().toLocaleString()}`;
    } else if (location.pathname.includes("registry/items")) {
      return `${t("registry.items")} ${new Date().toLocaleString()}`;
    } else if (location.pathname.includes("registry/write-off")) {
      return `${t("registry.writeoff_items")} ${new Date().toLocaleString()}`;
    } else if (location.pathname.includes("report")) {
      return `${t("dashboard.actions")} ${new Date().toLocaleString()}`;
    } else return `${new Date().toLocaleString()}`;
  }, [location]);

  const exportToExcel = (data: any[]) => {
    // Convert the data into a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Set the width for all columns (e.g., 20 characters wide)
    const allColumnsWidth = { wch: 20 };
    ws["!cols"] = new Array(Object.keys(data[0]).length).fill(allColumnsWidth);

    // Create a new workbook and add the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the workbook to a file
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    showNotification("success", t("notifications.export_success"));
  };

  const confirmExportExcel = async () => {
    Modal.confirm({
      width: 600,
      title: t("registry.export_title"),
      content: t("registry.export_content"),
      onOk: () => getAllData().then((res) => exportToExcel(res)),
      centered: true,
    });
  };

  return (
    <Button
      className={`shadow ${buttonWithText ? "w-full" : ""}`}
      icon={<IconFileTypeXls size={20} />}
      onClick={confirmExportExcel}
    >
      {buttonWithText ? t("registry.export_title") : undefined}
    </Button>
  );
}
