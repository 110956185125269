import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { Alert, Button, Divider, Image, Input, Modal, Select, Space, Spin, Table, TableProps } from "antd";
import { IconArrowRight, IconCircleCheckFilled, IconInfoCircle } from "@tabler/icons-react";
import CameraCapture from "components/camera-capture";
import { getFilteredAmount, moveItem, moveItems } from "api/moving";
import { useMainContext } from "state/main-context";
import { MovingData } from "types/moving-types";
import { useNotification } from "state/notification-context";
import { getOptions, NumberOption, StringOption } from "helpers/options-list";
import { useTranslation } from "react-i18next";
import { updateFilterCache } from "api/filter_cache";

export default function MovingLocations() {
  const { user, itemsToMove } = useMainContext();
  const { showNotification } = useNotification();
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const movingType: "single" | "multiple" | "" = location.state?.type;

  const [destinations, setDestinations] = useState<NumberOption[]>([]);
  const [amount, setAmount] = useState<number>(1);
  const [responsible, setResponsible] = useState<NumberOption[]>([]);
  const [reasons, setReasons] = useState<StringOption[]>([]);

  const [filteredItems, setFilteredItems] = useState<MovingData[]>([]);

  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [selectedAmount, setSelectedAmount] = useState<string>("");
  const [selectedResponsible, setSelectedResponsible] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [isCameraActive, setCameraActive] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string | null>(null);
  const [isMovingProduct, setIsMovingProduct] = useState<boolean>(false);
  const [isMovingSucceed, setIsMovingSucceed] = useState<boolean>(false);

  const isMovingItems = useMemo(() => {
    return location.pathname.includes("moving") ? true : false;
  }, [location]);

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    movingType === "multiple" && selectedDestination && selectedResponsible && onResponsibleSelect();
  }, [movingType, selectedDestination, selectedResponsible]);

  const isSingleMovingValid = useMemo(() => {
    if (
      movingType === "single" &&
      !!selectedDestination &&
      +selectedAmount <= amount &&
      +selectedAmount !== 0 &&
      !!selectedResponsible &&
      !!photo
    )
      return true;
    return false;
  }, [selectedDestination, selectedAmount, selectedResponsible, photo]);

  const isMultipleMovingValid = useMemo(() => {
    if (
      movingType === "multiple" &&
      !!selectedDestination &&
      +selectedAmount <= amount &&
      +selectedAmount !== 0 &&
      !!selectedResponsible
    )
      return true;
    return false;
  }, [selectedDestination, selectedAmount, selectedResponsible]);

  const isSingleWriteOffValid = useMemo(() => {
    if (movingType === "single" && +selectedAmount <= amount && +selectedAmount !== 0 && !!selectedReason && !!photo)
      return true;
    return false;
  }, [selectedAmount, selectedReason, photo]);

  const isMultipleWriteOffValid = useMemo(() => {
    if (movingType === "multiple" && +selectedAmount <= amount && +selectedAmount !== 0 && !!selectedReason)
      return true;
    return false;
  }, [selectedAmount, selectedReason]);

  const getFilterOptions = async () => {
    await getOptions()
      .then((res) => {
        setResponsible(res.responsibles);
        setDestinations(res.destinations);
        setReasons(res.reasons);
      })
      .catch(async () => {
        try {
          await updateFilterCache();
        } catch (error: any) {
          let err = error?.response?.data?.detail;
          showNotification("error", t("notifications.filters_error"), err);
        }
      });
  };

  useMemo(() => {
    let totalQuantity = (filteredItems.length ? filteredItems : itemsToMove).reduce((sum, item) => {
      return sum + item.quantity;
    }, 0);

    setAmount(totalQuantity);
  }, [itemsToMove, filteredItems]);

  const onResponsibleSelect = async () => {
    await getFilteredAmount({
      data: itemsToMove.map((item) => ({
        id_stock: item.id_stock,
        stock_responsible: item.stock_responsible,
        location_name: item.location_name,
        quantity: item.quantity,
      })),
      name_surname: responsible.find((person) => person.value === +selectedResponsible)?.label.toString() || "",
      location_name: destinations.find((location) => location.value === +selectedDestination)?.label.toString() || "",
    })
      .then((res: MovingData[]) => {
        setFilteredItems(res);
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification("error", t("notifications.item_count_error"), err);
      })
      .finally(() => {});
  };

  const onMoveItem = async () => {
    setIsMovingProduct(true);

    let payload: any = {};

    if (movingType === "single") {
      payload.item_id = itemsToMove[0].id_item;
      payload.from_location = itemsToMove[0].id_location;
      payload.to_location = isMovingItems ? selectedDestination : 2;
      payload.quantity = +selectedAmount;
      payload.responsible_employee = isMovingItems ? selectedResponsible : itemsToMove[0].stock_responsible_id;
      payload.id_user = user.user_id;
      payload.previous_responsible_employee = itemsToMove[0].stock_responsible_id;
      payload.photo = photo?.replace("data:image/png;base64,", "");
      payload.action_name = isMovingItems
        ? `Перемещение товара от ${itemsToMove[0].stock_responsible} к ${
            responsible.find((item) => item.value === +selectedResponsible)?.label
          } - количество ${selectedAmount}`
        : `Списание товара пользователем ${user.name} - количество ${selectedAmount} - причина ${selectedReason}`;
      payload.action_type = isMovingItems ? "Перемещение" : "Списание";

      isMovingItems &&
        (payload.action = `От_${itemsToMove[0].stock_responsible}_к_${
          responsible.find((item) => item.value === +selectedResponsible)?.label
        }`);
      !isMovingItems && (payload.reason = selectedReason);
    } else {
      payload.id_stock = (isMovingItems ? filteredItems : itemsToMove).map((item) => item.id_stock);
      payload.to_location = isMovingItems ? selectedDestination : 2;
      payload.quantity = +selectedAmount;
      payload.responsible_employee = isMovingItems ? selectedResponsible : itemsToMove[0].id_employee;
      payload.id_user = user.user_id;

      !isMovingItems && (payload.reason = selectedReason);
    }

    (movingType === "single" ? moveItem : moveItems)(payload)
      .then(() => {
        showNotification(
          "success",
          isMovingItems ? t("notifications.transfer_success") : t("notifications.writeoff_success")
        );
      })
      .catch((error: any) => {
        let err = error?.response?.data?.detail;
        showNotification(
          "error",
          isMovingItems ? t("notifications.transfer_error") : t("notifications.writeoff_error"),
          err
        );
      })
      .finally(() => {
        setTimeout(() => {
          setIsMovingSucceed(true);
        }, 1000);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      });
  };

  const columns: TableProps<MovingData>["columns"] = [
    {
      title: "E-SEP ID",
      dataIndex: "id_item",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_name"),
      dataIndex: "item_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_batch"),
      dataIndex: "batch",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_category"),
      dataIndex: "category",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_location"),
      dataIndex: "location_name",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.quantity"),
      dataIndex: "quantity",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
    {
      title: t("table.mrp"),
      dataIndex: "stock_responsible",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.item_source"),
      dataIndex: "source",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.filial"),
      dataIndex: "filial",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.lifecycle"),
      dataIndex: "lifecycle",
      render: (value) => (value !== null ? value : "-"),
    },
    {
      title: t("table.amortisation_value"),
      dataIndex: "amortisation_value",
      render: (value) => (value !== null ? (+value.toFixed(2)).toLocaleString() : "-"),
    },
  ];

  const movingModal = () => {
    Modal.info({
      title: <span className="font-medium">{t("moving.moving_guide_title")}</span>,
      content: (
        <ol>
          <li>{t("moving.moving_guide_1")}</li>
          <li>{t("moving.moving_guide_2")}</li>
          <li>{t("moving.moving_guide_3")}</li>
          <li>
            {t("moving.moving_guide_4")}
            {`${movingType === "single" ? ";" : "."}`}
          </li>
          {movingType === "single" ? <li>{t("moving.moving_guide_5")}</li> : undefined}
        </ol>
      ),
    });
  };

  const writeOffModal = () => {
    Modal.info({
      title: <span className="font-medium">{t("moving.writeoff_guide_title")}</span>,
      content: (
        <ol>
          <li>{t("moving.writeoff_guide_1")}</li>
          <li>{t("moving.writeoff_guide_2")}</li>
          <li>
            {t("moving.writeoff_guide_3")}
            {`${movingType === "single" ? ";" : "."}`}
          </li>
          {movingType === "single" ? <li>{t("moving.writeoff_guide_4")}</li> : undefined}
        </ol>
      ),
    });
  };

  const MovingLocationsNode = (
    <div className="flex flex-col grow shadow w-full h-full gap-3 p-2 sm:p-3 md:p-4 lg:p-6 rounded-md bg-white">
      <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between gap-3">
        <div className="flex items-center justify-center gap-1">
          <h2 className="text-lg lg:text-xl font-semibold text-center">
            {isMovingItems ? t("moving.moving_guide_title") : t("moving.writeoff_guide_title")}
          </h2>
          <IconInfoCircle
            className="text-primary cursor-pointer"
            onClick={() => (isMovingItems ? movingModal() : writeOffModal())}
          />
        </div>
      </div>

      <div className="flex flex-col gap-3 w-full">
        <Table<MovingData>
          size="small"
          dataSource={itemsToMove}
          columns={columns}
          rowKey={(_, index) => (index || 0).toString()}
          pagination={false}
        />

        <div className="flex flex-col md:flex-row md:items-end gap-3">
          {isMovingItems && movingType === "single" ? (
            <>
              <div className="flex flex-col gap-3">
                <span className="lg:text-lg font-semibold">{t("moving.move_from")}</span>
                <Input value={itemsToMove[0].location_name} disabled />
              </div>
              {window.innerWidth > 767 && (
                <div className="h-8 flex items-center">
                  <IconArrowRight />
                </div>
              )}
            </>
          ) : undefined}

          {isMovingItems && (
            <div className="flex flex-col gap-3">
              <span className="lg:text-lg font-semibold">{t("moving.move_where")}</span>
              <Select
                className="w-full md:w-48"
                value={selectedDestination}
                onChange={setSelectedDestination}
                options={destinations}
              />
            </div>
          )}

          {selectedDestination && isMovingItems && (
            <>
              <Divider className="hidden md:block m-0 h-8" type="vertical" />
              <div className="flex flex-col gap-3">
                <span className="lg:text-lg font-semibold">{t("moving.move_mrp")}</span>
                <Select value={selectedResponsible} onChange={setSelectedResponsible} options={responsible} />
              </div>
            </>
          )}

          {!isMovingItems && (
            <div className="flex flex-col gap-3">
              <span className="lg:text-lg font-semibold">{t("moving.move_reason")}</span>
              <Select value={selectedReason} onChange={setSelectedReason} options={reasons} />
            </div>
          )}

          {(isMovingItems && selectedDestination && selectedResponsible) ||
          (selectedDestination && selectedResponsible && filteredItems.length) ||
          !isMovingItems ? (
            <>
              <Divider className="hidden md:block m-0 h-8" type="vertical" />
              <div className="flex flex-col gap-3">
                <span className="lg:text-lg font-semibold">{t("moving.move_quantity")}</span>
                <Input
                  type="number"
                  min={0}
                  max={amount}
                  step={"any"}
                  value={selectedAmount}
                  onChange={(e) => setSelectedAmount(e.target.value)}
                />
              </div>
              {(+selectedAmount > amount || +selectedAmount === 0) && (
                <Alert message={t("moving.quantity_alert")} type="error" showIcon />
              )}
            </>
          ) : undefined}
        </div>

        {filteredItems.length > 0 && filteredItems.length !== itemsToMove.length && (
          <Alert message={t("moving.excluded_alert")} type="info" showIcon />
        )}

        {movingType === "single" ? (
          <>
            <Space direction="vertical">
              <span className="lg:text-lg font-semibold">{t("photo.title")}</span>
              {photo && <Image width={100} src={photo || undefined} />}
              <Button onClick={() => setCameraActive(true)}>
                {photo ? t("photo.change_photo") : t("photo.add_photo")}
              </Button>
            </Space>
          </>
        ) : undefined}

        <div className="flex items-center justify-end">
          <Button
            className="shadow"
            type="primary"
            disabled={
              !isSingleMovingValid && !isMultipleMovingValid && !isSingleWriteOffValid && !isMultipleWriteOffValid
            }
            onClick={() =>
              Modal.confirm({
                width: 500,
                centered: true,
                title: t("moving.confirm_header"),
                content: isMovingItems ? t("moving.confirm_moving_title") : t("moving.confirm_writeoff_title"),
                cancelText: t("moving.cancel"),
                okText: t("moving.confirm"),
                onOk: onMoveItem,
              })
            }
          >
            {isMovingItems ? t("moving.move_button") : t("moving.writeoff_button")}
          </Button>
        </div>
      </div>
    </div>
  );

  const LocationsLoadingNode = (
    <div className="flex flex-col items-center justify-center gap-3 grow">
      {isMovingItems ? (
        <h2 className="text-lg md:text-xl font-semibold text-center">
          {isMovingSucceed ? t("moving.move_success") : t("moving.move_in_progress")}
        </h2>
      ) : (
        <h2 className="text-lg md:text-xl font-semibold text-center">
          {isMovingSucceed ? t("moving.writeoff_success") : t("moving.writeoff_in_progress")}
        </h2>
      )}
      {isMovingSucceed ? <IconCircleCheckFilled className="fill-primary" size={200} /> : <Spin size="large"></Spin>}
    </div>
  );

  const MovingLocationsData: StageType = {
    id: 2,
    title: isMovingItems ? t("moving.moving_header") : t("moving.writeoff_header"),
    node: isMovingProduct ? LocationsLoadingNode : MovingLocationsNode,
  };

  return (
    <>
      <StageLayout stage={MovingLocationsData} />
      <CameraCapture isCameraActive={isCameraActive} setCameraActive={setCameraActive} sendPhoto={setPhoto} />
    </>
  );
}
