export const main = {
  scan: "Scan QR code",
  print: "Re-print QR code",
  employees: "Employees",
  dictionaries: "Directories",
  change_language: "Change language",
  logout: "Logout",

  registry: "Registry",
  report: "Dashboard",
  inventory: "Stock count",
  registration: "Registration",
  product_moving: "Transfer",
  product_write_off: "Write-off",
}