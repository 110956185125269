import { useNavigate } from "react-router-dom";
import StageLayout from "components/layout/page-layout";
import { StageType } from "types/stage-types";
import { IconArrowNarrowRight, IconVocabulary } from "@tabler/icons-react";
import { CategoryType } from "types/ui/category-types";
import { useTranslation } from "react-i18next";

export default function ChooseJournalType() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const journalTypes: CategoryType[] = [
    {
      id: "table_to_item",
      title: t("inventory.table_to_item_journal"),
      icon: <IconVocabulary size={60} />,
      url: "/inventory/journal-type/table-to-item",
    },
    {
      id: "item_to_table",
      title: t("inventory.item_to_table_journal"),
      icon: <IconVocabulary size={60} />,
      url: "/inventory/journal-type/item-to-table",
    },
  ];

  const secondStageNode = (
    <div className="flex flex-col justify-center grow gap-4 md:gap-8 lg:gap-12 md:mx-12 lg:mx-16">
      <h2 className="text-lg md:text-xl font-bold text-center">{t("register.choose_type_title")}</h2>
      <div className="grow sm:grow-0 grid grid-cols-1 sm:grid-cols-2 justify-items-center gap-4 md:gap-8 lg:gap-12 h-full md:h-fit">
        {journalTypes.map((journal) => (
          <div
            className="w-2/3 sm:w-full p-4 shadow rounded-xl md:rounded-2xl lg:rounded-3xl bg-white cursor-pointer"
            onClick={() => navigate(journal.url)}
            key={journal.id}
          >
            <div className="flex flex-col items-center justify-center gap-2 h-full md:h-48 lg:h-60">
              {journal.icon}
              <span className="text-lg md:text-xl font-bold text-center">{journal.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>

    // <div className="flex flex-col items-center justify-center gap-14 grow">
    //   <h2 className="text-2xl font-bold text-center">{t("inventory.choose_journal_type")}</h2>
    //   <div className="flex items-center gap-16">
    //     {productCategoryTypes.map((type) => (
    //       <Card
    //         className={`w-80 shadow rounded-3xl cursor-pointer ${
    //           category.id === type.id ? "border-primary" : undefined
    //         }`}
    //         key={type.id}
    //         onClick={() => setCategory(type)}
    //       >
    //         <div className="flex flex-col items-center justify-center gap-2 h-[200px]">
    //           {type.icon}
    //           <span className="text-2xl font-bold text-center">{type.title}</span>
    //         </div>
    //       </Card>
    //     ))}
    //   </div>
    //   <Button
    //     className="h-16 w-96 shadow rounded-xl"
    //     type="primary"
    //     disabled={!category.id}
    //     onClick={() => navigate(category.url)}
    //   >
    //     <span className="text-lg font-semibold">{t("inventory.next")}</span>
    //   </Button>
    // </div>
  );

  const secondStageData: StageType = {
    id: 2,
    title: (
      <div className="flex items-center justify-center flex-wrap gap-2">
        {t("inventory.header")}
        <IconArrowNarrowRight />
        {t("inventory.journal")}
      </div>
    ),
    node: secondStageNode,
  };

  return <StageLayout stage={secondStageData} />;
}
