import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { MainDashboardType } from "types/dashboard/main-dashboard-types";
import { useTranslation } from "react-i18next";

export default function WriteOffRatioChart({ mainDashboardData }: { mainDashboardData: MainDashboardType | null }) {
  const { t } = useTranslation();

  const writeOffData = useMemo(() => {
    return mainDashboardData?.items_count_items_count_amortised || [0, 0];
  }, [mainDashboardData]);

  const options: ApexOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: t("dashboard.writeoff_pie_title"),
    },
    labels: [t("dashboard.writeoff_pie_1"), t("dashboard.writeoff_pie_2")],
    colors: ["rgb(220, 38, 38)", "#1677ff"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return <ReactApexChart options={options} series={writeOffData} type="pie" />;
}
