export const roles = {
  header: "Roles and accesses",
  title: "Roles and accesses list",
  add_role: "Add role",
  role_column: "Role",
  action_column: "Action",
  edit_button: "Edit role",
  registration_column: "Registration",
  stock_count_column: "Stock count",
  locations_column: "Locations",
  reasons_column: "Write-off reasons",
  employees_column: "Employees",
  moving_column: "Transfer",
  roles_column: "Roles list",
  dashboard_column: "Dashboard",
  writeoff_column: "Write-off",
  registry_column: "Registry",
  logs_column: "Access to logs",
  scan_qr_column: "Scan QR code",
  print_qr_column: "Re-print QR code",
  batch_column: "Batches",
  filial_column: "Access to filials",
  // new role
  edit_title: "Edit role",
  new_title: "New role",
  role_name: "Role name",
  cancel: "Cancel",
  save: "Save",
};
