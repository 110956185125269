import { Api } from "api";

export const getMainDashboard = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  const query = !!params ? `&${params}` : "";
  const res = await Api.get(`/Main_dashboard/?hash_pw=${hash}${query}`);
  return res.data;
};

export const getWriteOffDashboard = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  const query = !!params ? `&${params}` : "";
  const res = await Api.get(`/Writeoff_dashboard/?hash_pw=${hash}${query}`);
  return res.data;
};

export const getInventoryDashboard = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  const query = !!params ? `&${params}` : "";
  const res = await Api.get(`/Inventory_dashboard/?hash_pw=${hash}${query}`);
  return res.data;
};

export const getItemActionCounts = async (params: URLSearchParams) => {
  const hash = localStorage.getItem("hash");
  const query = !!params ? `&${params}` : "";
  const res = await Api.get(`/get_item_action_counts/?hash_pw=${hash}${query}`);
  return res.data;
};
